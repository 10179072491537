<template>
    <demo-page name="Textarea" :menu="menu">
        <template slot="top">
            <p>A textarea is a form element similar to Input, but allows the user to enter text on several lines. The enter key is used to create a line break, and the @enter event can be triggered with Shift + Enter.</p>
        </template>
        <div class="value-wrapper" slot="sidemenu">
            <div class="value-container">
                <span class="value-label">Simple value:</span>
                <pre>{{ val }}</pre>
            </div>
        </div>
        <template>

            <demo-panel title="Simple textarea" :code="demo.a">
                <eg-input type="textarea" v-model="val"></eg-input>
            </demo-panel>

            <div class="show-doc" id="usage">
                <h2><a href="#usage">Usage</a></h2>
                <p>Textareas can be displayed using by default <code>&lt;eg-input&gt;</code> with the 'textarea' type.</p>
                <p>This component is an <router-link :to="{name: 'text-inputs'}">Input</router-link> wrapper. It has a similar structure and shares all its arguments. Nevertheless, it has some specific attributes.</p>
            </div>

            <div class="show-doc" id="events">
                <h2><a href="#events">Events</a></h2>
                <ul>
                    <li>The Input events: v-model (<strong>@input</strong>), <strong>@click</strong>, <strong>@iconClick</strong>, <strong>@change</strong>, <strong>@focus</strong>, <strong>@blur</strong>, <strong>@mousedown</strong>, <strong>@cross</strong>, <strong>@escape</strong>, <strong>@enter</strong> (with shift enter), <strong>@down</strong>, <strong>@up</strong>, <strong>@keydown</strong>, <strong>@keyup</strong>.</li>
                </ul>
            </div>

            <div class="show-doc" id="attributes">
                <h2><a href="#attributes">Attributes</a></h2>
                <ul>
                    <li><strong>rows</strong>: {default: 2}: The native &quot;rows&quot; attribute value (defining the base height).</li>
                    <li><strong>cols</strong>: {default: null}: The native &quot;cols attribute (defining the base width).</li>
                    <li><strong>minHeight</strong>: {type: Number, default: 40}: The minimal field height (in px).</li>
                    <li><strong>newLineShiftOnly</strong>: {type: Boolean, default: false}: If true, pressing enter in a textarea field will emit the @enter event and won't do a new line, unless the shift button is pressed at the same time.</li>
                </ul>
            </div>
        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                val: "",
                menu: [
                    {name: "Usage"},
                    {name: "Events"},
                    {
                        name: "Attributes"
                    },
                ],
                demo: {
                    a:`<eg-input type="number" v-model="val"></eg-input>`,
                    b:`<eg-input type="number" v-model="val" :decimals="1"></eg-input>`,
                    c:`<eg-input type="number" v-model="val" :step="5" :stepBig="50" :stepSmall="1"></eg-input>`,
                    d:`<eg-input type="number" v-model="val" :min="0" :max="10" message="Between 0 and 10"></eg-input>`,
                    e:`<eg-input type="number" v-model="val" :plusMinus="false"></eg-input>`,
                    f:`<eg-input type="number" v-model="val" :decimals="2" strongDecimals></eg-input>`
                }
            }
        }
    }
</script>