<style lang="scss">
    // Media queries thresholds
    @import "../../EgElementsProject/src/scss/thresholds";

    // Fonts
    @font-face {
        font-family: "Gadugi";
        src: url("../assets/gadugi.ttf") format("ttf")
    }

    html {
        transition-duration: 200ms;
        //scroll-behavior: smooth;
    }
    body {
        margin: 0;
    }
    * {
        font-family: 'Gadugi', sans-serif;
        line-height: 1.3;
    }
    h1, h2, h3, h4, h5 {
        font-weight: normal;
        margin: 0;
        display: block;
        padding: 1em 0 0;
        transition: none;
        @media all and (max-width: $Classic) {
            padding-top: 60px;
        }
        a {
            transition: none;
            text-decoration: none;
            margin: 0;
        }
    }
    a {
        font-weight: normal;
        font-size: inherit;
    }
    h1 {
        font-size: 2.2rem;
        padding-bottom: 0.5em;
        @media all and (max-width: $Classic) {
            padding-top: 0;
        }
    }
    h2 {
        font-size: 1.6rem;
        & > h3 {
            padding-top: 0;
        }
    }
    h3 {
        font-size: 1.4rem;
    }
    h4 {
        font-size: 1.1rem;
        color: #616161;
        a {
            color: #616161;
        }
    }
    p {
        margin: 10px 0 5px 0;
    }
    ul {
        padding-left: 2em;
    }
    p,
    li {
        color: var(--color-default-text);
    }
    a {
        color: var(--color-primary);
        &:hover {
            color: #999;
        }
    }
    pre,
    code {
        background-color: rgba(var(--rgb-default),0.25);
        white-space: pre-wrap;
    }
    .icons-demo-panel,
    pre {
        padding: 1em 2em;
        margin: 1em 0;
        max-width: calc(100vw - (2 * 4em));
        overflow-x: auto;
        code {
            display: inline-block;
            background-color: transparent;
        }
        @media all and (max-width: $Classic) {
            max-width: 100%;
        }
    }

    .font-courrier {
        font-family: courrier, monospace!important;
    }
    .sm-hide {
        @media all and (max-width: $Classic) {
            display: none;
        }
    }
    .sm-reveal {
        @media all and (max-width: $Classic) {
            display: block;
        }
    }

    .value-wrapper {
        margin: 0 1em;
        padding: 1em 0;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            right: 10px;
            height: 1px;
            background-color: #CCC;
        }
        .value-container {
            padding: 1em;
            background-color: #FFFFFF;
            min-height: 7rem;
            margin: 0 0 1em 0;
            .value-label {
                color: #aaa;
                font-size: 0.8rem;
                display: block;
                text-align: left;
            }
            pre {
                padding: 1em 0;
                background-color: transparent;
            }
        }
    }

    .css-i {
        display: inline-block;
        width: 30px;
        text-align: right;
        padding-right: 1em;
        user-select: none;
        .col-c {
            border: none;
            padding: 0;
            margin: 0;
            height: 1.2rem;
            width: 1.5rem;
            background-color: transparent;
        }
    }

    .icons-demo-panel {
        background-color: #f5f5f5;
        padding: 2rem;
        margin-bottom: 2rem;
        h3 {
            margin-top: 0;
        }
    }

    .tri-col {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1em;
    }

    .args-panels {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2em;
        .args-panel {
            flex: 1;
            .args-value {
                box-sizing: border-box;
                height: 100%;
                white-space: pre;
                margin-bottom: 0;
                strong {
                    display: inline-block;
                    line-height: 2;
                }
            }
        }
    }

    .icon-libraries-selectors {
        margin-top: 1em;
        .eg-button {
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
    .icons-demo-panels {
        margin-top: 1em;
        .eg-icon {
            font-size: 2em;
        }
    }
    .eg-elements-demo {
        background-color: var(--color-primary-text);
    }


</style>

<template>
    <div class="eg-elements-demo">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {}
</script>