<style lang="scss">
    // Media queries thresholds from eg-elements
    @import "../../EgElementsProject/src/scss/thresholds";

    .tab-list {
        border-bottom: 1px solid #CCC;
        margin-bottom: 1em;
        padding-top: 10px;
        background-color: #fff;
        z-index: 1;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                margin: 0;
                &.float-right {
                    float: right;
                    @media all and (max-width: $Smaller) {
                        display: none;
                    }
                }
                &.float-right-smaller {
                    display: none;
                    font-size: 20px;
                    position: relative;
                    bottom: 3px;
                    float: right;
                    color: #AAA;
                    margin-left: 10px;
                    @media all and (max-width: $Smaller) {
                        display: inline-block;
                    }
                }
                .checkbox-input {
                    vertical-align: middle;
                    padding: 3px 10px;
                    background-color: #fff;
                    float: right;
                    margin: 0;
                    position: relative;
                    bottom: 4px;
                    color: #AAA;
                    @media all and (max-width: $Smaller) {
                        top: 1px;
                    }
                }
                button {
                    cursor: pointer;
                    background: transparent;
                    border: none;
                    outline: none;
                    color: #AAA;
                    padding: 5px 1em;
                    border-bottom: 2px solid transparent;
                    &:hover {
                        color: #444;
                    }
                }
                &.selected {
                    color: var(--color-primary);
                    border-bottom: 2px solid var(--color-primary);
                    button {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
</style>

<template>
    <nav class="tab-list">
        <ul>
            <li :class="{selected:value==='docdemo'}"><button @click="hClick('docdemo')">Doc & Demo</button></li>
            <li :class="{selected:value==='doc'}"><button @click="hClick('doc')">Only doc</button></li>
            <li :class="{selected:value==='demo'}"><button @click="hClick('demo')" @dblclick="hDemo">Only demo</button></li>
            <li class="float-right">
                <eg-checkbox :value="fluid" @input="hFluid" label="Hide demo code"></eg-checkbox>
            </li>
            <li v-show="fluid" class="float-right-smaller" v-tooltip="tooltipArgs1">
                <eg-icon i="+visibility" clickable @click="hVisibility"></eg-icon>
            </li>
            <li v-show="!fluid" class="float-right-smaller" v-tooltip="tooltipArgs2">
                <eg-icon i="+visibility_off" clickable @click="hVisibility"></eg-icon>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        props: {
            value: {default: null},
            fluid: {type: Boolean, default: false}
        },
        data() {
            return {
                tooltipArgs1: {
                    zone: 'lc',
                    text: "Show code"
                },
                tooltipArgs2: {
                    zone: 'lc',
                    text: "Hide code"
                }
            }
        },
        methods: {
            hClick(tab) {
                this.$emit('input', tab);
            },
            hFluid() {
                this.$emit('fluid', !this.fluid);
            },
            hDemo() {
                this.hClick('demo');
                this.hFluid();
            },
            hVisibility() {
                this.$emit('fluid', !this.fluid);
            }
        }
    }
</script>