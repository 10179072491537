<template>
    <demo-page name="Select" :menu="menu">
        <template slot="top">
            <p>Select inputs (or simply selects) are form elements that allow the user to choose one or more options from the available list.</p>
        </template>
        <div class="value-wrapper" slot="sidemenu">
            <div class="value-container">
                <span class="value-label">Simple value:</span>
                <pre>{{ val }}</pre>
            </div>
        </div>
        <template>
            <demo-panel title="Select" :code="demo.a">
                <eg-select v-model="val" placeholder="Select an option..." :options="items"></eg-select>
            </demo-panel>

            <p>Selects can be displayed using by default <code>&lt;eg-select&gt;</code>.</p>
            <p>They are visually similar to the text inputs, but with a caret on the right. On focus, they display a floating menu (by default on the bottom of the field) containing options that can be selected.</p>
            <p>This component is an <router-link :to="{name: 'text-inputs'}">Input</router-link> wrapper. It has a similar structure and shares all its arguments. Nevertheless, it has some specific attributes and uses the menu slot.</p>

            <div id="attributes">
                <div class="show-doc">
                    <h2><a href="#attributes">Attributes</a></h2>
                </div>

                <div class="show-code hide-fluid">
                    <h3><a href="#demo-args" id="demo-args">Demo arguments</a></h3>
                    <div class="args-panels">
                        <div class="args-panel">
                        <pre class="args-value"><strong>items</strong>: [
    'Item 1',
    'Item 2',
    'Item 3'
]</pre>
                        </div>
                        <div class="args-panel">
                        <pre class="args-value"><strong>itemGroups</strong>: {
    "Fruits": ['Banana', 'Peach', 'Apricot'],
    "Animals": ['Jaguar', 'Lion', 'Tiger']
}</pre>
                        </div>
                        <div class="args-panel">
                        <pre class="args-value"><strong>itemsG</strong>: [{
    name: "John",
    type: "High level"
},{
    name: "Jane",
    type: "Medium level"
},{
    name: "Marie",
    type: "High level"
},{
    name: "Pierre",
    type: "Low level"
}]</pre>
                        </div>
                        <div class="args-panel">
                        <pre class="args-value"><strong>readyItems</strong>: [{
    id: 1,
    name: "First group",
    options: ['Tasty', 'Snack', 'Now']
},{
    id: 2,
    name: "Second group",
    options: ['Sleep', 'Rest', 'Dive']
}],</pre>
                        </div>
                    </div>
                </div>

                <div id="main">
                    <h3><a href="#main" >Main</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>menu</strong>: {type: String, default: &quot;b&quot;}: The menu positioning can be defined by a string:
                                <ul>
                                    <li><strong>bl</strong>: bottom left,</li>
                                    <li><strong>br</strong>: bottom right,</li>
                                    <li><strong>blr</strong>: top parent width,</li>
                                    <li><strong>tl</strong>: top: left,</li>
                                    <li><strong>tr</strong>: top right,</li>
                                    <li><strong>tlr</strong>: top parent width</li>
                                </ul>
                            </li>
                            <li><strong>native</strong>: {type: Boolean, default: false}: If true, the select menu displayed will be the browser native select.</li>
                        </ul>
                    </div>

                    <demo-panel title="Empty list select" :code="demo.b">
                        <eg-select v-model="val" placeholder="No option..." :options="[]" gLabel="name"></eg-select>
                    </demo-panel>
                    <demo-panel title="With the cross" :code="demo.c">
                        <eg-select placeholder="Select an option..." v-model="val" cross :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select menu bottom positionning" :code="demo.d">
                        <eg-select placeholder="Bottom... (default)" v-model="val" menu="b" :options="items"></eg-select>
                        <eg-select placeholder="Bottom left..." v-model="val" menu="bl" :options="items"></eg-select>
                        <eg-select placeholder="Bottom right..." v-model="val" menu="br" :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select menu top positionning" :code="demo.e">
                        <eg-select placeholder="Top..." v-model="val" menu="t" :options="items"></eg-select>
                        <eg-select placeholder="Top left..." v-model="val" menu="tl" :options="items"></eg-select>
                        <eg-select placeholder="Top right..." v-model="val" menu="tr" :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Free input select" :code="demo.f">
                        <eg-select placeholder="Free input..." v-model="val" editable free-input :options="items"></eg-select>
                    </demo-panel>

                    <demo-panel title="Select native" :code="demo.g">
                        <eg-select v-model="val" native placeholder="Select an option..." :options="items"></eg-select>
                    </demo-panel>
                </div>

                <div id="options">
                    <h3><a href="#options">Options</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>options</strong>: {type: Array, default() {return []}}: Array containing the options. If the array contains a string or a number, it will be used as label and value. If it contains object, one of the object's values can be defined as label using &quot;oLabel&quot; to specify the key.</li>
                            <li><strong>oLabel</strong>: {default: 0}: If the &quot;options&quot;array contains objects, defines the name of the attribute to use as option label.</li>
                            <li><strong>oId</strong>: {default: 0}: If the &quot;options&quot; array contains objects, defines the name of the attribute to use as option id.</li>
                            <li><strong>oMap</strong>: {default: null}:  If the &quot;options&quot; array contains objects, defines the name of the attribute to use for mapping. The &quot;value&quot; attribute is one (or contains if &quot;value&quot; is an array) of the objects values if &quot;oMap&quot; is used. For example, &quot;id&quot; can be set if you give the v-model an array containing only the ids of the selection. The options corresponding to the id of the &quot;oMap&quot; attributes will be selected and the label will be the first attribute or can be defined using &quot;oLabel&quot;.</li>
                        </ul>
                    </div>

                    <demo-panel title="Select with group options" :code="demo.h">
                        <eg-select v-model="val" placeholder="Groups object..." :optGroups="itemGroups"></eg-select>
                    </demo-panel>
                    <demo-panel title="Options select (defined option label)" :code="demo.i">
                        <eg-select v-model="val" placeholder="Object options..." :options="readyItems" oLabel="name" gOptions=""></eg-select>
                    </demo-panel>
                    <demo-panel title="Options select (with mapping)" :code="demo.j">
                        <eg-select v-model="val" placeholder="Object options..." :options="readyItems" oMap="id" oLabel="name" gOptions=""></eg-select>
                    </demo-panel>
                </div>

                <div id="grouped-options">
                    <h3><a href="#grouped-options">Grouped options</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>optGroups</strong>: {type: Object, default() {return {}}}: Used to display grouped options. Object containing the options to display as value (must be an array) and the group name as key.</li>
                            <li><strong>gOptions</strong>: {default: &quot;options&quot;}: If the &quot;options&quot; array contains objects representing options groups and containing an array of option at some key, you can use &quot;gOptions&quot; to specify at which key is the group options array stored.</li>
                            <li><strong>gLabel</strong>: {default: 0}: If the options provided are objects representing options groups, you can use &quot;gLabel&quot; to specify the value of which attribute should be considered as group name and label.</li>
                            <li><strong>groupBy</strong>: {type: String, default: null}: If &quot;options&quot; are objects, create grouped options using the specified attribute.</li>
                        </ul>
                    </div>

                    <demo-panel title="Select with options (custom group name)" :code="demo.k">
                        <eg-select v-model="val" placeholder="Object options..." :options="readyItems" gLabel="name"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select with options grouped by attribute" :code="demo.l">
                        <eg-select v-model="val" placeholder="Grouped options..." :options="itemsG" groupBy="type"></eg-select>
                    </demo-panel>
                </div>

                <div id="sort">
                    <h3><a href="#sort">Sort</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>sort</strong>: {type: String, default: null}:  If the &quot;options&quot; array contains objects, name of the attribute to base the sorting on. The objects will be sorted based on the value stored at the <em>sort</em> name. You can use &quot;1&quot; or &quot;true&quot; to sort using the options labels. You can prepend the &quot;sort&quot; value with &quot;-&quot; to sort in descending order instead of the default ascending order.</li>
                            <li><strong>sortGroups</strong>: {type: Boolean, default: false}, If true, the groups will be sorted as well with the ascending or descending order defined in sort.</li>
                        </ul>
                    </div>

                    <demo-panel title="Select items sorted" :code="demo.m">
                        <eg-select v-model="val" placeholder="Items sorted..." sort="1" :optGroups="itemGroups"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select groups sorted" :code="demo.n">
                        <eg-select v-model="val" placeholder="Groups sorted..." sortGroups :optGroups="itemGroups"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select items and groups sorted" :code="demo.o">
                        <eg-select v-model="val" placeholder="Items and groups sorted..." sort="1" sortGroups :optGroups="itemGroups"></eg-select>
                    </demo-panel>
                </div>

                <div id="search-and-filter">
                    <h3><a href="#search-and-filter">Search and filter</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>editable</strong>: {type: Boolean, default: false}: If true, the field is editable. The options are filtered based on the text typed (query). Only the options matching the query will be displayed.</li>
                            <li><strong>noFilter</strong>: {type: Boolean, default: false}: If true, the options will not be filtered when a value is typed.</li>
                            <li><strong>searchType</strong>: {type: String, default: &quot;start&quot;}: Search type. If 'start' is used, only the options starting with the typed query will be displayed. If another search type value is used, any part of the label matching the query will display the option.</li>
                            <li><strong>freeInput</strong>: {type: Boolean, default: false}: If &quot;editable&quot; is true, the returned value is the typed query or the selected option value (the user can manually edit the value on top of the available options).</li>
                        </ul>
                    </div>

                    <demo-panel title="Searchable select" :code="demo.p">
                        <eg-select v-model="val" placeholder="Searchable..." :options="items" editable></eg-select>
                    </demo-panel>
                    <demo-panel title="Select groups filtering" :code="demo.q">
                        <eg-select v-model="val" placeholder="Searchable groups..." sortGroups editable :optGroups="itemGroups"></eg-select>
                    </demo-panel>
                    <demo-panel title="Free input select" :code="demo.r">
                        <eg-select v-model="val" placeholder="Free input..." :options="items" editable free input></eg-select>
                    </demo-panel>
                </div>

                <div id="multiple">
                    <h3><a href="#multiple">Multiple</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>multiple</strong>: {type: Boolean, default: false}: The value is expected to be an array, and the options are displayed as tags inside the field.</li>
                            <li><strong>tagPrefix</strong>: {type: String, default: &quot;&quot;}: Text to be displayed on the left of all the tags (displayed if &quot;multiple&quot; is true).</li>
                            <li><strong>tagSuffix</strong>: {type: String, default: &quot;&quot;}: Text to be displayed on the left of all the tags (displayed if &quot;multiple&quot; is true).</li>
                            <li><strong>clickable</strong>: {type: Boolean, default: false}: If true, the tags are clickable.</li>
                        </ul>
                    </div>

                    <demo-panel title="Select multiple" :code="demo.s">
                        <eg-select flex placeholder="Select multiple..." v-model="vals" multiple :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select multiple editable" :code="demo.t">
                        <eg-select placeholder="Multiple..." v-model="vals" editable multiple :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select multiple flex" :code="demo.u">
                        <eg-select flex search placeholder="Multiple flex..." v-model="vals" multiple :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select multiple grouped" :code="demo.v">
                        <eg-select flex search placeholder="Multiple grouped..." v-model="vals" multiple :options="itemsG" groupBy="type"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select multiple editable free input" :code="demo.w">
                        <eg-select placeholder="Multiple..." v-model="vals" editable multiple free-input :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select tags not clickable" :code="demo.x">
                        <eg-select flex search placeholder="Clickable..." v-model="vals" multiple clickable :options="items"></eg-select>
                    </demo-panel>
                </div>

                <div id="style">
                    <h3><a href="#style">Style</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>buttonStyle</strong>: {type: Boolean, default: false}: If true, the select text box field will be a <router-link :to="{name: 'buttons'}">button</router-link> (can be &quot;primary&quot;).</li>
                            <li><strong>over</strong>: {type: Boolean, default: false}: If true, the menu will be displayed on top of the field box.</li>
                        </ul>
                    </div>

                    <demo-panel title="Select primary" :code="demo.y">
                        <eg-select placeholder="Select..." v-model="vals" primary :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select button style" :code="demo.z">
                        <eg-select placeholder="Select..." v-model="val" button-style :options="items"></eg-select>
                    </demo-panel>
                    <demo-panel title="Select menu over field" :code="demo.aa">
                        <eg-select placeholder="Select..." v-model="vals" over :options="items"></eg-select>
                    </demo-panel>
                </div>
            </div>

            <div class="show-doc" id="events">
                <h2><a href="#events">Events</a></h2>
                <ul>
                    <li>v-model (<strong>@input</strong>): the value can be a string or an array. If &quot;multiple&quot; is used, an array will always be returned.</li>
                    <li>The Input events: <strong>@click</strong>, <strong>@fakeClick</strong>, <strong>@iconClick</strong>, <strong>@change</strong>, <strong>@focus</strong>, <strong>@blur</strong>, <strong>@mousedown</strong>, <strong>@cross</strong>, <strong>@escape</strong>, <strong>@enter</strong>, <strong>@down</strong>, <strong>@up</strong>, <strong>@keydown</strong>, <strong>@keyup</strong>.</li>
                </ul>
            </div>

            <div id="custom-options">
                <h2><a href="#custom-options">Custom options</a></h2>
                <div class="show-doc">
                    <p>You can also define the html structure of the options to fit specific needs while keeping the component's logic.</p>
                </div>

                <demo-panel title="Select empty label" :code="demo.ab">
                    <eg-select v-model="val" placeholder="Select..." :options="items" cross>
                        <template v-slot:item="{e}">
                        <span class="custom-item">
                            <span class="num" v-html="`${String.fromCharCode(65 + e.i)}.`"></span>
                            <span v-html="e.value"></span>
                        </span>
                        </template>
                    </eg-select>
                </demo-panel>
            </div>

            <div class="show-doc" id="structure">
                <h2><a href="#structure">Structure</a></h2>
                <p>The Select (here with the id #my-field) has the following structure:</p>
                <pre><code class="jsx">&lt;div id=&quot;my-field-wrapper&quot; class=&quot;eg-input eg-input-container&quot;&gt;
	&lt;!-- Label --&gt;
	&lt;!-- Input box --&gt;
	&lt;div class=&quot;input-shell&quot;&gt;
		&lt;div class=&quot;egi-inner no-edit&quot;&gt;
			&lt;div class=&quot;fake-wrapper&quot;&gt;
				&lt;div class=&quot;custom-fake-inner&quot;&gt;
					&lt;input id=&quot;my-field&quot; class=&quot;fake-input&quot;&gt;
					&lt;span class=&quot;fake-value&quot;&gt;Item 1&lt;/span&gt;
				&lt;/div&gt;
			&lt;/div&gt;
			&lt;!-- Right icons --&gt;
		&lt;/div&gt;
		&lt;!-- Floating Menu --&gt;
		&lt;div id=&quot;my-field-menu&quot; class=&quot;floating-menu&quot; style=&quot;...&quot;&gt;
			&lt;div class=&quot;items-group&quot;&gt;
				&lt;span class=&quot;group-title&quot;&gt;Group name (display if grouped)&lt;/span&gt;
				&lt;ul&gt;
					&lt;li class=&quot;item&quot;&gt;&lt;span&gt;Item 1&lt;/span&gt;&lt;/li&gt;
				&lt;/ul&gt;
			&lt;/div&gt;
		&lt;/div&gt;
	&lt;/div&gt;
&lt;/div&gt;
</code></pre>
                <p>In addition to the &quot;.input-shell&quot; class, the floating menu can be selected with &quot;.floating-menu&quot; and all its children: &quot;.items-group&quot;, &quot;.group-title&quot; and &quot;.item&quot;.</p>

            </div>

        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                menu: [
                    {
                        name: "Attributes",
                        children: [
                            {name: "Main"},
                            {name: "Options"},
                            {name: "Grouped options"},
                            {name: "Sort"},
                            {name: "Search and filter"},
                            {name: "Multiple"},
                            {name: "Style"},
                        ]
                    },
                    {name: "Events"},
                    {name: "Custom options"},
                    {name: "Structure"},
                ],
                val: "",
                vals: [],
                items: [
                    'Item 1',
                    'Item 2',
                    'Item 3'
                ],
                itemGroups: {
                    "Fruits": ['Banana', 'Peach', 'Apricot'],
                    "Animals": ['Jaguar', 'Lion', 'Tiger']
                },
                itemsG: [{
                    name: "John",
                    type: "High level"
                },{
                    name: "Jane",
                    type: "Medium level"
                },{
                    name: "Marie",
                    type: "High level"
                },{
                    name: "Pierre",
                    type: "Low level"
                }],
                readyItems: [{
                    id: 1,
                    name: "First group",
                    options: ['Tasty', 'Snack', 'Now']
                },{
                    id: 2,
                    name: "Second group",
                    options: ['Sleep', 'Rest', 'Dive']
                }],
                demo: {
                    a:`<eg-select v-model="val" placeholder="Select an option..." :options="items"></eg-select>`,
                    b:`<eg-select v-model="val" placeholder="No option..." :options="[]" gLabel="name"></eg-select>`,
                    c:`<eg-select placeholder="Select an option..." v-model="val" cross :options="items"></eg-select>`,
                    d:`<eg-select placeholder="Bottom... (default)" v-model="val" menu="b" :options="items"></eg-select>
<eg-select placeholder="Bottom left..." v-model="val" menu="bl" :options="items"></eg-select>
<eg-select placeholder="Bottom right..." v-model="val" menu="br" :options="items"></eg-select>`,
                    e:`<eg-select placeholder="Top..." v-model="val" menu="t" :options="items"></eg-select>
<eg-select placeholder="Top left..." v-model="val" menu="tl" :options="items"></eg-select>
<eg-select placeholder="Top right..." v-model="val" menu="tr" :options="items"></eg-select>`,
                    f:`<eg-select placeholder="Free input..." v-model="val" editable free-input :options="items"></eg-select>`,
                    g:`<eg-select v-model="val" native placeholder="Select an option..." :options="items"></eg-select>`,
                    h:`<eg-select v-model="val" placeholder="Groups object..." :optGroups="itemGroups"></eg-select>`,
                    i:`<eg-select v-model="val" placeholder="Object options..." :options="readyItems" oLabel="name" gOptions=""></eg-select>`,
                    j:`<eg-select v-model="val" placeholder="Object options..." :options="readyItems" oMap="name" gOptions=""></eg-select>`,
                    k:`<eg-select v-model="val" placeholder="Object options..." :options="readyItems" gLabel="name"></eg-select>`,
                    l:`<eg-select v-model="val" placeholder="Grouped options..." :options="itemsG" groupBy="type"></eg-select>`,
                    m:`<eg-select v-model="val" placeholder="Items sorted..." sort="1" :optGroups="itemGroups"></eg-select>`,
                    n:`<eg-select v-model="val" placeholder="Groups sorted..." sortGroups :optGroups="itemGroups"></eg-select>`,
                    o:`<eg-select v-model="val" placeholder="Items and groups sorted..." sort="1" sortGroups :optGroups="itemGroups"></eg-select>`,
                    p:`<eg-select v-model="val" placeholder="Searchable..." :options="items" editable></eg-select>`,
                    q:`<eg-select v-model="val" placeholder="Searchable groups..." sortGroups editable :optGroups="itemGroups"></eg-select>`,
                    r:`<eg-select v-model="val" placeholder="Free input..." :options="items" editable free input></eg-select>`,
                    s:`<eg-select flex placeholder="Select multiple..." v-model="vals" multiple :options="items"></eg-select>`,
                    t:`<eg-select placeholder="Multiple..." v-model="vals" editable multiple :options="items"></eg-select>`,
                    u:`<eg-select flex search placeholder="Multiple flex..." v-model="vals" multiple :options="items"></eg-select>`,
                    v:`<eg-select flex search placeholder="Multiple grouped..." v-model="vals" multiple :options="itemsG" groupBy="type"></eg-select>`,
                    w:`<eg-select placeholder="Multiple..." v-model="vals" editable multiple free-input :options="items"></eg-select>`,
                    x:`<eg-select flex search placeholder="Clickable..." v-model="vals" multiple clickable :options="items"></eg-select>`,
                    y:`<eg-select placeholder="Select..." v-model="vals" primary :options="items"></eg-select>`,
                    z:`<eg-select placeholder="Select..." v-model="val" button-style :options="items"></eg-select>`,
                    aa:`<eg-select placeholder="Select..." v-model="vals" over :options="items"></eg-select>`,
                    ab:`<eg-select v-model="val" placeholder="Select..." :options="items" cross>
    <template v-slot:item="{e}">
        <span class="custom-item">
            <span class="num" v-html="e.i"></span>
            <span v-html="e.value"></span>
        </span>
    </template>
</eg-select>`
                }
            }
        }
    }
</script>