<template>
    <demo-page name="Icons" :menu="menu">
        <template slot="top">
            <p>The icons are visual elements that can be integrated in the interface or in the components to indicate information quickly or to allow the user to trigger an action.</p>
        </template>

        <div class="show-doc">
            <p>An icon can be displayed using by default <code>&lt;eg-icon&gt;</code>, with the icons name between the tags or given using the attributes &quot;type&quot;, &quot;icon&quot; or &quot;i&quot;. However, you will have to select an icon source if you don't want to be restricted to using only the few built-in svg icons.</p>
        </div>

        <div id="usage">
            <div class="show-doc">
                <h2><a href="#usage">Usage</a></h2>
                <p>You can use several types of icons from different sources easily:</p>
                <ul>
                    <li>Native svg Icons can be used directly</li>
                    <li>Fontello Webfonts can be imported using Vue.use.</li>
                    <li>The external libraries of Google Material and Font Awesome must be imported globally )in the header of the page for example).</li>
                </ul>
            </div>

            <div id="fontello-icon-webfonts">
                <h3><a href="#fontello-icon-webfonts">Fontello icon webfonts</a></h3>
                <div class="show-doc">
                    <p><a href="https://github.com/fontello/fontello">Fontello</a> is a tool that lets you combine icon web fonts. The default font sets from <a href="http://fontello.com">fontello.com</a> are available as packs. The licenses information are available on the web site. The available libraries are the following: Brandico, Elusive, Entypo, Iconic, Linecons, Maki, MFG Labs, Modern Pictograms, Typicons, Web Symbols and Zocial.</p>
                    <p>You can set the icon library to with the initialization parameters:</p>
                    <pre><code class="jsx">Vue.use(eg, {
    icons: [
        'brandico',
        'elusive',
        'entypo',
        'font_awesome',
        'iconic',
        'linecons',
        'maki',
        'mfg_labs',
        'modern_pictograms',
        'typicons',
        'web_symbols',
        'zocial',
    ]
});
</code></pre>
                </div>
                <demo-panel title="Native icon" :code="demo.a">
                    <eg-icon icon="check"></eg-icon>
                </demo-panel>
                <demo-panel title="Native icon shorthand" :code="demo.b">
                    <eg-icon i="search"></eg-icon>
                </demo-panel>
            </div>

            <div id="google-material-icons">
                <h3><a href="#google-material-icons">Google Material Icons</a></h3>
                <div class="show-doc">
                    <pre><code class="html">&lt;link href=&quot;https://fonts.googleapis.com/icon?family=Material+Icons&quot; rel=&quot;stylesheet&quot;&gt;
                    </code></pre>
                    <p>To use the icons, you have to prepend the icon name with &quot;ma:&quot;. For example if you want to use the &quot;alarm&quot; icon, you can use the attribute <em>icon</em> like this: <code>icon=&quot;ma:alarm&quot;</code>.</p>
                    <p>You can also use the text shorthand using a '+' before the name: <code>icon=&quot;+alarm&quot;</code></p>
                </div>
                <demo-panel title="Google material icon" :code="demo.e">
                    <eg-icon icon="ma:mic"></eg-icon>
                </demo-panel>
                <demo-panel title="Google material icon shorthand" :code="demo.f">
                    <eg-icon icon="+mic"></eg-icon>
                </demo-panel>
            </div>
            <div id="font-awesome-icons">
                <h3><a href="#font-awesome-icons">Font Awesome Icons</a></h3>
                <div class="show-doc">
                    <p>You can also use the <strong><a href="https://fontawesome.com/icons">Font Awesome Icons</a></strong>. You simply have to create an account and ask for a <strong><a href="https://fontawesome.com/start">kit</a></strong>. You will receive the {SECRET} **code to insert into the <em>index.html</em> file:</p>
                    <pre><code class="html">&lt;script src=&quot;https://kit.fontawesome.com/{SECRET}.js&quot;&gt;&lt;/script&gt;
</code></pre>
                    <p>To use the icons, you have to prepend the icon name with &quot;fa:&quot;. For example if you want to use the &quot;arrow-up&quot; **icon, you can use the attribute <em>icon</em> like this: <code>icon=&quot;fa:fas fa-arrow-up&quot;</code>. You have to prepend &quot;fa-arrow-up&quot; by &quot;fas&quot; to specify the <a href="https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use">font awesome style</a> (in this case for the Solid library).</p>
                    <p>You can also use another shorthand on the <em>fa</em> value. You can prefix &quot;fas fa-&quot; (not only fas by all icons styles) using the font style representative letter (s for fas, l for fal, etc.) followed by a '/' character: <code>icon=&quot;s/arrow-up&quot;</code>.</p>
                </div>
                <demo-panel title="Font awesome icon" :code="demo.c">
                    <eg-icon icon="fa:fas fa-balance-scale"></eg-icon>
                </demo-panel>
                <demo-panel title="Font awesome icon shorthand" :code="demo.d">
                    <eg-icon icon="s/balance-scale"></eg-icon>
                </demo-panel>
            </div>
            <div id="native-eg-elements-svg-icons">
                <h3><a href="#native-eg-elements-svg-icons">Native EG Elements SVG Icons</a></h3>
                <div class="show-doc">
                    <p>To use a native svg icon, you have to prefix the icon name with &quot;svg:&quot; or set the boolean attribute svg to true. The icons size is based on the text size.</p>
                </div>
                <demo-panel title="Native icon" :code="demo.g">
                    <eg-icon icon="svg:success"></eg-icon>
                </demo-panel>
                <demo-panel title="Native icon shorthand" :code="demo.h">
                    <eg-icon i="success" svg></eg-icon>
                </demo-panel>
            </div>
        </div>

        <div class="show-doc" id="attributes">
            <h2><a href="#attributes">Attributes</a></h2>
            <ul>
                <li><strong>icon</strong> {type: String, default: null}: The name of the icon to display. You can also use the arguments &quot;i&quot; or &quot;type&quot; instead of &quot;icon&quot;. The EG Element icon names can be used, but you can prefix the name with 'ma:' to use the material (google) icons, with 'fa:' to use the font awesome icons or with 'svg:' to use the native svg icons.</li>
                <li><strong>i</strong> {type: String, default: null}: &quot;icon&quot; shorthand (see above).</li>
                <li><strong>type</strong> {type: String, default: null}: &quot;icon&quot; shorthand (see above).</li>
                <li><strong>clickable</strong>: {type: Boolean, default: false}: If true, the icon has a clickable style (cursor and hover change).</li>
                <li><strong>fr</strong>: {type: Boolean, default: false}: The icon will have the <code>float: right</code> css attribute.</li>
                <li><strong>fl</strong>: {type: Boolean, default: false}: The icon will have the <code>float: left</code> css attribute.</li>
                <li><strong>svg</strong>: {type: Boolean, default: false}: If true, the svg library is used by default (you don't need to use the prefix).</li>
                <li><strong>title</strong>: {default: null}: The default &quot;title&quot; attribute, defining a default helping text revealed when the element is hover for a long time.</li>
                <li><strong>stop</strong>: {type: Boolean, default: false}: If true, <code>e.stopPropagation()</code> is applied before emitting any click event.</li>
                <li><strong>flip</strong>: {type: Boolean, default: false}: If true, the icon is vertically flipped.</li>
            </ul>
        </div>

        <div id="events" class="show-doc">
            <h2><a href="#events">Events</a></h2>
            <ul>
                <li><strong>@click</strong>: when the icon is clicked. Payload: the event, containing the extra attribute &quot;eventName&quot; containing the click action as string: 'click', 'dblclick', 'ctrlClick'.</li>
            </ul>
        </div>

        <div id="sizes" class="hide-fluid">
            <div class="show-code">
                <h2><a href="#sizes">Sizes</a></h2>
            </div>
            <div class="icons-demo-panel show-code">
                <h3><a href="#small-sizes" id="small-sizes">Small sizes (14px)</a></h3>
                <h4>Fontello</h4>
                <demo-panel v-for="a in fontello" :key="a" :title="a" :fluid="true"><eg-icon :icon="a"></eg-icon></demo-panel>
                <h4>Font Awesome</h4>
                <demo-panel v-for="a in fontawesomeIcons" :key="a" :title="a" :fluid="true"><eg-icon :i="`s/${a}`"></eg-icon></demo-panel>
                <h4>Google Material</h4>
                <demo-panel v-for="a in googleIcons" :key="a" :title="a" :fluid="true"><eg-icon :i="`ma:${a}`"></eg-icon></demo-panel>
                <h4>SVG</h4>
                <demo-panel v-for="a in nativeIcons" :key="a" :title="a" :fluid="true"><eg-icon svg :icon="a"></eg-icon></demo-panel>
            </div>
            <div class="icons-demo-panel show-code" style="font-size: 20px">
                <h3><a href="#medium-sizes" id="medium-sizes">Medium sizes (2px)</a></h3>
                <h4>Fontello</h4>
                <demo-panel v-for="a in fontello" :key="a" :title="a" :fluid="true"><eg-icon :icon="a"></eg-icon></demo-panel>
                <h4>Font Awesome</h4>
                <demo-panel v-for="a in fontawesomeIcons" :key="a" :title="a" :fluid="true"><eg-icon :i="`s/${a}`"></eg-icon></demo-panel>
                <h4>Google Material</h4>
                <demo-panel v-for="a in googleIcons" :key="a" :title="a" :fluid="true"><eg-icon :i="`ma:${a}`"></eg-icon></demo-panel>
                <h4>SVG</h4>
                <demo-panel v-for="a in nativeIcons" :key="a" :title="a" :fluid="true"><eg-icon svg :icon="a"></eg-icon></demo-panel>
            </div>
            <div class="icons-demo-panel show-code" style="font-size: 36px">
                <h3><a href="#big-sizes" id="big-sizes">Big sizes (36px)</a></h3>
                <h4>Fontello</h4>
                <demo-panel v-for="a in fontello" :key="a" :title="a" :fluid="true"><eg-icon :icon="a"></eg-icon></demo-panel>
                <h4>Font Awesome</h4>
                <demo-panel v-for="a in fontawesomeIcons" :key="a" :title="a" :fluid="true"><eg-icon :i="`s/${a}`"></eg-icon></demo-panel>
                <h4>Google Material</h4>
                <demo-panel v-for="a in googleIcons" :key="a" :title="a" :fluid="true"><eg-icon :i="`ma:${a}`"></eg-icon></demo-panel>
                <h4>SVG</h4>
                <demo-panel v-for="a in nativeIcons" :key="a" :title="a" :fluid="true"><eg-icon svg :icon="a"></eg-icon></demo-panel>
            </div>
        </div>

        <div class="show-doc" id="structure">
            <h2><a href="#structure">Structure</a></h2>
            <p>The Icon has the following structure:</p>
            <pre><code class="html">&lt;span class=&quot;eg-icon&quot;&gt;
	&lt;i class=&quot;icon-{name}&quot;&gt;&lt;/i&gt;
	&lt;!-- or --&gt;
	&lt;svg&gt;&lt;/svg&gt;
&lt;/span&gt;
</code></pre>
        </div>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                menu: [
                    {
                        name: "Usage",
                        children: [
                            {name: "Fontello icon webfonts"},
                            {name: "Google Material Icons"},
                            {name: "Font Awesome Icons"},
                            {name: "Native EG Elements SVG Icons"},
                        ]
                    },
                    {name: "Attributes"},
                    {name: "Events"},
                    {name: "Sizes"},
                    {name: "Structure"},
                ],
                demo: {
                    a:`<eg-icon icon="check"></eg-icon>`,
                    b:`<eg-icon i="search"></eg-icon>`,
                    c:`<eg-icon icon="fa:fas fa-balance-scale"></eg-icon>`,
                    d:`<eg-icon icon="s/balance-scale"></eg-icon>`,
                    e:`<eg-icon icon="ma:mic"></eg-icon>`,
                    f:`<eg-icon icon="+mic"></eg-icon>`,
                    g:`<eg-icon icon="svg:success"></eg-icon>`,
                    h:`<eg-icon icon="success" svg></eg-icon>`,
                },
                tooltipArgs: {},
                fontello: [
                    'note',
                    'note-beamed',
                    'music',
                    'water',
                    'flashlight',
                    'mail',
                    'heart',
                    'heart-empty',
                    'star',
                    'star-empty',
                    'user',
                    'users',
                    'user-add'
                ],
                nativeIcons: [
                    'cross',
                    'scross',
                    'plus',
                    'minus',
                    'search',
                    'success',
                    'error',
                    'warning',
                    'caret',
                    'lcaret',
                    'rcaret',
                    'gear',
                    'grabber',
                    'grabber2',
                    'duplicate',
                    'download',
                    'upload'
                ],
                fontawesomeIcons: [
                    'angle-double-up',
                    'angry',
                    'bomb',
                    'arrow-alt-circle-down',
                    'arrow-alt-circle-left',
                    'balance-scale',
                    'battery-three-quarters',
                    'binoculars',
                    'biohazard',
                    'birthday-cake',
                    'bicycle'
                ],
                googleIcons: [
                    'alarm',
                    'delete',
                    'cached',
                    'done',
                    'favorite',
                    'eco',
                    'home',
                    'perm_identity',
                    'zoom_in',
                    'games',
                    'mic'
                ],
                iconLibs: {
                    brandico: 'Brandico',
                    elusive: 'Elusive',
                    entypo: 'Entypo',
                    //font_awesome: 'Font Awesome',
                    iconic: 'Iconic',
                    linecons: 'Linecons',
                    maki: 'Maki',
                    mfg_labs: 'MFG Labs',
                    modern_pictograms: 'Modern Pictograms',
                    typicons: 'Typicons',
                    web_symbols: 'Web Symbols',
                    zocial: 'Zocial',
                },
                selIconLib: null,
                iconLibraries: {
                    brandico: [
                        'vimeo-rect',
                        'tumblr-rect',
                        'googleplus-rect',
                        'friendfeed',
                        'vkontakte-rect',
                        'friendfeed-rect',
                        'odnoklassniki-rect',
                        'facebook-rect-1',
                        'twitter-bird-1',
                        'skype-4',
                        'facebook-6',
                        'twitter-6',
                        'icq',
                        'yandex',
                        'yandex-rect',
                        'github-text',
                        'github-5',
                        'odnoklassniki-1',
                        'vimeo-4',
                        'tumblr-3',
                        'blogger-1',
                        'blogger-rect',
                        'deviantart-1',
                        'jabber',
                        'lastfm-4',
                        'lastfm-rect',
                        'linkedin-5',
                        'linkedin-rect',
                        'picasa-1',
                        'wordpress-2',
                        'instagram-4',
                        'instagram-filled',
                        'diigo',
                        'box-4',
                        'box-rect',
                        'tudou',
                        'youku',
                        'win8',
                        'amex',
                        'discover',
                        'visa',
                        'mastercard',
                        'houzz-1',
                        'bandcamp-1',
                        'codepen'
                    ],
                    elusive: [
                        'star-circled',
                        'star-6',
                        'stackoverflow-2',
                        'volume-1',
                        'smiley-circled',
                        'smiley',
                        'slideshare-1',
                        'skype-5',
                        'signal-4',
                        'basket-circled',
                        'basket-4',
                        'share-3',
                        'export-5',
                        'search-circled',
                        'search-6',
                        'target-4',
                        'desktop-circled',
                        'desktop-2',
                        'rss-6',
                        'road-1',
                        'backward-circled',
                        'retweet-3',
                        'resize-vertical-2',
                        'resize-small-4',
                        'resize-horizontal-2',
                        'resize-full-5',
                        'cw-circled',
                        'cw-5',
                        'cancel-circled-4',
                        'cancel-circled2-1',
                        'cancel-6',
                        'arrows-cw-2',
                        'reddit-2',
                        'record-2',
                        'shuffle-4',
                        'quote-circled',
                        'quote-1',
                        'help-circled-3',
                        'help-3',
                        'qrcode-1',
                        'print-6',
                        'plus-circled-2',
                        'plus-6',
                        'play-circled2-1',
                        'play-circled-1',
                        'play-5',
                        'flight-2',
                        'pinterest-4',
                        'picture-4',
                        'picasa-2',
                        'photo-circled',
                        'photo',
                        'phone-circled',
                        'phone-3',
                        'person',
                        'pencil-circled',
                        'pencil-6',
                        'pause-circled',
                        'pause-5',
                        'path',
                        'attach-circled',
                        'attach-5',
                        'ok-circled-2',
                        'ok-circled2-1',
                        'ok-5',
                        'off-1',
                        'network-1',
                        'music-3',
                        'move-3',
                        'minus-circled-2',
                        'minus-4',
                        'mic-circled',
                        'mic-5',
                        'location-circled',
                        'location-6',
                        'male-2',
                        'magnet-2',
                        'lock-circled',
                        'lock-6',
                        'clipboard-2',
                        'list-3',
                        'linkedin-6',
                        'leaf-3',
                        'laptop-circled',
                        'laptop-2',
                        'key-4',
                        'italic-1',
                        'iphone-home',
                        'instagram-5',
                        'info-circled-3',
                        'indent-right-1',
                        'indent-left-1',
                        'right-hand-1',
                        'left-hand-1',
                        'down-hand-1',
                        'guidedog',
                        'group-circled',
                        'group',
                        'forward-circled',
                        'forward-3',
                        'fontsize-1',
                        'font-1',
                        'folder-circled',
                        'folder-open-2',
                        'female-2',
                        'fast-forward-2',
                        'fast-backward-2',
                        'videocam-4',
                        'facebook-7',
                        'eye-6',
                        'gauge-2',
                        'css',
                        'credit-card-4',
                        'compass-circled',
                        'compass-5',
                        'comment-alt-1',
                        'down-open-3',
                        'check-empty-1',
                        'check-3',
                        'certificate-2',
                        'cc-3',
                        'camera-6',
                        'block-4',
                        'backward',
                        'asterisk-1',
                        'asl',
                        'up-6',
                        'right-5',
                        'heart-circled',
                        'heart-6',
                        'hearing-impaired',
                        'headphones-3',
                        'hdd-2',
                        'up-hand-1',
                        'github-6',
                        'gift-2',
                        'resize-full-alt-2',
                        'friendfeed-rect-1',
                        'friendfeed-1',
                        'foursquare-2',
                        'doc-new-circled',
                        'doc-new',
                        'edit-circled',
                        'edit-3',
                        'doc-circled',
                        'doc-6',
                        'dribbble-5',
                        'download-alt',
                        'download-6',
                        'digg-2',
                        'deviantart-2',
                        'delicious-2',
                        'left-circled-2',
                        'down-circled-2',
                        'child-1',
                        'up-open-3',
                        'right-open-4',
                        'left-open-4',
                        'bold-1',
                        'blogger-2',
                        'blind-1',
                        'bell-5',
                        'behance-2',
                        'barcode-1',
                        'left-5',
                        'down-6',
                        'align-right-1',
                        'align-left-1',
                        'align-justify-1',
                        'align-center-1',
                        'adult',
                        'adjust-2',
                        'address-book-alt',
                        'address-book-1',
                        'lightbulb-2',
                        'home-circled',
                        'home-6',
                        'heart-empty-3',
                        'globe-5',
                        'glasses',
                        'glass-1',
                        'github-text-1',
                        'flag-3',
                        'fire-2',
                        'filter-1',
                        'video-alt-1',
                        'mail-circled',
                        'mail-6',
                        'eject-3',
                        'edit-alt',
                        'cloud-circled',
                        'cloud-6',
                        'up-circled-2',
                        'right-circled-2',
                        'braille-1',
                        'bookmark-empty-1',
                        'bookmark-3',
                        'book-4',
                        'inbox-alt',
                        'inbox-circled',
                        'inbox-3',
                        'lightbulb-alt',
                        'chart-circled',
                        'chart-2',
                        'googleplus',
                        'globe-alt-1',
                        'folder-close',
                        'folder-5',
                        'flickr-4',
                        'flag-circled',
                        'eye-off-1',
                        'exclamation',
                        'error-alt',
                        'error',
                        'comment-5',
                        'cogs',
                        'cog-circled',
                        'cog-5',
                        'calendar-circled',
                        'calendar-7',
                        'megaphone-2',
                        'briefcase-3',
                        'vkontakte-2',
                        'th-list-4',
                        'warning-1',
                        'tumblr-4',
                        'zoom-out-4',
                        'youtube-3',
                        'website-circled',
                        'volume-up-3',
                        'trash-7',
                        'tag-5',
                        'volume-down-2',
                        'user-6',
                        'volume-off-4',
                        'trash-circled',
                        'view-mode',
                        'upload-5',
                        'video-4',
                        'lock-open-6',
                        'accessibility',
                        'w3c-1',
                        'clock-6',
                        'stop-circled',
                        'wrench-4',
                        'tint-1',
                        'vimeo-5',
                        'video-circled',
                        'universal-access-1',
                        'step-forward',
                        'lock-open-alt-2',
                        'star-empty-3',
                        'zoom-in-4',
                        'clock-circled',
                        'tags-2',
                        'th-3',
                        'th-large-2',
                        'thumbs-down-4',
                        'website',
                        'text-width-1',
                        'step-backward',
                        'thumbs-up-4',
                        'text-height-1',
                        'stumbleupon-3',
                        'video-chat',
                        'tasks-1',
                        'stop-6',
                        'wordpress-3',
                        'twitter-7',
                        'torso',
                        'wrench-circled'
                    ],
                    entypo: [
                        'brush-1',
                        'paper-plane-1',
                        'minus-squared-1',
                        'back',
                        'bookmarks',
                        'user-add',
                        'link-1',
                        'home-1',
                        'users-1',
                        'trash-1',
                        'login-1',
                        'publish',
                        'eye-1',
                        'tag-1',
                        'list-add',
                        'rss-1',
                        'lock-open-1',
                        'chart-line-1',
                        'cd',
                        'doc-text-1',
                        'chat-1',
                        'minus-circled-1',
                        'compass-1',
                        'music-1',
                        'github-1',
                        'flashlight',
                        'upload-cloud-1',
                        'cancel-squared',
                        'plus-squared-1',
                        'down-open-1',
                        'right-circled-1',
                        'docs-1',
                        'up-open-big',
                        'down-1',
                        'book-open',
                        'shareable',
                        'github-circled-1',
                        'flickr-1',
                        'tumblr-1',
                        'keyboard-1',
                        'alert',
                        'address',
                        'tools',
                        'bag',
                        'volume',
                        'light-up',
                        'adjust-1',
                        'light-down',
                        'left-1',
                        'up-1',
                        'right-1',
                        'newspaper-1',
                        'down-bold',
                        'share-1',
                        'infinity',
                        'help-circled-1',
                        'language-1',
                        'info-circled-1',
                        'droplet',
                        'flag-1',
                        'flow-cascade',
                        'note',
                        'network',
                        'heart-1',
                        'cc-share',
                        'phone-1',
                        'back-in-time',
                        'shuffle-1',
                        'clipboard',
                        'cog-1',
                        'monitor',
                        'clock-1',
                        'lifebuoy-1',
                        'block-1',
                        'dot-3',
                        'cw-1',
                        'cc-remix',
                        'play-1',
                        'pause-1',
                        'cloud-1',
                        'to-start-1',
                        'to-end-1',
                        'target-1',
                        'flash-1',
                        'chart-bar-1',
                        'key-1',
                        'thumbs-down-1',
                        'flow-branch',
                        'bell-1',
                        'level-up-1',
                        'attention-1',
                        'switch',
                        'basket-1',
                        'inbox-1',
                        'calendar-1',
                        'globe-1',
                        'bookmark-1',
                        'bucket',
                        'mute-1',
                        'flight-1',
                        'thumbs-up-1',
                        'flow-tree',
                        'sound',
                        'leaf-1',
                        'download-1',
                        'cc-1',
                        'megaphone-1',
                        'resize-full-1',
                        'magnet-1',
                        'list-1',
                        'mobile-1',
                        'resize-small-1',
                        'erase',
                        'video-1',
                        'chart-pie-1',
                        'left-dir-1',
                        'flow-line',
                        'up-dir-1',
                        'rocket-1',
                        'down-thin',
                        'cc-nd',
                        'cancel-1',
                        'up-thin',
                        'pencil-1',
                        'ccw-1',
                        'book-1',
                        'trophy',
                        'plus-circled-1',
                        'left-thin',
                        'right-thin',
                        'mic-1',
                        'moon-1',
                        'minus-1',
                        'chart-area-1',
                        'loop',
                        'install',
                        'cloud-thunder',
                        'mouse',
                        'briefcase-1',
                        'tape',
                        'graduation-cap-1',
                        'mail-1',
                        'suitcase-1',
                        'heart-empty-1',
                        'dot-2',
                        'star-1',
                        'ticket-1',
                        'user-1',
                        'water',
                        'gauge-1',
                        'traffic-cone',
                        'check-1',
                        'credit-card-1',
                        'cancel-circled-1',
                        'left-bold',
                        'plus-1',
                        'right-bold',
                        'level-down-1',
                        'help-1',
                        'stop-1',
                        'info-1',
                        'record',
                        'fast-forward',
                        'fast-backward',
                        'progress-0',
                        'lock-1',
                        'progress-1',
                        'progress-2',
                        'progress-3',
                        'palette',
                        'export-1',
                        'battery',
                        'floppy-1',
                        'quote',
                        'comment-1',
                        'database-1',
                        'drive',
                        'flow-parallel',
                        'doc-1',
                        'doc-text-inv-1',
                        'box-1',
                        'lamp',
                        'right-open-big',
                        'arrows-ccw',
                        'down-open-big',
                        'left-open-big',
                        'popup',
                        'dot',
                        'air',
                        'thermometer-1',
                        'up-bold',
                        'search-1',
                        'layout',
                        'menu-1',
                        'upload-1',
                        'print-1',
                        'vcard',
                        'star-empty-1',
                        'feather',
                        'picture-1',
                        'camera-1',
                        'cup',
                        'left-open-mini',
                        'right-open-mini',
                        'up-open-mini',
                        'gplus-1',
                        'facebook-squared-1',
                        'gplus-circled',
                        'twitter-1',
                        'vimeo-circled',
                        'vimeo-1',
                        'twitter-circled',
                        'folder-1',
                        'facebook-circled',
                        'pinterest-1',
                        'pinterest-circled-1',
                        'tumblr-circled',
                        'linkedin-1',
                        'linkedin-circled',
                        'dribbble-1',
                        'dribbble-circled',
                        'stumbleupon-1',
                        'stumbleupon-circled-1',
                        'lastfm-1',
                        'lastfm-circled',
                        'rdio',
                        'rdio-circled',
                        'spotify-1',
                        'spotify-circled',
                        'qq-1',
                        'logout-1',
                        'left-open-1',
                        'right-open-1',
                        'down-dir-1',
                        'right-dir-1',
                        'down-open-mini',
                        'signal-1',
                        'reply-1',
                        'reply-all-1',
                        'forward-1',
                        'code-1',
                        'retweet-1',
                        'doc-landscape',
                        'archive',
                        'cc-by',
                        'cc-nc',
                        'cc-nc-eu',
                        'cc-nc-jp',
                        'cc-sa',
                        'cc-zero',
                        'cc-pd',
                        'instagram-1',
                        'dropbox-1',
                        'evernote',
                        'flattr',
                        'skype-1',
                        'skype-circled',
                        'renren-1',
                        'sina-weibo',
                        'paypal-1',
                        'picasa',
                        'soundcloud-1',
                        'mixi',
                        'behance-1',
                        'google-circles',
                        'vkontakte-1',
                        'smashing',
                        'db-shape',
                        'sweden',
                        'logo-db',
                        'arrow-combo',
                        'map-1',
                        'window',
                        'note-beamed',
                        'attach-1',
                        'left-circled-1',
                        'up-open-1',
                        'up-circled-1',
                        'hourglass-4',
                        'direction-1',
                        'location-1',
                        'down-circled-1',
                        'flickr-circled',
                        'facebook-1'
                    ],
                    iconic: [
                        'pilcrow',
                        'info-3',
                        'undo',
                        'exchange-1',
                        'home-3',
                        'split',
                        'pause-3',
                        'eject-2',
                        'to-end-2',
                        'to-start-2',
                        'stop-3',
                        'play-3',
                        'sun-inv',
                        'umbrella-1',
                        'sun-2',
                        'moon-3',
                        'key-3',
                        'rain-1',
                        'cancel-3',
                        'cancel-circle',
                        'quote-left-alt',
                        'quote-right-alt',
                        'quote-left-1',
                        'quote-right-1',
                        'plus-circle',
                        'minus-circle',
                        'arrow-curved',
                        'resize-vertical-1',
                        'resize-horizontal-1',
                        'lock-open-empty',
                        'eye-3',
                        'tag-3',
                        'tag-empty',
                        'download-cloud-1',
                        'upload-cloud-3',
                        'comment-3',
                        'comment-inv',
                        'comment-inv-alt',
                        'comment-alt2',
                        'comment-inv-alt2',
                        'chat-3',
                        'chat-inv',
                        'location-3',
                        'location-inv',
                        'location-alt',
                        'compass-3',
                        'trash-3',
                        'trash-empty-1',
                        'doc-inv-1',
                        'doc-alt',
                        'doc-inv-alt',
                        'share-2',
                        'resize-full-alt-1',
                        'resize-small-alt',
                        'move-2',
                        'award-empty',
                        'list-2',
                        'list-nested',
                        'bat-empty',
                        'bat-half',
                        'bat-full',
                        'bat-charge-1',
                        'equalizer',
                        'cursor',
                        'aperture',
                        'aperture-alt',
                        'steering-wheel',
                        'brush-3',
                        'brush-alt',
                        'eyedropper-1',
                        'layers',
                        'layers-alt',
                        'moon-inv',
                        'chart-pie-alt',
                        'dial',
                        'pin-2',
                        'upload-3',
                        'cw-3',
                        'camera-3',
                        'calendar-inv',
                        'chart-bar-3',
                        'award-1',
                        'plus-3',
                        'cd-1',
                        'mail-3',
                        'heart-3',
                        'chart-pie-3',
                        'star-3',
                        'ok-2',
                        'help-2',
                        'flash-3',
                        'play-circle2',
                        'download-3',
                        'pencil-3',
                        'pencil-alt',
                        'article',
                        'article-alt',
                        'wrench-2',
                        'calendar-3',
                        'volume-off-2',
                        'volume-up-1',
                        'resize-full-3',
                        'resize-small-2',
                        'down-3',
                        'up-3',
                        'mobile-3',
                        'book-2',
                        'at-2',
                        'hash',
                        'pencil-neg',
                        'left-3',
                        'right-3',
                        'cloud-3',
                        'minus-3',
                        'heart-empty-2',
                        'user-3',
                        'picture-3',
                        'doc-3',
                        'rss-3',
                        'cog-3',
                        'clock-3',
                        'popup-3',
                        'block-3',
                        'target-3',
                        'lock-empty',
                        'ok-circle',
                        'comment-alt',
                        'folder-3',
                        'rss-alt',
                        'down-circle',
                        'left-circle',
                        'right-circle',
                        'up-circle',
                        'signal-2',
                        'mic-3',
                        'headphones-2',
                        'video-3',
                        'lamp-1',
                        'folder-empty-1',
                        'calendar-alt',
                        'attach-3',
                        'book-alt',
                        'book-open-1',
                        'box-3',
                        'loop-alt-1',
                        'loop-2',
                        'search-3',
                        'key-inv',
                        'lock-3',
                        'lock-open-3',
                        'link-3'
                    ],
                    linecons: [
                        'heart-7',
                        'cloud-7',
                        'star-7',
                        'tv',
                        'sound-1',
                        'videocam-5',
                        'trash-8',
                        'user-7',
                        'key-5',
                        'search-7',
                        'cog-6',
                        'camera-7',
                        'tag-6',
                        'lock-7',
                        'lightbulb-3',
                        'pencil-7',
                        'diamond-1',
                        'desktop-3',
                        'location-7',
                        'eye-7',
                        'comment-6',
                        'inbox-4',
                        'cup-1',
                        'mobile-6',
                        'doc-7',
                        'mail-7',
                        'thumbs-up-5',
                        'photo-1',
                        'note-1',
                        'clock-7',
                        'paper-plane-3',
                        'params',
                        'money-2',
                        'database-3',
                        'music-4',
                        'megaphone-3',
                        'graduation-cap-2',
                        'beaker-1',
                        'food-1',
                        't-shirt',
                        'fire-3',
                        'attach-6',
                        'shop-1',
                        'calendar-8',
                        'wallet',
                        'cd-3',
                        'truck-1',
                        'globe-6'
                    ],
                    maki: [
                        'campsite',
                        'college',
                        'golf',
                        'monument',
                        'school',
                        'fire-station',
                        'airport',
                        'ferry',
                        'fuel',
                        'bus-1',
                        'warehouse',
                        'museum',
                        'pharmacy',
                        'religious-christian',
                        'religious-islam',
                        'religious-jewish',
                        'restaurant',
                        'town-hall',
                        'toilet',
                        'theatre',
                        'swimming',
                        'skiing',
                        'rail',
                        'roadblock',
                        'harbor',
                        'heliport',
                        'hospital-1',
                        'industrial-building',
                        'soccer',
                        'tree-2',
                        'library',
                        'lodging',
                        'minefield',
                        'credit-card-3',
                        'commerical-building',
                        'airfield',
                        'fast-food',
                        'aboveground-rail',
                        'art-gallery',
                        'bar',
                        'baseball',
                        'football',
                        'garden',
                        'giraffe',
                        'basketball',
                        'beer-2',
                        'bicycle-1',
                        'cafe',
                        'pitch',
                        'police',
                        'grocery-store',
                        'post',
                        'embassy',
                        'tree-3',
                        'cemetery',
                        'cinema',
                        'shop',
                        'cricket',
                        'tennis',
                        'prison',
                        'belowground-rail',
                        'london-underground',
                        'trash-6'
                    ],
                    mfg_labs: [
                        'left-4',
                        'up-5',
                        'right-4',
                        'down-5',
                        'left-fat',
                        'up-fat',
                        'right-fat',
                        'down-fat',
                        'left-bold-1',
                        'up-bold-1',
                        'right-bold-1',
                        'down-bold-1',
                        'hourglass-5',
                        'stopwatch-1',
                        'clock-5',
                        'star-empty-2',
                        'female-1',
                        'male-1',
                        'king',
                        'heart-5',
                        'anchor-2',
                        'flash-4',
                        'mail-5',
                        'pencil-5',
                        'vector-pencil',
                        'cancel-5',
                        'help-circled-2',
                        'help-circled-alt',
                        'attention-alt-1',
                        'cog-4:before',
                        'home-5',
                        'attention-4',
                        'star-5',
                        'plus-5',
                        'at-4',
                        'ok-4',
                        'cloud-5',
                        'down-open-2',
                        'up-open-2',
                        'right-open-3',
                        'left-open-3',
                        'menu-3',
                        'th-list-3',
                        'th-thumb',
                        'th-thumb-empty',
                        'coverflow',
                        'coverflow-empty',
                        'pause-4',
                        'play-4',
                        'to-end-3',
                        'to-start-3',
                        'fast-forward-1',
                        'fast-backward-1',
                        'upload-cloud-4',
                        'download-cloud-2',
                        'data-science',
                        'data-science-inv',
                        'globe-4',
                        'globe-inv',
                        'math',
                        'math-circled-empty',
                        'math-circled',
                        'paper-plane-2',
                        'paper-plane-alt2',
                        'paper-plane-alt',
                        'color-adjust',
                        'star-half-1',
                        'star-half_empty',
                        'ccw-2',
                        'heart-broken',
                        'hash-1',
                        'reply-3',
                        'retweet-2',
                        'login-2',
                        'logout-2',
                        'download-5',
                        'upload-4',
                        'location-5',
                        'monitor-1',
                        'tablet-3',
                        'mobile-5',
                        'connected-object',
                        'isight',
                        'videocam-3',
                        'shuffle-3',
                        'chat-5',
                        'bell-4',
                        'movie',
                        'ruler',
                        'vector',
                        'mic-off',
                        'mic-4',
                        'doc-5',
                        'dribbble-circled-2',
                        'dribbble-3',
                        'facebook-circled-2',
                        'facebook-4',
                        'github-circled-alt',
                        'github-circled-3',
                        'github-3',
                        'github-circled-alt2',
                        'twitter-circled-2',
                        'twitter-4',
                        'gplus-circled-1',
                        'gplus-2',
                        'linkedin-circled-2',
                        'linkedin-3',
                        'instagram-2',
                        'instagram-circled',
                        'mfg-logo',
                        'mfg-logo-circled',
                        'user-5',
                        'user-male',
                        'user-female',
                        'users-3',
                        'folder-4',
                        'folder-open-1',
                        'folder-empty-2',
                        'attach-4',
                        'ok-circled-1',
                        'cancel-circled-3',
                        'inbox-2',
                        'trophy-1',
                        'lock-open-alt-1',
                        'link-4',
                        'zoom-in-3',
                        'zoom-out-3',
                        'stop-5',
                        'export-4',
                        'eye-5',
                        'trash-5',
                        'hdd-1',
                        'info-circled-2',
                        'info-circled-alt',
                        'print-4',
                        'fontsize',
                        'soundcloud-2',
                        'soundcloud-circled',
                        'comment-4',
                        'folder-open-empty-1',
                        'calendar-5',
                        'newspaper-2',
                        'camera-5',
                        'search-5',
                        'lock-alt',
                        'lock-5',
                        'lock-open-5',
                        'joystick',
                        'fire-1',
                        'chart-bar-5',
                        'spread',
                        'spinner1',
                        'spinner2'
                    ],
                    modern_pictograms: [
                        'up-dir-2',
                        'left-dir-2',
                        'pencil-alt-1',
                        'cw-4',
                        'squares',
                        'article-1',
                        'article-alt-1',
                        'basket-3',
                        'calendar-alt-1',
                        'resize-small-3',
                        'zoom-in-2',
                        'zoom-out-2',
                        'signal-3',
                        'chart-pie-4',
                        'basket-alt',
                        'mobile-alt',
                        'tablet-2',
                        'ipod',
                        'stop-4',
                        'grid',
                        'easel',
                        'eye-4',
                        'videocam-2',
                        'thumbs-up-3',
                        'download-4',
                        'pencil-4',
                        'edit-2',
                        'print-3',
                        'trash-4',
                        'doc-4',
                        'export-3',
                        'down-4',
                        'up-4',
                        'at-3',
                        'youtube-1',
                        'money-1',
                        'plus-4',
                        'mail-4',
                        'heart-4',
                        'star-4',
                        'ok-3',
                        'home-4',
                        'colon',
                        'semicolon',
                        'info-4',
                        'question',
                        'th-2',
                        'th-list-2',
                        'attention-3',
                        'facebook-3',
                        'twitter-3',
                        'twitter-rect',
                        'resize-full-4',
                        'facebook-rect',
                        'twitter-bird',
                        'user-4',
                        'cancel-4',
                        'cancel-circle-1',
                        'lock-4',
                        'lock-open-4',
                        'tag-4',
                        'chat-4',
                        'location-4',
                        'rss-4',
                        'clock-4',
                        'popup-4',
                        'down-dir-2',
                        'right-dir-2',
                        'globe-3',
                        'video-alt',
                        'award-2',
                        'videocam-alt',
                        'thumbs-down-3',
                        'user-woman',
                        'user-pair',
                        'dollar-1',
                        'cd-2',
                        'calendar-4',
                        'chart-1',
                        'chart-bar-4',
                        'book-3',
                        'bullhorn',
                        'mobile-4',
                        'camera-4',
                        'volume-off-3',
                        'volume-down-1',
                        'volume-up-2',
                        'search-4',
                        'bell-3',
                        'wrench-3',
                        'clock-alt'
                    ],
                    typicons: [
                        'infinity-1',
                        'scissors-1',
                        'pencil-2',
                        'user-add-1',
                        'user-delete-outline',
                        'user-delete',
                        'user-outline',
                        'user-2',
                        'videocam-outline',
                        'videocam-1',
                        'volume-middle',
                        'volume-off-1',
                        'volume-high',
                        'volume-low',
                        'warning-empty',
                        'warning',
                        'wristwatch',
                        'waves-outline',
                        'waves',
                        'cloud-2',
                        'rain',
                        'moon-2',
                        'cloud-sun',
                        'drizzle',
                        'snow',
                        'cloud-flash',
                        'cloud-wind',
                        'wind',
                        'wifi-outline',
                        'wifi-1',
                        'wine',
                        'globe-outline',
                        'zoom-in-outline',
                        'zoom-in-1',
                        'zoom-out-outline',
                        'zoom-out-1',
                        'search-outline',
                        'search-2',
                        'mic-2',
                        'loop-1',
                        'wrench-1',
                        'mobile-2',
                        'chart',
                        'cog-2',
                        'clock-2',
                        'block-2',
                        'cw-2',
                        'upload-cloud-2',
                        'target-2',
                        'sun-1',
                        'flash-2',
                        'chart-bar-2',
                        'key-2',
                        'thumbs-down-2',
                        'attention-filled',
                        'globe-alt',
                        'reply-outline',
                        'anchor-1',
                        'tumbler-circled',
                        'flow-parallel-1',
                        'chart-bar-outline',
                        'sort-alphabet',
                        'sort-alphabet-outline',
                        'news',
                        'minus-2',
                        'pinterest-2',
                        'briefcase-2',
                        'popup-2',
                        'resize-normal-outline',
                        'link-2',
                        'link-outline',
                        'resize-full-2',
                        'resize-full-outline',
                        'vimeo-2',
                        'map-2',
                        'loop-alt',
                        'download-outline',
                        'stop-2',
                        'eject-outline',
                        'edit-1',
                        'attach-outline',
                        'attach-2',
                        'at-1',
                        'loop-alt-outline',
                        'flag-filled',
                        'flash-outline',
                        'compass-2',
                        'cog-outline',
                        'divide-outline',
                        'address-1',
                        'folder-delete',
                        'folder-add',
                        'laptop-1',
                        'desktop-1',
                        'pinterest-circled-2',
                        'linkedin-2',
                        'down-2',
                        'down-outline',
                        'flag-2',
                        'video-2',
                        'heart-2',
                        'headphones-1',
                        'move-1',
                        'move-outline',
                        'eject-alt',
                        'bat3',
                        'bat4',
                        'stopwatch',
                        'certificate-1',
                        'twitter-circled-1',
                        'temperatire',
                        'up-2',
                        'up-small',
                        'power-outline',
                        'looped-square-interest',
                        'resize-normal',
                        'chart-alt-outline',
                        'cancel-circled-2',
                        'cancel-circled-outline',
                        'chart-alt',
                        'export-2',
                        'export-outline',
                        'plug-1',
                        'plane',
                        'sort-numeric',
                        'tablet-1',
                        'right-open-outline',
                        'ticket-2',
                        'play-2',
                        'box-2',
                        'down-small',
                        'ok-1',
                        'ok-outline',
                        'globe-alt-outline',
                        'play-outline',
                        'pause-2',
                        'right-small',
                        'right-2',
                        'plus-2',
                        'plus-outline',
                        'plane-outline',
                        'pipette',
                        'th-outline',
                        'lock-2',
                        'menu-2',
                        'lock-filled',
                        'chart-pie-2',
                        'eq-outline',
                        'eject-1',
                        'cancel-2',
                        'cancel-outline',
                        'archive-1',
                        'block-outline',
                        'tree-1',
                        'trash-2',
                        'gift-1',
                        'folder-2',
                        'left-outline',
                        'left-2',
                        'vcard-1',
                        'forward-2',
                        'music-2',
                        'up-outline',
                        'arrows-cw-1',
                        'linkedin-circled-1',
                        'th-large-outline',
                        'chat-2',
                        'code-outline',
                        'pi-outline',
                        'clipboard-1',
                        'right-open-2',
                        'flow-cross',
                        'contrast',
                        'sun-filled',
                        'record-outline',
                        'rss-outline',
                        'cw-outline',
                        'dribbble-2',
                        'dribbble-circled-1',
                        'minus-outline',
                        'target-outline',
                        'puzzle-1',
                        'star-filled',
                        'star-2',
                        'leaf-2',
                        'key-outline',
                        'coffee-1',
                        'code-2',
                        'contacts',
                        'database-2',
                        'doc-remove',
                        'doc-add',
                        'divide',
                        'flask',
                        'bat2',
                        'eye-2',
                        'eye-outline',
                        'upload-cloud-outline',
                        'sort-numeric-outline',
                        'comment-2',
                        'left-open-2',
                        'mail-2',
                        'lock-open-filled',
                        'direction-2',
                        'direction-outline',
                        'bell-2',
                        'beer-1',
                        'chart-outline',
                        'camera-2',
                        'chart-pie-outline',
                        'infinity-outline',
                        'picture-2',
                        'flow-merge',
                        'tags-1',
                        'tag-2',
                        'popup-1',
                        'skype-outline',
                        'menu-outline',
                        'wrench-outline',
                        'doc-text-2',
                        'picture-outline',
                        'location-2',
                        'location-outline',
                        'rss-2',
                        'info-outline',
                        'cancel-alt-filled',
                        'cancel-alt',
                        'facebook-2',
                        'flickr-2',
                        'facebook-circled-1',
                        'flickr-circled-1',
                        'home-outline',
                        'check-2',
                        'check-outline',
                        'upload-2',
                        'flow-split',
                        'left-open-outline',
                        'at-circled',
                        'certificate-outline',
                        'lifebuoy-2',
                        'github-2',
                        'arrows-cw-outline',
                        'rewind-outline',
                        'record-1',
                        'chat-alt',
                        'twitter-2',
                        'users-2',
                        'th-list-outline',
                        'th-large-1',
                        'pause-outline',
                        'upload-outline',
                        'heart-filled',
                        'pen',
                        'rewind',
                        'bat-charge',
                        'vimeo-circled-1',
                        'fast-fw-outline',
                        'calendar-outlilne',
                        'calculator',
                        'lastfm-2',
                        'lastfm-circled-1',
                        'mic-outline',
                        'skype-2',
                        'music-outline',
                        'info-2',
                        'eq',
                        'github-circled-2',
                        'lightbulb-1',
                        'reply-2',
                        'loop-outline',
                        'users-outline',
                        'lock-open-2',
                        'basket-2',
                        'scissors-outline',
                        'stop-outline',
                        'fast-fw-1',
                        'puzzle-outline',
                        'print-2',
                        'power',
                        'looped-square-outline',
                        'eject-alt-outline',
                        'th-1',
                        'pin-1',
                        'pin-outline',
                        'pi',
                        'phone-outline',
                        'tumbler',
                        'th-list-1',
                        'attention-2',
                        'home-2',
                        'feather-1',
                        'doc-2',
                        'credit-card-2',
                        'camera-outline',
                        'calendar-2',
                        'brush-2',
                        'bat1',
                        'right-outline',
                        'left-small',
                        'user-add-outline',
                        'forward-outline',
                        'anchor-outline',
                        'globe-2',
                        'thumbs-up-2',
                        'phone-2',
                        'download-2',
                        'shuffle-2',
                        'bookmark-2'
                    ],
                    web_symbols: [
                        'progress-5:before',
                        'progress-7:before',
                        'progress-8:before',
                        'progress-9:before',
                        'right-dir-3',
                        'down-dir-3',
                        'star-8',
                        'th-list-5',
                        'heart-empty-4',
                        'th-4',
                        'cog-7',
                        'attention-5',
                        'ok-6',
                        'ok-circle-1',
                        'forward-4',
                        'minus-circle-1',
                        'lock-8',
                        'tag-7',
                        'chat-6',
                        'lock-open-7',
                        'video-5',
                        'progress-4',
                        'cancel-7',
                        'mail-8',
                        'attach-7',
                        'retweet-4',
                        'search-8',
                        'location-8',
                        'comment-7',
                        'plus-circle-1',
                        'link-5',
                        'progress-6',
                        'cancel-circle-2',
                        'heart-8',
                        'left-circle-1',
                        'right-circle-1',
                        'up-circle-1',
                        'down-circle-1',
                        'th-large-3',
                        'reply-4',
                        'reply-all-2',
                        'code-3',
                        'comment-alt-2',
                        'doc-8',
                        'docs-landscape',
                        'archive-2',
                        'rss-7',
                        'rss-alt-1',
                        'logout-3',
                        'resize-full-6',
                        'popup-5',
                        'left-open-5',
                        'right-open-5',
                        'arrows-cw-3',
                        'resize-full-circle',
                        'down-micro',
                        'up-micro',
                        'cw-circle',
                        'updown-circle',
                        'progress-10',
                        'progress-11',
                        'font-2',
                        'list-4',
                        'list-numbered-1',
                        'indent-left-2',
                        'indent-right-2',
                        'cloud-8',
                        'terminal-1',
                        'facebook-rect-2',
                        'twitter-bird-2',
                        'googleplus-rect-1',
                        'vkontakte-rect-1',
                        'skype-6',
                        'odnoklassniki-rect-1',
                        'vimeo-rect-1',
                        'tumblr-rect-1',
                        'linkedin-rect-1',
                        'youtube-4',
                        'picture-5',
                        'target-5',
                        'user-8',
                        'folder-6',
                        'signal-5',
                        'clock-8',
                        'block-5'
                    ],
                    zocial: [
                        'duckduckgo',
                        'aim:before',
                        'delicious-1',
                        'paypal-2:before',
                        'flattr-1',
                        'android-1:before',
                        'eventful',
                        'smashmag',
                        'wikipedia',
                        'lanyrd',
                        'calendar-6',
                        'stumbleupon-2',
                        'fivehundredpx:before',
                        'bitcoin-1:before',
                        'grooveshark:before',
                        'ninetyninedesigns:before',
                        'forrst',
                        'digg-1',
                        'spotify-2',
                        'reddit-1:before',
                        'guest',
                        'gowalla',
                        'appstore:before',
                        'blogger:before',
                        'cc-2:before',
                        'dribbble-4:before',
                        'evernote-1:before',
                        'flickr-3:before',
                        'google-1:before',
                        'viadeo-1:before',
                        'instapaper:before',
                        'weibo-1:before',
                        'klout:before',
                        'linkedin-4:before',
                        'meetup-1:before',
                        'vk:before',
                        'plancast:before',
                        'disqus:before',
                        'rss-5:before',
                        'skype-3:before',
                        'twitter-5:before',
                        'youtube-2:before',
                        'vimeo-3:before',
                        'windows-1:before',
                        'xing-1:before',
                        'yahoo-1:before',
                        'chrome-1',
                        'email',
                        'macstore',
                        'myspace:before',
                        'podcast-1',
                        'amazon-1:before',
                        'steam-1:before',
                        'cloudapp:before',
                        'dropbox-2:before',
                        'ebay:before',
                        'facebook-5:before',
                        'github-4:before',
                        'googleplay:before',
                        'itunes:before',
                        'plurk:before',
                        'songkick:before',
                        'lastfm-3:before',
                        'gmail:before',
                        'pinboard:before',
                        'openid-1:before',
                        'quora-1:before',
                        'soundcloud-3:before',
                        'tumblr-2:before',
                        'eventasaurus:before',
                        'wordpress-1:before',
                        'yelp-1:before',
                        'intensedebate',
                        'eventbrite',
                        'scribd-1',
                        'posterous',
                        'stripe:before',
                        'opentable:before',
                        'cart:before',
                        'print-5:before',
                        'angellist-1:before',
                        'instagram-3:before',
                        'dwolla:before',
                        'appnet:before',
                        'statusnet:before',
                        'acrobat:before',
                        'drupal-1:before',
                        'buffer:before',
                        'pocket:before',
                        'github-circled-4:before',
                        'bitbucket-1:before',
                        'lego:before',
                        'login-3:before',
                        'stackoverflow-1:before',
                        'hackernews:before',
                        'lkdto:before',
                        'gplus-3',
                        'pinterest-3',
                        'w3c',
                        'foursquare-1',
                        'html5-1',
                        'ie',
                        'call'
                    ]
                }
            }
        },
        methods: {
            hIconClick(i) {
                this.copyToClipboard(i);
            },
            hIconBtnClick(sel) {
                this.selIconLib = sel === this.selIconLib ? null : sel;
            },
            copyToClipboard(text) {
                navigator.clipboard.writeText(text).then(() => {
                    console.log("copied to clipboard! (" + text + ")");
                }, () => {
                    console.error("clipboard failed...");
                });
            },
            getTooltipArgs(e) {
                return {
                    text: e,
                };
            }
        },
        computed: {
            showDoc() { return this.tab === 'docdemo' || this.tab === 'doc' },
            showCode() { return this.tab === 'docdemo' || this.tab === 'demo' },
            selIconLibList() { return this.selIconLib ? this[this.selIconLib] : [] }
        }
    }
</script>