<style lang="scss">
    // Media queries thresholds
    @import "../../EgElementsProject/src/scss/thresholds";

    .demo-footer {
        text-align: center;
        margin-top: 10em;
        opacity: 0.5;
        padding: 2em;
        .sep {
            display: inline-block;
            margin: 0 10px;
            opacity: 0.5
        }
        @media all and (max-width: $Classic) {
            margin-top: 2em;
        }
    }
</style>

<template>
    <div class="demo-footer">
        By Erwan Guyomarch - 2020 <span class="sep">|</span> <eg-button link @click="toTop">Back to the top</eg-button>
    </div>
</template>

<script>
    export default {
        computed: {
            y() { return new Date().getFullYear() }
        },
        methods: {
            toTop() {
                window.scrollTo(0,0);
            },
        }
    }
</script>