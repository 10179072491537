<style lang="scss">
    // Media queries thresholds
    @import "../../EgElementsProject/src/scss/thresholds";

    .demo-scroll-menu {
        flex: 1;
        @media all and (max-width: $Classic) {
            background-color: rgba(var(--rgb-default), 0.4);
            padding-top: 1em;
            border-top: thin solid #ccc;
            border-bottom: thin solid #ccc;
        }
        aside {
            top: 1.5em;
            width: 200px;
            position: sticky;
        }
        .demo-scroll-menu-content {
            margin-right: 4em;
            margin-top: 2em;

            .eg-button {
                box-sizing: border-box;
            }
            @media all and (max-width: $Classic) {
                margin: 0;
                padding-bottom: 1em;
            }
        }
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                position: relative;
                color: #AAA;
                a {
                    position: relative;
                    display: inline-block;
                    padding: 1em 1.5em;
                    text-decoration: none;
                    color: currentColor;
                    width: 100%;
                    box-sizing: border-box;
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        width: 1px;
                        background-color: currentColor;
                        opacity: 0.5;
                        transition: all .2s;
                        @media all and (max-width: $Classic) {
                            opacity: 1;
                            left: 2px;
                            width: 3px;
                        }
                    }
                }
                &.visible > a {
                    color: var(--color-primary);
                    &::after {
                        background-color: var(--color-primary);
                        opacity: 1;
                    }
                }
                ul {
                    margin-right: 0;
                    li {
                        margin-bottom: 0;
                        a {
                            padding: 0.75em 1em 0.75em 3em;
                            font-size: 0.9em;
                            &:last-child {
                                padding-bottom: 1.5em;
                            }
                        }
                        ul {
                            li {
                                a {
                                    padding: 0.75em 1em 0.75em 4em;
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }
        }
        .buttons-box {
            border: 1px solid #ccc;
            margin-top: 2em;
            padding: 1em;
            opacity: 0;
            transition: opacity .2s;
            width: 100%;
            .eg-button:first-child {
                margin: 0;
            }
            @media all and (max-width: $Classic) {
                display: none;
            }
        }
        &:hover {
            .buttons-box {
                opacity: 0.6;
            }
        }
        &.under-constraint {
            ul {
                overflow-y: auto;
                max-height: 80vh;
                &::-webkit-scrollbar {
                    opacity: 0;
                    transition: opacity .2s;
                    width: 0;
                }
                &::-webkit-scrollbar-track { background: #FFF; }
                &::-webkit-scrollbar-thumb {
                    cursor: pointer;
                    background: rgba(0,0,0,.1);
                    &:hover {
                        background: rgba(0,0,0,.3);
                    }
                }
                &::-webkit-scrollbar {
                    width: 5px;
                    opacity: 1;
                }
                @media all and (max-width: $Classic) {
                    max-height: none;
                }
            }
        }
    }
    .menu-top-title {
        font-size: 1.4em;
        color: var(--color-primary);
        margin: 0;
        max-height: 0;
        display: block;
        transition: max-height .2s, color .2s;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            color: #AAA;
        }
        &.visible {
            display: inline-block;
            margin: 0 0 1em 0;
            max-height: 50px;
        }
        @media all and (max-width: $Classic) {
            margin: 0;
            max-height: 50px;
            &.visible {
                margin: 0;
            }
        }
    }
    .toggle-navigation-btn {
        padding: 0 10px;
        box-sizing: border-box;
        @media all and (min-width: $Classic) {
            display: none;
        }
    }

    .clap-enter-active, .clap-leave-active {
        max-width: 150px;
        transition: max-width .1s ease-out, max-height .3s ease-out;
        overflow-x: hidden;
        @media all and (max-width: $Classic) {
            max-height: 100vh;
            overflow-y: hidden;
        }
    }
    .clap-enter, .clap-leave-to /* .fade-leave-active below version 2.1.8 */ {
        max-width: 0;
        overflow-x: hidden;
        transition: max-width .3s ease-out, max-height .3s ease-out;
        @media all and (max-width: $Classic) {
            max-width: none;
            max-height: 0;
            overflow-y: hidden;
        }
    }

</style>

<template>
    <div class="demo-scroll-menu" :class="{'under-constraint':showTopButton}">
        <transition name="clap">
            <aside v-if="value">
                <div class="toggle-navigation-btn">
                    <eg-button wide @click="update">Hide navigation</eg-button>
                </div>
                <div class="demo-scroll-menu-content">
                    <span class="menu-top-title" v-visible.above="'.tab-list'" @click="toTop">{{ name }}</span>
                    <ul>
                        <li v-for="i in items" :key="i.name" v-visible="{t:getSelector(i)}">
                            <a :href="getSelector(i)">{{ i.name }}</a>
                            <template v-if="i.children && i.children.length > 0">
                                <ul>
                                    <li v-for="i2 in i.children" :key="i2.name" v-visible="getSelector(i2)">
                                        <a :href="getSelector(i2)">{{ i2.name }}</a>
                                        <template v-if="i2.children && i2.children.length > 0">
                                            <ul>
                                                <li v-for="i3 in i2.children" :key="i3.name" v-visible="getSelector(i3)">
                                                    <a :href="getSelector(i3)">{{ i3.name }}</a>
                                                </li>
                                            </ul>
                                        </template>
                                    </li>
                                </ul>
                            </template>
                        </li>
                    </ul>
                    <div class="buttons-box">
                        <eg-button wide @click="hHide" lSlideIcon="left-open-1" link>Hide navigation</eg-button>
                    </div>
                </div>
            </aside>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            name:{type: String, default: null},
            items: {type: Array, default() {return []}},
            value: {type: Boolean, default: true}
        },
        data() {
            return {
                showTopButton: false
            }
        },
        methods: {
            toTop() {
                if(this.$route.hash) this.$router.push({name: this.$route.name, hash: ""});
                else this.$router.push({name: this.$route.name, hash: "#"});
                window.scrollTo(0,0);
            },
            hHide() {
                this.$emit('input', false);
            },
            getSelector(item) {
                if(item.selector) {
                    return item.selector;
                }
                return "#" + item.name.toKebabCase();
            },
            hScroll() {
                //let target = document.querySelector('.eg-elements-page-wrapper');
                let target = document.querySelector('main');
                let distance = target.getBoundingClientRect();
                let tolerance = 50;
                this.showTopButton = distance.top < tolerance;
            },
            update() {
                this.$emit('input', !this.value);
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.hScroll);
        },
        mounted() {
            window.addEventListener('scroll', this.hScroll);
            this.hScroll();
        }
    }
</script>