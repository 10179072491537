<template>
    <demo-page name="Special Icons" :menu="menu">
        <template slot="top">
            <p>Special icons are elements to be used like icons but which have their own components because they have a very specific logic or style (animations for example).</p>
        </template>
        <template>

            <div id="hamburger-icon">
                <h2><a href="#hamburger-icon">Hamburger Icon</a></h2>
                <div class="show-doc">
                    <p>The hamburger icon is a classic icon that represents three vertical bars visible when the icon is not active (when a menu is closed for example) and changes its aspect with an efficient animation when it is clicked or active.</p>
                    <p>This component is based on the <a href="https://jonsuh.com/hamburgers">https://jonsuh.com/hamburgers</a> library which includes the following animations:</p>
                    <p>
                        <span v-tooltip="'hamburger--slider'">Slider</span>,
                        <span v-tooltip="'hamburger--squeeze'">Squeeze</span>,
                        <span v-tooltip="'hamburger--arrow'">Arrow</span>,
                        <span v-tooltip="'hamburger--arrowalt'">Arrow Alt</span>,
                        <span v-tooltip="'hamburger--arrowturn'">Arrow Turn</span>,
                        <span v-tooltip="'hamburger--spin'">Spin</span>,
                        <span v-tooltip="'hamburger--elastic'">Elastic</span>,
                        <span v-tooltip="'hamburger--emphatic'">Emphatic</span>,
                        <span v-tooltip="'hamburger--collapse'">Collapse</span>,
                        <span v-tooltip="'hamburger--vortex'">Vortex</span>,
                        <span v-tooltip="'hamburger--stand'">Stand</span>,
                        <span v-tooltip="'hamburger--spring'">Spring</span>,
                        <span v-tooltip="'hamburger--minus'">Minus</span>,
                        <span v-tooltip="'hamburger--3dx'">3DX</span>,
                        <span v-tooltip="'hamburger--3dy'">3DY</span>,
                        <span v-tooltip="'hamburger--3dxy'">3DXY</span>,
                        <span v-tooltip="'hamburger--boring'">Boring</span>
                    </p>
                </div>

                <div id="usage">
                    <h3><a href="#usage">Usage</a></h3>
                    <div class="show-doc">
                        <p>You can use this icon by default using <code>&lt;hamburger-icon&gt;</code>. The chosen animation can simply be passed to the class, and the &quot;active&quot; attribute changes the state and appearance of the icon.</p>
                        <pre><code class="html">&lt;hamburger-icon class=&quot;hamburger--collapse&quot; :active=&quot;showMenu&quot; @click=&quot;showMenu=!showMenu&quot;&gt;&lt;/hamburger-icon&gt;
</code></pre>
                    </div>
                </div>
                <div id="attributes">
                    <h3><a href="#attributes">Attributes</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>active</strong>: {default: false}: If the icon should be active (alternative visual aspect).</li>
                        </ul>
                    </div>
                </div>
                <div id="events">
                    <h3><a href="#events">Events</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>@click</strong>: when the icon is clicked (default event payload).</li>
                        </ul>
                    </div>
                    <eg-select :options="options" oMap="value" v-model="selectedAnimation"></eg-select>

                    <demo-panel title="Hamburger demo" :code="demo.a">
                        <hamburger-icon :class="selectedAnimation" @click="s=!s" :active="s"></hamburger-icon>
                    </demo-panel>
                </div>
            </div>

        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                val: 0,
                menu: [
                    {
                        name: "Hamburger Icon",
                        children: [
                            {name: "Usage"},
                            {name: "Attributes"},
                            {name: "Events"},
                        ]
                    },
                ],
                s: false,
                selectedAnimation: "hamburger--slider",
                options: [
                    {name: "Slider", value:  "hamburger--slider"},
                    {name: "Squeeze", value:  "hamburger--squeeze"},
                    {name: "Arrow", value:  "hamburger--arrow"},
                    {name: "Arrow Alt", value:  "hamburger--arrowalt"},
                    {name: "Arrow Turn", value:  "hamburger--arrowturn"},
                    {name: "Spin", value:  "hamburger--spin"},
                    {name: "Elastic", value:  "hamburger--elastic"},
                    {name: "Emphatic", value:  "hamburger--emphatic"},
                    {name: "Collapse", value:  "hamburger--collapse"},
                    {name: "Vortex", value:  "hamburger--vortex"},
                    {name: "Stand", value:  "hamburger--stand"},
                    {name: "Spring", value:  "hamburger--spring"},
                    {name: "Minus", value:  "hamburger--minus"},
                    {name: "3DX", value:  "hamburger--3dx"},
                    {name: "3DY", value:  "hamburger--3dy"},
                    {name: "3DXY", value:  "hamburger--3dxy"},
                    {name: "Boring", value:  "hamburger--boring"},
                ]
            }
        },
        computed: {
            demo() {
                return {
                    a:`<hamburger-icon class="${this.selectedAnimation}" @click="s=!s" :active="s"></hamburger-icon>`
                }
            }
        }
    }
</script>