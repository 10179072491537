import Vue from 'vue'
Vue.config.productionTip = false;
window.Vue = require('vue');

import VueRouter from 'vue-router'
Vue.use(VueRouter);

import ege from '../EgElementsProject'
Vue.use(ege, {
    theme: "basic",
    icons: [
        'brandico',
        'elusive',
        'entypo',
        'font_awesome',
        'iconic',
        'linecons',
        'maki',
        'mfg_labs',
        'modern_pictograms',
        'typicons',
        'web_symbols',
        'zocial',
    ]
});
Vue.use(ege, {
    theme: "basic",
    components: {
        icon: "icon",
        input: "text-input",
        numberInput: "eg-number"
    },
    icons: ['brandico', 'elusive']
});

import DemoPanel from './EgElementsDemo/DemoPanel'
Vue.component('demo-panel', DemoPanel);

import DemoSideMenu from './EgElementsDemo/DemoSideMenu'
Vue.component('demo-side-menu', DemoSideMenu);

import DemoTabNav from './EgElementsDemo/DemoTabNav'
Vue.component('demo-tab-nav', DemoTabNav);

import DemoFooter from './EgElementsDemo/DemoFooter'
Vue.component('demo-footer', DemoFooter);

import DemoScrollMenu from './EgElementsDemo/DemoScrollMenu'
Vue.component('demo-scroll-menu', DemoScrollMenu);

import DemoPage from './EgElementsDemo/DemoPage'
Vue.component('demo-page', DemoPage);

import App from './EgElementsDemo/EgElementsDemo.vue'
import router from './router'
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
