<template>
    <demo-page name="Text Input" :menu="menu">
        <div class="value-wrapper" slot="sidemenu">
            <div class="value-container">
                <span class="value-label">Simple value:</span>
                <pre>{{ val }}</pre>
            </div>
        </div>
        <div slot="top">
            <p>An input is a form component that allows the user to enter text on a line, more or less freely. Native types can also be used to specify a password field for example.</p>
        </div>
        <template>

            <demo-panel title="Simple text input" :code="demo.a">
                <eg-input v-model="val"></eg-input>
            </demo-panel>

            <div class="show-doc">
                <p>Text inputs can be displayed using by default <code>&lt;eg-input&gt;</code>.</p>
                <p>Text inputs have easy-to-use shortcuts to display text (label, placeholder, message) or icons into the field. You can also quickly set up validation, specific style and state or counter. Helper attributes are available as well to quickly make the component fit its interface.</p>
            </div>

            <div id="attributes">
                <div class="show-doc">
                    <h2><a href="#attributes">Attributes</a></h2>
                </div>

                <div id="main">
                    <h3><a href="#main">Main</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>value</strong>: {default: null}: The value of the field is given and updated using v-model. The initial value can be of any type, but a string will be emitted if the value is edited.</li>
                            <li><strong>type</strong>: {type: String, default: &quot;text&quot;}: The type is defining the input type (mostly the input tag's <em>type</em> native attribute). I can be:
                                <ul>
                                    <li>A native type: 'color', 'date', 'datetime-local', 'file', 'month', 'number', 'password', 'range', 'search', 'tel', 'text', 'time', 'url', 'email', 'week'.</li>
                                    <li>Textarea</li>
                                    <li>Number</li>
                                </ul>
                            </li>
                            <li><strong>id</strong>: {type: String, default: null}: The id can be defined using a string. The component wrapper will have the &quot;#{id}-wrapper&quot; id, and the field will have the &quot;#id&quot; id. The field can be selected or deselected from any component using the native javascript method <code>focus()</code> or <code>blur()</code> on the element (fetched using <code>document.getElementById</code> for example). If no id is provided, a random one will be used.</li>
                            <li><strong>label</strong>: {type: String, default: null}: The given label will be displayed on top of the field between the &lt;<em>label&gt;</em> tags (see structure). Clicking on the label will select the field since the label tags have the <em>for</em> property defined and linked to the input tag.</li>
                            <li><strong>placeholder</strong>: {type: String, default: null}: The placeholder text will be displayed (lightly) inside the field when it's empty. This text will be hidden when a value is typed. It can be used to given information about the field to the users.</li>
                            <li><strong>disabled</strong>: {type: Boolean, default: false}: If true, the field aspect will change (grayed by default) and will be an no longer usable (no interaction will be possible and no events will be emitted).</li>
                        </ul>
                    </div>

                    <demo-panel title="Disabled text input" :code="demo.c">
                        <eg-input v-model="val" disabled label="Text input" placeholder="Text..."></eg-input>
                    </demo-panel>
                </div>

                <div id="text-helpers">
                    <h3><a href="#text-helpers">Text helpers</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>prefix</strong>: {type: String, default: null}: The prefix is a text (generally short) displayed on the left of the value, inside of the field.</li>
                            <li><strong>suffix</strong>: {type: String, default: null}: The prefix is a text (generally short) displayed on the right of the value, inside of the field.</li>
                            <li><strong>message</strong>: {type: String, default: null}: A small message that can be displayed on one or multiple line below the field (outside of the field box).</li>
                            <li><strong>messages</strong>: {type: Array, default() {return []}}. Multiple messages can be given to be displayed below the field.</li>
                            <li><strong>helpText</strong>: {type: String, default: null}: If a value is provided, a HelpIcon will be visible on the right of the field label. The provided text will be displayed when the icon is hovered.</li>
                        </ul>
                    </div>

                    <demo-panel title="Input with prefix and message" :code="demo.d">
                        <eg-input v-model="val"
                                  label="Text input"
                                  prefix="Dr."
                                  message="You can type here anything you want"
                                  placeholder="Type some text...">
                        </eg-input>
                    </demo-panel>
                    <demo-panel title="Input with suffix and message" :code="demo.e">
                        <eg-input v-model="val"
                                  label="Value (cash)"
                                  suffix="$"
                                  :cross="false"
                                  placeholder="Type some text...">
                        </eg-input>
                    </demo-panel>
                    <demo-panel title="Input with messages" :code="demo.f">
                        <eg-input v-model="val"
                                  label="The field"
                                  :messages="['First line', 'Second line']">
                        </eg-input>
                    </demo-panel>
                    <demo-panel title="Input with help message" :code="demo.g">
                        <eg-input v-model="val"
                                  label="The field"
                                  helpText="Get some textual help!">
                        </eg-input>
                    </demo-panel>

                    <div id="state-related-text-helpers">
                        <h4><a href="#state-related-text-helpers">State related text helpers:</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>errorMsg</strong>: {type: String, default: null}: An error message to display below the field (in the messages area). If an error message is displayed, the field will automatically have the error state as well.</li>
                                <li><strong>errors</strong>: {type: Array, default() {return []}}: If multiple errors should be displayed, &quot;errors&quot; can be used to provide an array of error messages. This attribute is particularly useful with APIs returned messages.</li>
                            </ul>
                        </div>

                        <demo-panel title="Error message" :code="demo.h">
                            <eg-input v-model="val"
                                      label="The field"
                                      errorMsg="Something went wrong">
                            </eg-input>
                        </demo-panel>
                        <demo-panel title="Error messages" :code="demo.i">
                            <eg-input v-model="val"
                                      label="The field"
                                      :errors="['Multiple things went wrong!', 'Try again']">
                            </eg-input>
                        </demo-panel>
                    </div>

                    <div id="counter-related-text-helpers">
                        <h4><a href="#counter-related-text-helpers">Counter related text helpers:</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>counter</strong>: {type: Boolean, default: false}: If true, a counter (current count and maximum) will be displayed on the right of the message zone, below the field. By default, The counter displays the number of characters of the value.</li>
                                <li><strong>counterMax</strong>: {type: Number, default: 0}: A maximum can be specified for the counter. By default, The count can be greater than the maximum.</li>
                                <li><strong>counterLock</strong>: {type: Boolean, default: false}: If true, the field will not be usable if the count is greater than the maximum.</li>
                                <li><strong>counterWarn</strong>: {type: Boolean, default: true}: If true, the field will have the warning state if the count is greater than the maximum.</li>
                                <li><strong>counterHide</strong>: {type: Boolean, default: false}: If true, the counter will not be displayed (but &quot;counterLock&quot; will still be active).</li>
                                <li><strong>countWords</strong>: {type: Boolean, default: false}: If true, the word (separated by the '-' character) will be counted instead of the number of characters.</li>
                                <li><strong>countWordsSeparator</strong>: {type: String, default: &quot; &quot;}: Separator used for <em>counterWords</em> to replace the space character.</li>
                            </ul>
                        </div>

                        <demo-panel title="Simple counter" :code="demo.j">
                            <eg-input v-model="val" counter></eg-input>
                        </demo-panel>
                        <demo-panel title="Counter max" :code="demo.k">
                            <eg-input v-model="val" counter :counterMax="255"></eg-input>
                        </demo-panel>
                        <demo-panel title="Counter lock" :code="demo.l">
                            <eg-input v-model="val" counter :counterMax="10" counter-lock></eg-input>
                        </demo-panel>
                        <demo-panel title="Counter warn" :code="demo.m">
                            <eg-input v-model="val" counter :counterMax="10" counter-lock :counterWarn="false"></eg-input>
                        </demo-panel>
                        <demo-panel title="Counter hide" :code="demo.n">
                            <eg-input v-model="val" counter :counterMax="10" counter-lock :counterWarn="false" counter-hide></eg-input>
                        </demo-panel>
                        <demo-panel title="Counter words" :code="demo.o">
                            <eg-input v-model="val" counter count-words></eg-input>
                        </demo-panel>
                    </div>
                </div>

                <div id="style">
                    <h3><a href="#style">Style</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>buttonStyle</strong>: {type: Boolean, default: false}: If true, the input will have the button style (default or primary). The &quot;.button-shell&quot; class will be applied to the input box.</li>
                            <li><strong>primary</strong>: {type: Boolean, default: false}: If true, the field will have the primary style and color.</li>
                            <li><strong>slim</strong>: {type: Boolean, default: false}: If true, the field will have no padding and boxing. It will be displayed in a compact form.</li>
                        </ul><ul>
                            <li><strong>buttonStyle</strong>: {type: Boolean, default: false}: If true, the input will have the button style (default or primary). The <em>.button-shell</em> class will be applied to the input box.</li>
                            <li><strong>primary</strong>: {type: Boolean, default: false}: If true, the field will have the primary style and color.</li>
                            <li><strong>slim</strong>: {type: Boolean, default: false}: If true, the field will have no padding and boxing. It will be displayed in a compact form.</li>
                        </ul>
                    </div>

                    <demo-panel title="Input primary" :code="demo.p">
                        <eg-input v-model="val" primary></eg-input>
                    </demo-panel>
                    <demo-panel title="Input slim" :code="demo.q">
                        <eg-input v-model="val" slim placeholder="Enter text here..."></eg-input>
                    </demo-panel>
                </div>

                <div id="state">
                    <h3><a href="#state">State</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>loading</strong>: {type: Boolean, default: false}: If true, an animated loader will be displayed (as main icon) to indicate a loading state.</li>
                            <li><strong>error</strong>: {type: Boolean, default: false}: If true, the field will have the error state (error color).</li>
                            <li><strong>warning</strong>: {type: Boolean, default: false}: If true, the field will have the warning state (warning color).</li>
                            <li><strong>success</strong>: {type: Boolean, default: false}: If true, the field will have the success state (success color).</li>
                        </ul>
                    </div>

                    <demo-panel v-if="false" title="Input loading" :code="demo.r">
                        <eg-input v-model="val" label="Is loading..." :loading="true"></eg-input>
                    </demo-panel>
                    <demo-panel title="Input error" :code="demo.s">
                        <eg-input v-model="val" error placeholder="Error!" label="Error" errorMsg="Error!" icon="svg:error"></eg-input>
                    </demo-panel>
                    <demo-panel title="Input warning" :code="demo.t">
                        <eg-input v-model="val" warning placeholder="Warning!" label="Warning" message="Warning!" icon="svg:warning"></eg-input>
                    </demo-panel>
                    <demo-panel title="Input success" :code="demo.u">
                        <eg-input v-model="val" success placeholder="Success!" label="Success!" message="Success!" icon="svg:success"></eg-input>
                    </demo-panel>
                </div>

                <div id="icons">
                    <h3><a href="#icons">Icons</a></h3>
                    <div class="show-doc">
                        <p><router-link :to="{name: 'icons'}">More information on how to use the icons.</router-link></p>
                        <ul>
                            <li><strong>icon</strong>: {type: String, default: null}: Main icon to display the icon name can be prefixed to use a specific library.</li>
                            <li><strong>lIcon</strong>: {type: String, default: null}: Main icon to display but on the left of the field (similar to &quot;icon&quot;).</li>
                            <li><strong>icons</strong>: {type: Array, default() {return []}}: Display multiple icons providing an array of strings containing the icon names (similar to the attribute &quot;icon&quot;).</li>
                            <li><strong>lIcons</strong>: {type: Array, default() {return []}}: Display multiple icons but on the left instead of the right (similar to &quot;icons&quot;).</li>
                            <li><strong>clickableIcon</strong>: {type: Boolean, default: false}: If true, the icons can be clicked. When clicked, the event @iconClick will be emitted (with the icon name as payload).</li>
                        </ul>
                    </div>

                    <demo-panel title="Input icon" :code="demo.v">
                        <eg-input v-model="val" placeholder="Text..." :cross="false" icon="svg:warning"></eg-input>
                    </demo-panel>
                    <demo-panel title="Input left icon" :code="demo.v2">
                        <eg-input v-model="val" placeholder="Text..." lIcon="svg:warning"></eg-input>
                    </demo-panel>
                    <demo-panel title="Input icon (fontawesome)" :code="demo.w">
                        <eg-input v-model="val" placeholder="Text..." icon="s/cart-plus"></eg-input>
                    </demo-panel>
                    <demo-panel title="Input icon (google material)" :code="demo.x">
                        <eg-input v-model="val" placeholder="Text..." icon="+assignment_turned_in"></eg-input>
                    </demo-panel>
                    <demo-panel title="Input icons" :code="demo.y">
                        <eg-input v-model="val"
                                  placeholder="Text..."
                                  :icons="['svg:success', 's/cat', '+lock']">
                        </eg-input>
                    </demo-panel>
                    <demo-panel title="Input left icons and clickable" :code="demo.z">
                        <eg-input v-model="val"
                                  placeholder="Text..."
                                  clickableIcon
                                  :lIcons="['svg:error', 'fa:fas fa-dragon', 'ma:gavel']">
                        </eg-input>
                    </demo-panel>
                    <demo-panel title="Input right icon + icons" :code="demo.z2">
                        <eg-input v-model="val"
                                  placeholder="Text..."
                                  icon="+assignment_turned_in"
                                  :icons="['svg:error', 'fa:fas fa-dragon']">
                        </eg-input>
                    </demo-panel>
                    <demo-panel title="Input left icon + icons" :code="demo.z3">
                        <eg-input v-model="val"
                                  placeholder="Text..."
                                  lIcon="+assignment_turned_in"
                                  :lIcons="['svg:error', 'fa:fas fa-dragon']">
                        </eg-input>
                    </demo-panel>

                    <div id="special-icons-attributes">
                        <h4><a href="#special-icons-attributes">Special icons attributes</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>flipIcon</strong>: {type: Boolean, default: false}: If true, the icon is flipped horizontally (downward instead of upwards).</li>
                                <li><strong>plusMinus</strong>: {type: Boolean, default: false}: If true, a number-style plus (+) and minus (-) buttons stack will be displayed next to the main icon (generally instead of). Clicking on the icons will trigger the event @plus and @minus (containing the value as payload).</li>
                            </ul>
                        </div>

                        <demo-panel title="Input icon flip" :code="demo.aa">
                            <eg-input v-model="val" placeholder="Text..." icon="s/crow" flipIcon></eg-input>
                        </demo-panel>
                    </div>
                </div>

                <div id="validation">
                    <h3><a href="#validation">Validation</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>regexList</strong>: {type: Array, default() {return []}}: This attribute is used to provide a list of rules to apply to the value. The rules are defined by parameters objects containing the following keys and values:
                                <ul>
                                    <li><strong>regex</strong>: a regular expression defining what the input text must be (for example: <code>/^([\d]*)$/</code>).</li>
                                    <li><strong>message</strong>: a message to briefly display if the value doesn't match the regex.</li>
                                    <li><strong>strong</strong>: (boolean) if true, an invalid value cannot be entered in the field (the field will be locked).</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <demo-panel title="Input validation" :code="demo.ab">
                        <eg-input v-model="val"
                                  :regexList="[{ regex: /^([\d]*)$/, message: 'Only numbers!', strong: true }]"
                                  placeholder="Text...">
                        </eg-input>
                    </demo-panel>
                </div>

                <div id="composed">
                    <h3><a href="#composed">Composed</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>composed</strong>: {default: null}: You can completely format the inside of the input using the composed property. This takes an object as a parameter that contains several attributes and allows you to display several input texts for each of the individual characters, or fixed text before, after or between them. A font with characters of similar size is preferable, but you can use resizeInputs otherwise.
                                <ul>
                                    <li><strong>composition</strong>: a formatting string representing each input or placeholder to display inside the input. By default, any characters in this string will represent a slot for an inside text or input. By default, the characters &quot;_&quot; will represent a text input and &quot;#&quot; a number input, restricting its content to numbers only. Anything else will be displayed as is.</li>
                                    <li><strong>placeholders</strong>: another formatting string to specify the potential individual input placeholder text (displayed when there is no value). The position of the character in the string is targeting the element at the same position in the &quot;composition&quot; string. if the targeted element is an input (text or number) will have the placeholder specified by the character in &quot;placeholders&quot;.</li>
                                    <li><strong>inputChar</strong>: the character to indicate an input in &quot;composition&quot;. &quot;_&quot; by default.</li>
                                    <li><strong>numberChar</strong>: the character to indicate a number input in &quot;composition&quot;. &quot;#&quot; by default.</li>
                                    <li><strong>resizeInputs</strong>: if true, the individual input width will be computed based on its content (doesn't work for the placeholders).</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <demo-panel title="Composed input" :code="demo.ab2">
                        <eg-input class="font-courrier" v-model="val"
                                  :composed="{
                                    composition:'CODE-__-____-####',
                                    placeholders: 'CODE-XX-XXXX-0000',
                                    inputChar: '_',
                                    numberChar: '#',
                                    resizeInputs: false
                                  }">
                        </eg-input>
                    </demo-panel>
                </div>

                <div id="helpers">
                    <h3><a href="#helpers">Helpers</a></h3>

                    <div id="interactivity">
                        <h4><a href="#interactivity">Interactivity:</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>autoFocus</strong>: {type: Boolean, default: false}: If true, the field will have the focus directly after being mounted (when the page loads). If multiple inputs have this attribute defined, the last one in the DOM will have the focus.</li>
                                <li><strong>editable</strong>: {type: Boolean, default: true}: If false, the value is displayed but cannot be modified (instead of <em>disabled</em>, the field will show no difference).</li>
                                <li><strong>cross</strong>: {type: Boolean, default: true}: If false, the erasing cross will not be displayed.</li>
                                <li><strong>emptyCross</strong>: {type: Boolean, default: false}: If false, the cross will only be visible when the value is not empty.</li>
                            </ul>
                        </div>

                        <demo-panel title="Input not editable" :code="demo.ac">
                            <eg-input v-model="val" :editable="false" placeholder="Text..."></eg-input>
                        </demo-panel>
                        <demo-panel title="Input without cross" :code="demo.ad">
                            <eg-input v-model="val" :cross="false" placeholder="Text..."></eg-input>
                        </demo-panel>
                        <demo-panel title="Input empty cross" :code="demo.ae">
                            <eg-input v-model="val" empty-cross placeholder="Text..."></eg-input>
                        </demo-panel>
                    </div>

                    <div id="scaffolding">
                        <h4><a href="#scaffolding">Scaffolding:</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>inputClass</strong>: {type: Boolean, default: true}: If true, the &quot;.eg-input-container&quot; will have the &quot;.eg-input&quot; class (mainly used for components wrapped: only the parent container should have the <em>&quot;</em>.eg-input&quot; class).</li>
                                <li><strong>emptyValue</strong>: {default: null}: The default field value. Also set when the value is erased.</li>
                                <li><strong>tabindex</strong>: {default: null}: The custom field tabindex value.</li>
                            </ul>
                        </div>

                        <demo-panel title="Input with custom empty value" :code="demo.af">
                            <eg-input v-model="val" emptyValue="ok" message="Click on the cross" emptyCross></eg-input>
                        </demo-panel>
                    </div>

                    <div id="sizing">
                        <h4><a href="#sizing">Sizing</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>wide</strong>: {type: Boolean, default: false}: If true, the field will take all the horizontal available space.</li>
                                <li><strong>flex</strong>: {type: Boolean, default: false}: If true, the field width will be defined by the value width.</li>
                                <li><strong>baseMin</strong>: {type: Number, default: 50}: Minimum witdh (in px) allowed for the &quot;flex&quot; field to expand.</li>
                                <li><strong>baseMax</strong>: {type: Number, default: 500}: Maximum witdh (in px) allowed for the &quot;flex&quot; field to expand.</li>
                            </ul>
                        </div>

                        <demo-panel title="Input wide" :code="demo.ag">
                            <eg-input v-model="val" wide message="Full width..."></eg-input>
                        </demo-panel>
                        <demo-panel title="Input flex" :code="demo.ah">
                            <eg-input v-model="val" flex placeholder="Start typing..."></eg-input>
                        </demo-panel>
                    </div>
                </div>
            </div>

            <div id="native-types">
                <h2><a href="#native-types">Native types</a></h2>
                <demo-panel title="Text input native types" :code="demo.ak">
                    <eg-input v-model="val2" placeholder="Type here..." v-for="t in types" :key="t" :type="t"></eg-input>
                </demo-panel>
            </div>

            <div class="show-doc" id="events">
                <h2><a href="#events">Events</a></h2>
                <ul>
                    <li>v-model (<strong>@input</strong>): any value can be passed to the field, but will be converted to a string. The input event is triggered when the keyboard key is released in the field.</li>
                    <li><strong>@click</strong>: triggered when the whole element is clicked (.egi-inner or <em>input</em> tag). Default event payload.</li>
                    <li><strong>@fakeClick</strong>: triggered when the value is clicked. &quot;.fake-input&quot; is the value wrapper class, which is displayed when the field is not &quot;editable&quot;). Default event payload.</li>
                    <li><strong>@iconClick</strong>: triggered when an icon is clicked. Payload: the event with the extra attribute &quot;icon&quot; containing the name of the icon clicked (prefixed name if an icon library is used).</li>
                    <li><strong>@change</strong>: triggered when the value of the field changes (after blur). Default event payload (containing &quot;value&quot; in &quot;target&quot;).</li>
                    <li><strong>@focus</strong>: triggered when the field gets the focus. Default event payload.</li>
                    <li><strong>@blur</strong>: triggered when the field looses the focus. Default event payload.</li>
                    <li><strong>@mousedown</strong>: triggered when the mouse is pressed, before the release (on the box wrapper tag). Default event payload.</li>
                    <li><strong>@cross</strong>: triggered when the cross is clicked. Payload: the last value.</li>
                </ul>
                <div id="keyboard-events">
                    <p>These event are triggered only if the field has focus.</p>
                    <ul>
                        <li><strong>@escape</strong>: triggered when the escape key is pressed (default event payload).</li>
                        <li><strong>@enter</strong>: triggered when the enter key is pressed (default event payload).</li>
                        <li><strong>@down</strong>: triggered when the down arrow key is pressed (default event payload).</li>
                        <li><strong>@up</strong>: triggered when the up arrow key is pressed (default event payload).</li>
                        <li><strong>@keydown</strong>: triggered when a key is pressed, before the release of the key (default event payload).</li>
                        <li><strong>@keyup</strong>: triggered when a key is pressed, after the release of the key (default event payload).</li>
                    </ul>
                </div>
            </div>

            <div class="show-doc" id="slots">
                <h2><a href="#slots">Slots</a></h2>
                <ul>
                    <li><strong>default</strong>: Content to insert as button label (when &quot;buttonStyle&quot; is used).</li>
                    <li><strong>prefix</strong>: Content to insert before the value in the input box.</li>
                    <li><strong>suffix</strong>: Content to insert after the value in the input box.</li>
                    <li><strong>above</strong>: Content to display above the field label.</li>
                    <li><strong>after-label</strong>: Content to display next to the label.</li>
                    <li><strong>taglist</strong>: Content to display inside the input box on the left (after the prefix).</li>
                    <li><strong>input</strong>: Content to display inside of the input box. Will replace the inner input by its content.</li>
                    <li><strong>display</strong>: Content to display as fake value (text placeholder).</li>
                    <li><strong>menu</strong>; Content to display inside the input box, below everything. Used mainly for the Floting menu of the <router-link :to="{name: 'selects'}">select</router-link> component.</li>
                </ul>
            </div>

            <div class="show-doc" id="structure">
                <h2><a href="#structure">Structure</a></h2>
                <p>The Input (here with the id #my-field) has the following structure:</p>
                <pre><code class="jsx">&lt;div id=&quot;my-field-wrapper&quot; class=&quot;eg-input-container eg-input wide&quot;&gt;
	&lt;!-- Label --&gt;
	&lt;div class=&quot;label-container&quot;&gt;
		&lt;div class=&quot;egi-label-wrapper&quot;&gt;
			&lt;label for=&quot;my-field&quot; class=&quot;egi-label&quot;&gt;Label&lt;/label&gt;
		&lt;/div&gt;
	&lt;/div&gt;
	&lt;!-- Input box --&gt;
	&lt;div class=&quot;input-shell&quot;&gt;
		&lt;div class=&quot;egi-inner empty&quot;&gt;
			&lt;!-- Input field --&gt;
			&lt;input id=&quot;my-field&quot; type=&quot;text&quot;&gt;
			&lt;!-- Right icons --&gt;
			&lt;div class=&quot;egi-icons-right-list&quot;&gt;
				&lt;div class=&quot;egi-main-icon-slot&quot;&gt;&lt;/div&gt;
			&lt;/div&gt;
		&lt;/div&gt;
	&lt;/div&gt;
&lt;/div&gt;
</code></pre>
                <p>You can target the &quot;.input-shell&quot; class to change the input box and the &quot;input&quot; tag inside it to modify the text aspect, as well as the &quot;.label-container&quot; or the &quot;.eg-input-container&quot; to change the spacing.</p>

            </div>
        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                menu: [
                    {
                        name: "Attributes",
                        children: [
                            {name: "Main"},
                            {name: "Text helpers"},
                            {name: "Style"},
                            {name: "State"},
                            {name: "Icons"},
                            {name: "Validation"},
                            {name: "Composed"},
                            {
                                name: "Helpers",/*
                                children: [
                                    {name: "Interactivity"},
                                    {name: "Scaffolding"},
                                    {name: "Sizing"},
                                ]*/
                            }
                        ]
                    },
                    {name: "Native types"},
                    {name: "Events"},
                    {name: "Slots"},
                    {name: "Structure"},
                ],
                val: "",
                val2: "",
                tab: "docdemo",
                types: [
                    'color',
                    'date',
                    'datetime-local',
                    'month',
                    'password',
                    'range',
                    'search',
                    'tel',
                    'text',
                    'time',
                    'url',
                    'email',
                    'week',
                ],
                demo: {
                    a:`<eg-input v-model="val"></eg-input>`,
                    b:`<eg-input v-model="val" type="textarea" placeholder="Type some text..." label="Textarea"></eg-input>`,
                    c:`<eg-input v-model="val" disabled label="Text input" placeholder="Text..."></eg-input>`,
                    d:`<eg-input v-model="val"
          label="Text input"
          prefix="Dr."
          suffix="(approx.)"
          message="You can type here anything you want"
          placeholder="Type some text...">
</eg-input>`,
                    e:`<eg-input v-model="val"
          label="Value (cash)"
          suffix="$"
          :cross="false"
          placeholder="Type some text...">
</eg-input>`,
                    f:`<eg-input v-model="val"
          label="The field"
          :messages="['First line', 'Second line']">
</eg-input>`,
                    g:`<eg-input v-model="val"
          label="The field"
          help="Get some textual help!">
</eg-input>`,
                    h:`<eg-input v-model="val"
          label="The field"
          errorMsg="Something went wrong">
</eg-input>`,
                    i:`<eg-input v-model="val"
          label="The field"
          :errors="['Multiple things went wrong!', 'Try again']">
</eg-input>`,
                    j:`<eg-input v-model="val" counter></eg-input>`,
                    k:`<eg-input v-model="val" counter :counterMax="255"></eg-input>`,
                    l:`<eg-input v-model="val" counter :counterMax="10" counter-lock></eg-input>`,
                    m:`<eg-input v-model="val" counter :counterMax="10" counter-lock :counterWarn="false"></eg-input>`,
                    n:`<eg-input v-model="val" counter :counterMax="10" counter-lock :counterWarn="false" counter-hide></eg-input>`,
                    o:`<eg-input v-model="val" counter count-words></eg-input>`,
                    p:`<eg-input v-model="val" primary></eg-input>`,
                    q:`<eg-input v-model="val" slim placeholder="Enter text here..."></eg-input>`,
                    r:`<eg-input v-model="val" label="Is loading..." :loading="true"></eg-input>`,
                    s:`<eg-input v-model="val" error placeholder="Error!" label="Error" errorMsg="Error!" icon="svg:error"></eg-input>`,
                    t:`<eg-input v-model="val" warning placeholder="Warning!" label="Warning" message="Warning!" icon="svg:warning"></eg-input>`,
                    u:`<eg-input v-model="val" success placeholder="Success!" label="Success!" message="Success!" icon="svg:success"></eg-input>`,
                    v:`<eg-input v-model="val" placeholder="Text..." :cross="false" icon="svg:warning"></eg-input>`,
                    v2:`<eg-input v-model="val" placeholder="Text..." lIcon="svg:warning"></eg-input>`,
                    w:`<eg-input v-model="val" placeholder="Text..." icon="s/cart-plus"></eg-input>`,
                    x:`<eg-input v-model="val" placeholder="Text..." icon="+assignment_turned_in"></eg-input>`,
                    y:`<eg-input v-model="val"
          placeholder="Text..."
          :icons="['svg:success', 's/cat', '+lock']">
</eg-input>`,
                    z:`<eg-input v-model="val"
          placeholder="Text..."
          clickableIcon
          :lIcons="['svg:error', 'fa:fas fa-dragon', 'ma:gavel']">
</eg-input>`,
                    z2:`<eg-input v-model="val"
          placeholder="Text..."
          icon="+assignment_turned_in"
          :icons="['svg:error', 'fa:fas fa-dragon']">
</eg-input>`,
                    z3:`<eg-input v-model="val"
          placeholder="Text..."
          lIcon="+assignment_turned_in"
          :lIcons="['svg:error', 'fa:fas fa-dragon']">
</eg-input>`,
                    aa:`<eg-input v-model="val" placeholder="Text..." icon="s/crow" flipIcon></eg-input>`,
                    ab:`<eg-input v-model="val"
          :regexList="[{ regex: /^([\\d]*)$/, message: 'Only numbers!', strong: true }]"
          placeholder="Text...">
</eg-input>`,
                    ab2:`<eg-input class="font-courrier" v-model="val"
          :composed="{
            composition:'CODE-__-____-####',
            placeholders: 'CODE-XX-XXXX-0000',
            inputChar: '_',
            numberChar: '#',
            resizeInputs: false
          }">
</eg-input>`,
                    ac:`<eg-input v-model="val" :editable="false" placeholder="Text..."></eg-input>`,
                    ad:`<eg-input v-model="val" :cross="false" placeholder="Text..."></eg-input>`,
                    ae:`<eg-input v-model="val" empty-cross placeholder="Text..."></eg-input>`,
                    af:`<eg-input v-model="val" emptyValue="ok" message="Click on the cross"></eg-input>`,
                    ag:`<eg-input v-model="val" wide message="Full width..."></eg-input>`,
                    ah:`<eg-input v-model="val" flex placeholder="Start typing..."></eg-input>`,
                    ai:`<eg-input v-model="val" type="textarea" cols="35" rows="4" placeholder="Paragraph..."></eg-input>`,
                    aj:`<eg-input v-model="val" type="textarea" newLineShiftOnly placeholder="Paragraph..."></eg-input>`,
                    ak:`<eg-input ... type="color"></eg-input>
<eg-input ... type="date"></eg-input>
<eg-input ... type="datetime-locale"></eg-input>
<eg-input ... type="month"></eg-input>
<eg-input ... type="password"></eg-input>
<eg-input ... type="range"></eg-input>
<eg-input ... type="search"></eg-input>
<eg-input ... type="tel"></eg-input>
<eg-input ... type="text"></eg-input>
<eg-input ... type="time"></eg-input>
<eg-input ... type="url"></eg-input>
<eg-input ... type="email"></eg-input>
<eg-input ... type="week"></eg-input>`
                }
            }
        }
    }
</script>