var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.simpleButton)?_c('button',{staticClass:"eg-button button-shell",class:{
            hidden: _vm.loading,
            big: _vm.big, small: _vm.small,
            primary:_vm._primary, secondary:_vm._secondary, tertiary:_vm._tertiary,
            transparent:_vm._transparent, dark:_vm._dark, light:_vm._light, link:_vm._link,
            error:_vm._error, warning:_vm._warning, success:_vm._success,
            disabled: _vm.disabled,fl: _vm.fl,fr: _vm.fr,wide: _vm.wide,icon:_vm.noText},attrs:{"disabled":_vm.disabled},on:{"click":_vm.hClick,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.hClick($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.$emit('escape', $event)}],"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"mousedown":function($event){return _vm.$emit('mousedown', $event)},"mouseup":function($event){return _vm.$emit('mouseup', $event)}}},[_vm._t("default")],2):_c('div',{staticClass:"eg-button button-shell",class:{
            hidden: _vm.loading,
            big: _vm.big, small: _vm.small,
            loadable: _vm.loadable,
            slideable: _vm.slideIcon || _vm.lSlideIcon,
            primary:_vm._primary, secondary:_vm._secondary, tertiary:_vm._tertiary,
            transparent:_vm._transparent, dark:_vm._dark, light:_vm._light, link:_vm._link,
            error:_vm._error, warning:_vm._warning, success:_vm._success,
            disabled: _vm.disabled,fl: _vm.fl,fr: _vm.fr,wide: _vm.wide,icon:_vm.noText},attrs:{"disabled":_vm.disabled,"tabindex":_vm.disabled?-1:0},on:{"click":_vm.hClick,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.hClick($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.$emit('escape', $event)}],"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"mousedown":function($event){return _vm.$emit('mousedown', $event)},"mouseup":function($event){return _vm.$emit('mouseup', $event)}}},[_c('div',{staticClass:"button-inner",class:{rshift:_vm.loading}},[(_vm.hasLeftIcon || _vm.lIcons.length > 0)?_c('div',{staticClass:"btn-left-icons"},[(_vm.lIcons.length > 0)?_vm._l((_vm.lIcons),function(i){return _c('eg-icon',{key:i,attrs:{"icon":i}})}):_vm._e(),(_vm.hasLeftIcon)?[_c('eg-icon',{attrs:{"icon":_vm.lIcon}})]:_vm._e()],2):_vm._e(),(_vm.lSlideIcon)?_c('div',{staticClass:"btn-l-slide-icons"},[_c('eg-icon',{staticClass:"l-slide-icon",attrs:{"icon":_vm.lSlideIcon}})],1):_vm._e(),_vm._t("default"),(_vm.hasRightIcon || _vm.icons.length > 0 || _vm.help !== null)?_c('div',{staticClass:"btn-right-icons"},[(_vm.icons.length > 0)?_vm._l((_vm.icons),function(i){return _c('eg-icon',{key:i,attrs:{"icon":i}})}):_vm._e(),(_vm.hasRightIcon || _vm.help)?[(_vm.hasRightIcon)?_c('eg-icon',{attrs:{"icon":_vm.icon},on:{"click":function($event){return _vm.$emit('iconClick', _vm.icon)}}}):_c('help-icon',{attrs:{"help":_vm.help}})]:_vm._e()],2):_vm._e(),(_vm.slideIcon)?_c('div',{staticClass:"btn-slide-icons"},[_c('eg-icon',{staticClass:"slide-icon",attrs:{"icon":_vm.slideIcon}})],1):_vm._e(),_c('div',{staticClass:"eg-hidden-icon-wrapper"},[(_vm.loading)?_c('div',{staticClass:"eg-loader-wrapper"},[_vm._m(0)]):_vm._e()])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eg-loader"},[_c('div',{staticClass:"eg-loader-inside"})])}]

export { render, staticRenderFns }