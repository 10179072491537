<style lang="scss">
    // Media queries thresholds
    @import "../../EgElementsProject/src/scss/thresholds";

    .eg-elements-demo-page {
        display: grid;
        grid-template-columns: 200px 1fr;
        min-height: 100vh;
        &.hide-doc {
            .show-doc {
                display: none;
            }
        }
        &.hide-demo {
            .demo-panel {
                display: none;
            }
            .show-code {
                display: none;
            }
        }
        &.use-fluid {
            .code-zone {
                display: none;
            }
            .hide-fluid {
                display: none;
            }
        }
        @media all and (max-width: $Classic) {
            grid-template-columns: 1fr;
            grid-template-rows: 50px 1fr;

        }
    }

    .eg-elements-page-inner {
        padding: 2em 4em;
        @media all and (max-width: $Classic) {
            padding: 2em;
        }
    }

    .eg-elements-page-content {
        width: 100%;
        display: flex;
        .demo-scroll-menu {
            @media all and (max-width: $Classic) {
                display: none;
            }
        }
    }

    .eg-elements-page-title {
        .top-slot-content {
            margin-bottom: 2em;
            p {
                color: #999;
            }
        }
    }

    main {
        width: 100%;
        margin-top: 1em;
        h2:first-child {
            margin-top: 0;
        }
    }
</style>

<template>
    <div class="eg-elements-demo-page" :class="{'hide-doc':hideDoc, 'hide-demo':hideDemo, 'use-fluid':fluid}">

        <demo-side-menu>
            <template slot="navigation">
                <demo-scroll-menu v-if="menu && menu.length > 0" :items="menu" v-model="showNav" :name="name"></demo-scroll-menu>
            </template>
            <div class="top-slot" :class="{hidden:showNav}" slot="top">
                <eg-button wide slot="top" @click="showNav=true">Show navigation</eg-button>
            </div>
            <slot name="sidemenu"></slot>
        </demo-side-menu>

        <div class="eg-elements-page-wrapper">
            <div class="eg-elements-page-inner">
                <div class="eg-elements-page-title" :id="_id">
                    <h1><a :href="`#${_id}`">{{ name }}</a></h1>
                    <div class="top-slot-content">
                        <slot name="top"></slot>
                    </div>
                    <demo-tab-nav v-if="!noTab" v-model="tab" :fluid="fluid" @fluid="fluid=$event"></demo-tab-nav>
                </div>
                <div class="eg-elements-page-content">
                    <demo-scroll-menu v-if="menu && menu.length > 0" :items="menu" v-model="showNav" :name="name"></demo-scroll-menu>
                    <main>
                        <slot></slot>
                    </main>
                </div>
            </div>

            <demo-footer></demo-footer>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            name: {type: String, default: "Title test"},
            menu: {type: Array, default(){ return []}},
            noTab: {type: Boolean, default: false}
        },
        data() {
            return {
                fluid: false,
                hidden: false,
                showNav: true,
                tab: "docdemo"
            }
        },
        methods: {
            hDemo() { this.fluid = !this.fluid },
            bv(v) {
                let t = v.split(', ');
                return this.rgba2hex(t[0],t[1],t[2]);
            },
            hCC(e, name) {
                let v = this.hex2rgb(e.target.value);
                this[name] = v;
                document.documentElement.style.setProperty(`--${this.camelToKebab(name)}`, v);
            },
            rgba2hex(r, g, b, alpha) {
                let hex = (r | 1 << 8).toString(16).slice(1) +
                    (g | 1 << 8).toString(16).slice(1) +
                    (b | 1 << 8).toString(16).slice(1);

                let a = alpha ? alpha : 0o01;
                // multiply before convert to HEX
                a = ((a * 255) | 1 << 8).toString(16).slice(1);
                if(alpha) hex = hex + a;

                return '#' + hex;
            },
            hex2rgb(hex) {
                let rgba = [];
                rgba[0] = parseInt(hex.slice(1,3), 16);
                rgba[1] = parseInt(hex.slice(3,5), 16);
                rgba[2] = parseInt(hex.slice(5,7), 16);
                return rgba.join(', ');
            },
            camelToKebab(s) { return s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase(); }
        },
        watch: {
            tab(v) {
                this.hidden = v === "doc";
                let tab =  v === "docdemo" ? null : v;
                if(this.$route.params.tab !== tab) {
                    this.$router.push({params: {tab}});
                }
            }
        },
        computed: {
            hideDoc() { return this.tab === 'demo' },
            hideDemo() { return this.tab === 'doc' },
            _id() { return this.name.toKebabCase() }
        },
        mounted() {
            let r = this.$route;
            if(r.hash) {
                location.hash = "";
                location.hash = this.$route.hash;
            }
            if(r.params) {
                let p = r.params;
                let tab = p && p.tab ? p.tab : null;
                let test = ["demo", "doc"];
                if(test.indexOf(tab) >= 0) this.tab = tab;
                else if(tab === null) this.tab = "docdemo";
            }
        }
    }
</script>