<template>
    <demo-page name="Tags" :menu="menu">
        <template slot="top">
            <p>Tags are elements that can be used directly to display important information in a more visible way. This element is also used in another element: Select (with the multiple attribute).</p>
        </template>
        <template>
            <demo-panel title="Basic tag" :code="demo.a"><eg-tag>Tag</eg-tag></demo-panel>

            <div id="attributes">
                <div class="show-doc">
                    <h2><a href="#attributes">Attributes</a></h2>
                    <ul>
                        <li><strong>cross</strong>: {type: Boolean, default: false}: If true, a cross is visible and can be used to emit the 'cross' event when clicked.</li>
                        <li><strong>clickable</strong>: {type: Boolean, default: false}: If true, is visibly clickable (css cursor and hover effects). Emits the event 'click' when clicked.</li>
                    </ul>
                </div>

                <demo-panel title="Cross tag" :code="demo.b"><eg-tag cross>Tag</eg-tag></demo-panel>
                <demo-panel title="Clickable tag" :code="demo.c"><eg-tag clickable>Tag</eg-tag></demo-panel>
            </div>

            <div class="show-doc" id="events">
                <h2><a href="#events">Events</a></h2>
                <ul>
                    <li><strong>@click</strong>: triggered when the whole element is clicked. Default event payload.</li>
                    <li><strong>@escape</strong>: triggered when the escape key is pressed (default event payload).</li>
                </ul>
            </div>
        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                menu: [
                    {name: "Attributes"},
                    {name: "Events"}
                ],
                demo: {
                    a:`<eg-tag>Tag</eg-tag>`,
                    b:`<eg-tag cross>Tag</eg-tag>`,
                    c:`<eg-tag clickable>Tag</eg-tag>`,
                }
            }
        }
    }
</script>