<template>
    <demo-page name="Checkbox" :menu="menu">
        <template slot="top">
            <p>A checkbox is a component that usually displays text as a label and input as a box with a check mark inside. The user can check or uncheck the option to select it by clicking on it.</p>
        </template>
        <div class="value-wrapper" slot="sidemenu">
            <div class="value-container">
                <span class="value-label">Simple value:</span>
                <pre>{{ val }}</pre>
                <span class="value-label">Multiple value:</span>
                <pre>{{ vals }}</pre>
            </div>
        </div>
        <template>

            <demo-panel title="Basic checkbox" :code="demo.a">
                <eg-checkbox v-model="val" label="Check me"></eg-checkbox>
            </demo-panel>

            <div class="show-doc">
                <p>Checkbox(es) can be displayed using by default <code>&lt;eg-checkbox&gt;</code>.</p>
                <p>A simple checkbox allows to link a value (usually a boolean). It can also be a string of characters if the user has to choose between several checkboxes or also an array if several options are selectable simultaneously.</p>
            </div>

            <div id="attributes">
                <div class="show-doc">
                    <h2><a href="#attributes">Attributes</a></h2>
                </div>

                <div class="show-code hide-fluid">
                    <h3><a href="#demo-args" id="demo-args">Demo arguments</a></h3>
                    <div class="args-panels">
                        <div class="args-panel">
                        <pre class="args-value"><strong>items</strong>: [
    'Item 1',
    'Item 2',
    'Item 3'
]</pre>
                        </div>
                        <div class="args-panel">
                        <pre class="args-value"><strong>itemGroups</strong>: {
    "Fruits": "Yes",
    "Animals": "No"
},</pre>
                        </div>
                        <div class="args-panel">
                        <pre class="args-value"><strong>itemsG</strong>: [{
    name: "John",
    type: "High level"
},{
    name: "Jane",
    type: "Medium level"
},{
    name: "Marie",
    type: "High level"
},{
    name: "Pierre",
    type: "Low level"
}]</pre>
                        </div>
                    </div>
                </div>

                <div id="main">
                    <h3><a href="#main">Main</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>value</strong>: {default: null}: The value (generally a boolean value).</li>
                            <li><strong>id:</strong> {type: String, default: null}: An id string can be given to the field. The component input tag will inherit the id.</li>
                            <li><strong>label</strong>: {type: String, default: null}: The given label will be displayed on the right of the field. Clicking on the label will select it (like clicking on the box).</li>
                            <li><strong>disabled</strong>: {type: Boolean, default: false}: If true, the field aspect will change (grayed by default) and will be an no longer usable (no interaction will be possible and no events will be emitted).</li>
                            <li><strong>native</strong>: {type: Boolean, default: false}: If true, the checkbox field will have the browser native style and behavior.</li>
                            <li><strong>type</strong>: {type: String, default: null}: The default type is a square box, but other styles can be applied:
                                <ul>
                                    <li><strong>radio</strong>: can be used to display radio buttons: round buttons with a dot inside indicating that the option is selected. A radio input cannot be deselected.</li>
                                    <li><strong>switch</strong>: display a switch button, generally used to select an option or another. Represented by a small slider with a dot inside. The dot's position is indicating which option selected. The switch field have custom attributes.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <demo-panel title="Checkbox disabled" :code="demo.b">
                        <eg-checkbox v-model="val" label="Check me" disabled></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Checkbox native" :code="demo.c">
                        <eg-checkbox v-model="val" label="Check me" native></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Radio checkbox" :code="demo.d">
                        <eg-checkbox v-model="val" label="Check me" type="radio"></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Radio native checkbox" :code="demo.e">
                        <eg-checkbox v-model="val" label="Check me" type="radio" native></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Switch checkbox" :code="demo.f">
                        <eg-checkbox v-model="val" type="switch"></eg-checkbox>
                    </demo-panel>
                </div>

                <div id="options">
                    <h3><a href="#options">Options</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>options</strong>: {type: Array, default() {return []}}: Array containing the options. If the array contain a string or number, it will be displayed as label. If it contains object, an attribute can be defined as label using &quot;oLabel&quot;.</li>
                            <li><strong>elements</strong>: {type: Object, default(){ return {} }}: Object containing the value of the option as value and the label as key.</li>
                            <li><strong>multiple</strong>: {type: Boolean, default: false}: If true, the value is expected to an array. A list of options can be provided with the attributes &quot;options&quot; or &quot;elements&quot;. The value will containing the options selected.</li>
                            <li><strong>oLabel</strong>: {type: String, default: &quot;name&quot;}: If &quot;options&quot; are provided and contain objects, name of the attribute (of the array's objects) to use as option label.</li>
                        </ul>
                    </div>

                    <demo-panel title="Checkbox options" :code="demo.g">
                        <eg-checkbox label="Checkboxes group" v-model="val" :options="items"></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Checkbox object options" :code="demo.h">
                        <eg-checkbox v-model="val" :elements="itemGroups"></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Checkbox multiple options" :code="demo.i">
                        <eg-checkbox v-model="vals" :options="items" multiple></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Checkbox multiple options with oLabel" :code="demo.j">
                        <eg-checkbox v-model="val" :options="itemsG" oLabel="name"></eg-checkbox>
                    </demo-panel>
                </div>

                <div id="state">
                    <h3><a href="#state">State</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>error</strong>: {type: Boolean, default: false}: If true, the checkbox will have the error state (error color).</li>
                            <li><strong>warning</strong>: {type: Boolean, default: false}: If true, the checkbox will have the warning state (warning color).</li>
                            <li><strong>success</strong>: {type: Boolean, default: false}: If true, the checkbox will have the success state (success color).</li>
                        </ul>
                    </div>

                    <demo-panel title="Checkbox error" :code="demo.k">
                        <eg-checkbox v-model="val" label="Error" error></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Checkbox warning" :code="demo.l">
                        <eg-checkbox v-model="val" label="Warning" warning></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Checkbox success" :code="demo.m">
                        <eg-checkbox v-model="val" label="Success" success></eg-checkbox>
                    </demo-panel>
                </div>

                <div id="helpers">
                    <h3><a href="#helpers">Helpers</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>inputClass</strong>: {type: Boolean, default: true}: If true, the &quot;.eg-input-container&quot; will have the &quot;.eg-input&quot; class (mainly used for components wrapped since only the parent container should have the &quot;.eg-input&quot; class).</li>
                            <li><strong>nullValue</strong>: {default: null}: Value returned when the checkbox is unchecked.</li>
                            <li><strong>useUndefined</strong>: {type: Boolean, default: false}: If true, the checkbox can be in an undefined state (represented by an horizontal stroke by default).</li>
                        </ul>
                    </div>

                    <demo-panel title="Checkbox with null value" :code="demo.n">
                        <eg-checkbox v-model="val" label="Check me" :nullValue="null"></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Checkbox with use undefined" :code="demo.o">
                        <eg-checkbox v-model="val2" label="Regular checkbox with undefined value"></eg-checkbox>
                        <eg-checkbox v-model="val2" label="Use undefined checkbox" use-undefined></eg-checkbox>
                    </demo-panel>
                </div>

                <div id="switch-specific-options">
                    <h3><a href="#switch-specific-options">Switch specific options</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>onLabel</strong>: {type: String, default: null}: The label if the value is positive (the option is checked).</li>
                            <li><strong>offLabel</strong>: {type: String, default: null}: The label if the value is negative (the option is not checked).</li>
                            <li><strong>innerLabels</strong>: {type: Boolean, default: false}: If true, the visual aspect changes. The label are inside the slider box and the dot is hiding the option which is not selected.</li>
                        </ul>
                    </div>

                    <demo-panel title="Switch checkbox" :code="demo.p">
                        <eg-checkbox v-model="val" type="switch" onLabel="On" offLabel="Off"></eg-checkbox>
                    </demo-panel>
                    <demo-panel title="Switch checkbox inner labels" :code="demo.q">
                        <eg-checkbox v-model="val" type="switch" onLabel="On" offLabel="Off" inner-labels></eg-checkbox>
                    </demo-panel>
                </div>
            </div>

            <div class="show-doc" id="events">
                <h2><a href="#events">Events</a></h2>
                <ul>
                    <li>v-model (<strong>@input</strong>): can return a boolean value or a defined value.</li>
                    <li><strong>@click</strong>: when the whole element is clicked. No payload.</li>
                </ul>
            </div>

            <div class="show-doc" id="structure">
                <h2><a href="#structure">Structure</a></h2>
                <p>The Checkbox components has the following structure:</p>
                <pre><code class="html">&lt;div class=&quot;checkbox-input eg-input&quot;&gt;
	&lt;!-- Group label --&gt;
	&lt;div class=&quot;label-container&quot; v-if=&quot;options.length &gt; 0&quot;&gt;
      &lt;span class=&quot;egi-label&quot;&gt;&#123; label &#125;&lt;/span&gt;
  &lt;/div&gt;
	&lt;div class=&quot;checkbox-inner&quot;&gt;
		&lt;div class=&quot;checkbox-group&quot;&gt;
			&lt;!-- Checkbox icon --&gt;
			&lt;div tabindex=&quot;0&quot; id=&quot;check-me&quot; class=&quot;check-box&quot;&gt;
			&lt;!-- Checkbox label --&gt;
			&lt;label for=&quot;check-me&quot; class=&quot;egi-label checkbox-label&quot;&gt;Check me&lt;/label&gt;
		&lt;/div&gt;
	&lt;/div&gt;
&lt;/div&gt;
</code></pre>
            </div>

        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                menu: [
                    {
                        name: "Attributes",
                        children: [
                            {name: "Main"},
                            {name: "Options"},
                            {name: "State"},
                            {name: "Helpers"},
                            {name: "Switch specific options"},
                        ]
                    },
                    {name: "Events"},
                    {name: "Structure"}
                ],
                val: false,
                val2: undefined,
                vals: [],
                items: ['Item 1', 'Item 2', 'Item 3'],
                itemGroups: {
                    "Fruits": "Yes",
                    "Animals": "No"
                },
                itemsG: [{
                    name: "John",
                    type: "High level"
                },{
                    name: "Jane",
                    type: "Medium level"
                },{
                    name: "Marie",
                    type: "High level"
                },{
                    name: "Pierre",
                    type: "Low level"
                }],
                demo: {
                    a:`<eg-checkbox v-model="val" label="Check me"></eg-checkbox>`,
                    b:`<eg-checkbox v-model="val" label="Check me" disabled></eg-checkbox>`,
                    c:`<eg-checkbox v-model="val" label="Check me" native></eg-checkbox>`,
                    d:`<eg-checkbox v-model="val" label="Check me" type="radio"></eg-checkbox>`,
                    e:`<eg-checkbox v-model="val" label="Check me" type="radio" native></eg-checkbox>`,
                    f:`<eg-checkbox v-model="val" type="switch"></eg-checkbox>`,
                    g:`<eg-checkbox v-model="val" label="Checkboxes group" :options="items"></eg-checkbox>`,
                    h:`<eg-checkbox v-model="val" :elements="itemGroups"></eg-checkbox>`,
                    i:`<eg-checkbox v-model="vals" :options="items" multiple></eg-checkbox>`,
                    j:`<eg-checkbox v-model="val" :options="itemsG" oLabel="name"></eg-checkbox>`,
                    k:`<eg-checkbox v-model="val" label="Error" error></eg-checkbox>`,
                    l:`<eg-checkbox v-model="val" label="Warning" warning></eg-checkbox>`,
                    m:`<eg-checkbox v-model="val" label="Success" success></eg-checkbox>`,
                    n:`<eg-checkbox v-model="val" label="Check me" :nullValue="null"></eg-checkbox>`,
                    o:`<eg-checkbox v-model="val2" label="Regular checkbox with undefined value"></eg-checkbox>
<eg-checkbox v-model="val2" label="Use undefined checkbox" use-undefined></eg-checkbox>`,
                    p:`<eg-checkbox v-model="val" type="switch" onLabel="On" offLabel="Off"></eg-checkbox>`,
                    q:`<eg-checkbox v-model="val" type="switch" onLabel="On" offLabel="Off" inner-labels></eg-checkbox>`,
                }
            }
        }
    }
</script>