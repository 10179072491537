<template>
    <demo-page name="Buttons" :menu="scrollMenuItems">
        <template slot="top">
            <p>Buttons are elements that allow the user to trigger an action. Their visual aspect allows to draw attention or not to a possible interaction.</p>
        </template>
        <template>
            <demo-panel title="Buttons" :code="demo.a">
                <eg-button>Back</eg-button>
                <eg-button primary :loading="!l" @click="l=!l">Save</eg-button>
            </demo-panel>

            <div class="show-doc">
                <p>A button can be displayed using by default <code>&lt;eg-button&gt;</code> (with the inner label between the tags)</p>
                <p>Buttons can be disabled, have special states (error, warning, success) or incorporate icons in addition to the styles provided by the theme: &quot;default&quot;, &quot;primary&quot;, &quot;secondary&quot;, &quot;tertiary&quot; and all special aspects such as &quot;link&quot;.</p>
            </div>

            <div id="attributes">
                <div class="show-doc">
                    <h2><a href="#attributes">Attributes</a></h2>
                </div>

                <div id="main">
                    <h3><a href="#main">Main</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>disabled</strong>: {type: Boolean, default: false}: If <em>true</em>, the field aspect will change (grayed by default) and the button will no longer be usable (no interaction will be possible and no events will be emitted).</li>
                        </ul>
                    </div>
                    <demo-panel title="Disabled button" :code="demo.b">
                        <eg-button disabled>Click</eg-button>
                    </demo-panel>
                </div>

                <div id="styles">
                    <h3><a href="#styles">Styles</a></h3>
                    <div class="show-doc">
                        <p>For more information about the colors, you can look at the theme's CSS variables list.</p>
                        <ul>
                            <li><strong>primary</strong>: {type: Boolean, default: false}: The button will be of the primary color.</li>
                            <li><strong>secondary</strong>: {type: Boolean, default: false}: The button will be of the secondary color.</li>
                            <li><strong>tertiary</strong>: {type: Boolean, default: false}: The button will be of the tertiary color.</li>
                            <li><strong>transparent</strong>: {type: Boolean, default: false}: The button will be transparent: no background or borders (but the padding will still be displayed).</li>
                            <li><strong>dark</strong>: {type: Boolean, default: false}: The button will be of a dark color (dark gray by default).</li>
                            <li><strong>light</strong>: {type: Boolean, default: false}: The button will be of a light color (light gray by default).</li>
                            <li><strong>link</strong>: {type: Boolean, default: false}: The button will have the link style: no padding, underlined text (to fit in a paragraph).</li>
                            <li><strong>type</strong>: {type: String, default: null}: Any style attribute can be passed as string with &quot;type&quot; instead of using the matching attribute (<em>primary</em> for example).</li>
                        </ul>
                    </div>
                    <demo-panel title="Primary button" :code="demo.c">
                        <eg-button primary>Primary</eg-button>
                    </demo-panel>
                    <demo-panel title="Secondary button" :code="demo.d">
                        <eg-button secondary>Secondary</eg-button>
                    </demo-panel>
                    <demo-panel title="Tertiary button" :code="demo.e">
                        <eg-button tertiary>Tertiary</eg-button>
                    </demo-panel>
                    <demo-panel title="Transparent button" :code="demo.f">
                        <eg-button transparent>Transparent</eg-button>
                    </demo-panel>
                    <demo-panel title="Dark button" :code="demo.g">
                        <eg-button dark>Dark</eg-button>
                    </demo-panel>
                    <demo-panel title="Light button" :code="demo.h">
                        <eg-button light>Light</eg-button>
                    </demo-panel>
                    <demo-panel title="Link button" :code="demo.i">
                        <eg-button link>Link</eg-button>
                    </demo-panel>
                </div>

                <div id="state">
                    <h3><a href="#state">State</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>loading</strong>: {type: Boolean, default: null}: If true, a loader will be displayed to indicate a loading state.</li>
                            <li><strong>error</strong>: {type: Boolean, default: false}: If true, the button will be of the error color (red by default).</li>
                            <li><strong>warning</strong>: {type: Boolean, default: false}: If true, the button will be of the warning color (orange by default).</li>
                            <li><strong>success</strong>: {type: Boolean, default: false}: If true, the button will be of the success color (green by default).</li>
                        </ul>
                    </div>
                    <demo-panel title="Loading button" :code="demo.j">
                        <eg-button :loading="l" @click="l=!l">Loading</eg-button>
                    </demo-panel>
                    <demo-panel title="Error button" :code="demo.k">
                        <eg-button error>Error</eg-button>
                    </demo-panel>
                    <demo-panel title="Warning button" :code="demo.l">
                        <eg-button warning>Warning</eg-button>
                    </demo-panel>
                    <demo-panel title="Success button" :code="demo.m">
                        <eg-button success>Success</eg-button>
                    </demo-panel>
                </div>

                <div id="icons">
                    <h3><a href="#icons">Icons</a></h3>
                    <div class="show-doc">
                        <p>By default, the icons are displayed on the right side of the buttons, but can be displayed on the left using the left-hand version of the icon attribute. <router-link :to="{name: 'icons'}">More information on how to use the icons.</router-link></p>
                        <ul>
                            <li><strong>help</strong>: {default: null}: Can be a String or an parameters object that will be given to a HelpIcon components used instead of an icon.</li>
                            <li><strong>icon</strong>: {type: String, default: null}: Main icon to display the icon name can be prefixed to use a specific library.</li>
                            <li><strong>lIcon</strong>: {type: String, default: null}: Main icon to display but on the left of the field (similar to <em>icon</em>).</li>
                            <li><strong>icons</strong>: {type: Array, default() {return []}}: Display multiple icons providing an array of strings containing the icon names (similar to the attribute <em>icon</em>).</li>
                            <li><strong>lIcons</strong>: {type: Array, default() {return []}}: Display multiple icons but on the left instead of the right (similar to <em>icons</em>).</li>
                        </ul>
                    </div>
                    <demo-panel title="Button icon" :code="demo.n">
                        <eg-button l-icon="svg:success">Success</eg-button>
                        <eg-button icon="svg:warning">Warning</eg-button>
                    </demo-panel>
                    <demo-panel title="Button font awesome icon" :code="demo.o">
                        <eg-button l-icon="s/horse">Horse</eg-button>
                        <eg-button icon="s/fish">Fish</eg-button>
                    </demo-panel>
                    <demo-panel title="Button google material icon" :code="demo.p">
                        <eg-button l-icon="+thumb_up">Good</eg-button>
                        <eg-button icon="+thumb_down">Bad</eg-button>
                    </demo-panel>
                    <demo-panel title="Button right icons" :code="demo.q">
                        <eg-button :icons="['svg:success', 'fa:fas fa-cat', 'ma:lock']">Right icons</eg-button>
                    </demo-panel>
                    <demo-panel title="Button left icons" :code="demo.r">
                        <eg-button :lIcons="['svg:success', 'fa:fas fa-cat', 'ma:lock']">Left icons</eg-button>
                    </demo-panel>
                    <demo-panel title="Button help text" :code="demo.r2">
                        <eg-button help="Thank you dear">Need help?</eg-button>
                    </demo-panel>
                </div>

                <div id="helpers">
                    <h3><a href="#helpers">Helpers</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>fr</strong>: {type: Boolean, default: false}: The icon will have the <code>float: right</code> CSS attribute.</li>
                            <li><strong>fl</strong>: {type: Boolean, default: false}: The icon will have the <code>float: left</code> CSS attribute.</li>
                            <li><strong>wide</strong>: {type: Boolean, default: false}: The icon will take all the horizontal space (the text will be centered).</li>
                            <li><strong>big</strong>: {type: Boolean, default: false}: The button appears bigger.</li>
                            <li><strong>small</strong>: {type: Boolean, default: false}: The button appears smaller.</li>
                        </ul>
                    </div>
                    <demo-panel title="Float left button" :code="demo.s">
                        <eg-button fl>Click</eg-button>
                    </demo-panel>
                    <demo-panel title="Float right button" :code="demo.t">
                        <eg-button fr>Click</eg-button>
                    </demo-panel>
                    <demo-panel title="Float wide button" :code="demo.u">
                        <eg-button wide>Click</eg-button>
                    </demo-panel>
                    <demo-panel title="Float wide button" :code="demo.v">
                        <eg-button small>Small</eg-button>
                        <eg-button>Regular</eg-button>
                        <eg-button big>Big</eg-button>
                    </demo-panel>
                </div>
            </div>

            <div class="show-doc">
                <div id="events">
                    <h2><a href="#events">Events</a></h2>
                    <ul>
                        <li><strong>@click</strong>: when the button is clicked (default event payload).</li>
                        <li><strong>@focus</strong>: The the button gets focus (default event payload).</li>
                        <li><strong>@blur</strong>: when the button looses focus (default event payload).</li>
                    </ul>
                </div>
                <div id="structure">
                    <h2><a href="#structure">Structure</a></h2>
                    <p>The Button has the following structure:</p>
                    <pre><code class="html">&lt;!-- If the button contains only text --&gt;
&lt;button class=&quot;eg-button button-shell primary&quot;&gt;Narrow&lt;/button&gt;

&lt;!-- Otherwise --&gt;
&lt;div class=&quot;eg-button button-shell primary&quot;&gt;
	&lt;div class=&quot;button-inner&quot;&gt;Label&lt;/div&gt;
&lt;/div&gt;
</code></pre>
                    <p>The sizing style is preferably applied to the class &quot;.button-shell&quot;. The style of the theme mainly alters this class.</p>
                </div>
            </div>
        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                i: 0,
                l: true,
                scrollMenuItems: [
                    {
                        name: "Attributes",
                        children: [
                            {name: "Main"},
                            {name: "Styles"},
                            {name: "State"},
                            {name: "Icons"},
                            {name: "Helpers"},
                        ]
                    },
                    {name: "Events"},
                    {name: "Structure"},
                ],
                demo: {
                    a:`<eg-button>Back</eg-button>
<eg-button primary>Save</eg-button>`,
                    b:`<eg-button disabled>Click</eg-button>`,
                    c:`<eg-button primary>Primary</eg-button>`,
                    d:`<eg-button secondary>Secondary</eg-button>`,
                    e:`<eg-button tertiary>Tertiary</eg-button>`,
                    f:`<eg-button transparent>Transparent</eg-button>`,
                    g:`<eg-button dark>Dark</eg-button>`,
                    h:`<eg-button light>Light</eg-button>`,
                    i:`<eg-button link>Link</eg-button>`,
                    j:`<eg-button :loading="l" @click="l=!l">Loading</eg-button>`,
                    k:`<eg-button error>Error</eg-button>`,
                    l:`<eg-button warning>Warning</eg-button>`,
                    m:`<eg-button success>Success</eg-button>`,
                    n:`<eg-button l-icon="svg:success">Success</eg-button>
<eg-button icon="svg:warning">Warning</eg-button>`,
                    o:`<eg-button l-icon="s/horse">Horse</eg-button>
<eg-button icon="s/fish">Fish</eg-button>`,
                    p:`<eg-button l-icon="+thumb_up">Good</eg-button>
<eg-button icon="+thumb_down">Bad</eg-button>`,
                    q:`<eg-button :icons="['svg:success', 'fa:fas fa-cat', 'ma:lock']">Right icons</eg-button>`,
                    r:`<eg-button :lIcons="['svg:success', 'fa:fas fa-cat', 'ma:lock']">Left icons</eg-button>`,
                    r2:`<eg-button help="Thank you dear">Need help?</eg-button>`,
                    s:`<eg-button fl>Click</eg-button>`,
                    t:`<eg-button fr>Click</eg-button>`,
                    u:`<eg-button wide>Click</eg-button>`,
                    v:`<eg-button small>Small</eg-button>
<eg-button>Regular</eg-button>
<eg-button big>Big</eg-button>`
                }
            }
        }
    }
</script>