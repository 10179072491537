<style lang="scss">
    .red-bg {
        --tooltip-bg: var(--color-primary);
    }
</style>

<template>
    <demo-page name="V-Tooltip" :menu="menu">
        <template slot="top">
            <p>Tooltips are simple text indications that are display next to an element. They are initially hidden, and displayed when the user needs more information.</p>
        </template>
        <template>
            <demo-panel title="Basic tooltip" :code="demo.a"><span v-tooltip="smallText">Target text</span></demo-panel>

            <div class="show-doc">
                <p>They can be set on any element (any html tag called tooltip parent in the documentation) to generate a tooltip &#60;span&#62; inside in order to position the tooltip based on the parent size.</p>
                <p>The tooltips can mainly be modified by editing the default CSS variables.</p>
            </div>

            <div class="show-doc" id="usage">
                <h2><a href="#usage">Usage</a></h2>
                <p>You can use the custom directive in multiple forms:</p>
                <ol>
                    <li>The most basic one is providing a string as directive argument to display it using the default parameters: <code>v-tooltip=&quot;'Hello world!'&quot;</code>.</li>
                    <li>You can also give an object containing the parameters to the tooltip if you want to change one or multiple parameters: <code>v-tooltip=&quot;args&quot;</code>.</li>
                    <li>You can also use modifiers to quickly change one parameter value. Example with the side parameter: <code>v-tooltip.left=&quot;args&quot;</code>. The list of modifiers can be found below.</li>
                    <li>If the parameters can change after the parent element is mounted, you can use the dynamic arguments: <code>v-tooltip:[args]</code>.</li>
                </ol>
            </div>

            <div id="parameters">
                <div class="show-doc">
                    <h2><a href="#parameters">Parameters</a></h2>
                    <p>Tooltips have the following parameters available. Most of the parameters have a one-letter shorthand that you can also use the same way (written between parenthesis next to the parameter name). You can also find the possible values after the description (with the default one in italic) or details about the unit to use.</p>
                    <ul>
                        <li><strong>text (t)</strong>: The text to display (a string).</li>
                        <li><strong>side (s)</strong>: Tooltip wrapper positioning (b, bottom, t, top, l, left, r, right).</li>
                        <li><strong>position (p)</strong>: Tooltip content positioning (l, r, c, t, b).</li>
                        <li><strong>zone (z)</strong>: shorthand to define the side and the position with a 2-letters string (side then position).</li>
                        <li><strong>display (d)</strong>: The trigger or interactivity option (hover, click, clickout, furtive).</li>
                        <li><strong>width (w)</strong>: Top / Bottom tooltip size constraint (in <em>px</em> or any other size unit).</li>
                        <li><strong>height (h)</strong>: Top / Bottom tooltip size constraint (in <em>px</em> or any other size unit).</li>
                        <li><strong>textAlign (a)</strong>: Text alignment in the tooltip (left, right, center).</li>
                        <li><strong>theme</strong>: CSS color quick change (dark, light).</li>
                        <li><strong>fontSize</strong>: The font size (in any font size unit).</li>
                        <li><strong>enterDelay</strong>: The time needed for the hovering event triggers the tooltip reveal (number in <em>ms</em>).</li>
                        <li><strong>exitDelay</strong>: The time needed for the hovering event triggers the tooltip hiding (number in <em>ms</em>).</li>
                    </ul>
                </div>

                <div class="show-code hide-fluid">
                    <h3><a href="#demo-args" id="demo-args">Demo arguments</a></h3>
                    <div class="args-panels">
                        <div class="args-panel">
                        <pre class="args-value"><strong>args</strong>: {
    text: 'Lorem [...]',
    display: 'hover',
    side: 'top',
    position: 'c',
    width: '200px',
    height: '0px',
}</pre>
                        </div>
                        <div class="args-panel">
                        <pre class="args-value"><strong>argsSide</strong>: {
    text: 'Lorem [...]',
    display: 'hover',
    side: 'left',
    position: 'c',
    width: '0',
    height: '100px',
}</pre>
                        </div>
                        <div class="args-panel">
                        <pre class="args-value"><strong>shortArgs</strong>: {
    t: 'Lorem [...]',
    d: 'hover',
    s: 'top',
    p: 'c',
    w: '200px',
    h: '0px',
}</pre>
                        </div>
                    </div>
                </div>

                <demo-panel title="Default tooltip" :code="demo.b"><span v-tooltip="'Short helping text'">Target text</span></demo-panel>
                <demo-panel title="Regular arguments tooltip" :code="demo.c"><span v-tooltip="args">Target text</span></demo-panel>
                <demo-panel title="Shorthands arguments tooltip" :code="demo.d"><span v-tooltip="shortArgs">Target text</span></demo-panel>
                <demo-panel title="Dynamic arguments tooltip" :code="demo.e"><span v-tooltip:[args]>Target text</span></demo-panel>
                <demo-panel title="Modifiers arguments" :code="demo.f"><span v-tooltip.bottom="args">Target text</span></demo-panel>

                <div id="text">
                    <h3><a href="#text">Text</a></h3>
                    <div class="show-doc">
                        <p>The number of lines of text displayed is base on the width or height of the wrapper (sizing), but you can change the size and alignment of the text (the color must be defined using theme).</p>
                    </div>

                    <demo-panel title="Tooltip displayed on click" :code="demo.g"><span v-tooltip="{t: 'Hello!', display: 'click'}">Target text</span></demo-panel>
                    <demo-panel title="Tooltip displayed on clickout" :code="demo.h"><span v-tooltip="{t: 'Hello there!', display: 'clickout'}">Target text</span></demo-panel>
                </div>

                <div id="display">
                    <h3><a href="#display">Display</a></h3>
                    <div class="show-doc">
                        <p>By default, the tooltip opens when the parent element is hovered but other triggers can be used:</p>
                        <ul>
                            <li><strong>hover</strong>: the tooltip is visible after a few ms (defined by enterDelay)  when the curor hovers the parent element. It is hidden after a few ms (defined by exitDelay) when the cursor exits the parent element.</li>
                            <li><strong>click</strong>: the tooltip is visible when the parent element is clicked. It is hidden when anything is clicked again.</li>
                            <li><strong>clickout</strong>: the tooltip is visible when the parent element is clicked. It is hidden when anything except the tooltip itself is clicked again.</li>
                        </ul>
                    </div>
                </div>

                <div id="positioning">
                    <h3><a href="#positioning">Positioning</a></h3>
                    <div class="show-doc">
                        <p>The positioning has two main components:</p>
                        <ul>
                            <li><strong>side</strong>: defines on which side of the parent element the tooltip should appear. It can be top, bottom, left or right. Each of these values have a shorthand value available: t, b, l and r.</li>
                            <li><strong>position</strong>: defines how the tooltip should be align compared to the parent element. It can be l, c, r (for left, center and right in a top or bottom sided), or t, c, b (for top, center and bottom in a left or right sided tooltip). For example, a tooltip with the left position will stick to the left of the parent element side and expand to the right.</li>
                        </ul>
                        <p>You can also use the shorthand zone to define both the side and the position as one attribute:</p>
                        <ul>
                            <li><strong>zone</strong>: 2 characters string defining the side (t, b, l, r) and the position (l, c, r, t, b) quickly (ex: &quot;tl&quot; for top left).</li>
                        </ul>
                    </div>

                    <demo-panel title="Tooltip positionning: Top side" :code="demo.i">
                        <div class="tri-col">
                            <div><span v-tooltip.l="shortArgs">Top left</span></div>
                            <div><span v-tooltip.c="shortArgs">Top center</span></div>
                            <div><span v-tooltip.r="shortArgs">Top right</span></div>
                        </div>
                    </demo-panel>
                    <demo-panel title="Tooltip positionning: Bottom side" :code="demo.j">
                        <div class="tri-col">
                            <div><span v-tooltip.l.bottom="shortArgs">Bottom left</span></div>
                            <div><span v-tooltip.c.bottom="shortArgs">Bottom center</span></div>
                            <div><span v-tooltip.r.bottom="shortArgs">Bottom right</span></div>
                        </div>
                    </demo-panel>
                    <demo-panel title="Tooltip positionning: Left side" :code="demo.k">
                        <div class="tri-col">
                            <div><span v-tooltip.t.tal="argsSide">Left top</span></div>
                            <div><span v-tooltip.c.tal="argsSide">Left center</span></div>
                            <div><span v-tooltip.b.tal="argsSide">Left bottom</span></div>
                        </div>
                    </demo-panel>
                    <demo-panel title="Tooltip positionning: Right side" :code="demo.l">
                        <div class="tri-col">
                            <div><span v-tooltip.t.right.tal="argsSide">Right top</span></div>
                            <div><span v-tooltip.c.right="argsSide">Right center</span></div>
                            <div><span v-tooltip.b.right.tar="argsSide">Right bottom</span></div>
                        </div>
                    </demo-panel>

                    <demo-panel title="Tooltip positioning using zone" :code="demo.m"><span v-tooltip="{t: 'Hello', zone: 'bl'}">Target text</span></demo-panel>
                </div>

                <div id="sizing">
                    <h3><a href="#sizing">Sizing</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>width</strong>: wrapper max width.</li>
                            <li><strong>height</strong>: wrapper max height.</li>
                        </ul>
                    </div>
                </div>

                <div id="themes">
                    <h3><a href="#themes">Themes</a></h3>
                    <div class="show-doc">
                        <p>The color of the tooltip, text and border (including the arrow) are defined by css variables. A set of these variables are defining a theme. By default, the tooltip has the dark theme and the background color is black with a white text, but you can use the light theme to display a white background tooltip with a black text, you defines the variables to make the tooltips match your design.</p>
                        <ul>
                            <li><strong>theme</strong>: dark or light.</li>
                        </ul>
                    </div>

                    <demo-panel title="Tooltip light theme" :code="demo.n"><span v-tooltip="{t: 'Hello', theme: 'light'}">Target text</span></demo-panel>
                    <demo-panel title="Tooltip custom css" :code="demo.o">
                        <span class="red-bg" v-tooltip="{t: 'Hello'}">Target text</span>
                    </demo-panel>
                </div>
            </div>

            <div class="show-doc" id="modifiers">
                <h2><a href="#modifiers">Modifiers</a></h2>
                <p>The modifiers are the following:</p>
                <ul>
                    <li>For <strong>position</strong>: <strong>l</strong>, <strong>c</strong>, <strong>r</strong>, <strong>t</strong> or <strong>b</strong> for 'left', 'center', 'right', 'top' or 'bottom' positioning.</li>
                    <li>For <strong>display</strong>: <strong>hover</strong>, <strong>click</strong> or clickout.</li>
                    <li>For <strong>side</strong>: <strong>top</strong>, <strong>right</strong>, <strong>bottom</strong> or <strong>left</strong>.</li>
                    <li>For <strong>text</strong>: <strong>tal</strong>, <strong>tar</strong> or <strong>tac</strong> for 'left', 'right' or 'center' text align.</li>
                    <li>For <strong>themes</strong>: <strong>dark</strong> or <strong>light</strong>.</li>
                </ul>
            </div>

            <div class="show-doc" id="structure">
                <h2><a href="#structure">Structure</a></h2>
                <p>The tooltip (here in a &#60;span&#62;Target text&#60;/span&#62; parent) has the following structure:</p>
                <pre><code class="html">&lt;span style=&quot;position: relative;&quot;&gt;Target text
  &lt;!-- Tooltip area --&gt;
  &lt;span class=&quot;tt-wrapper&quot; style=&quot;...&quot;&gt;
    &lt;!-- Tooltip bubble --&gt;
    &lt;span class=&quot;tt-content&quot; style=&quot;...&quot;&gt;Short helping text&lt;/span&gt;
  &lt;/span&gt;
&lt;/span&gt;
</code></pre>
            </div>
        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                menu: [
                    {name: "Usage"},
                    {
                        name: "Parameters",
                        children: [
                            {name: "Text"},
                            {name: "Display"},
                            {name: "Positioning"},
                            {name: "Sizing"},
                            {name: "Themes"},
                        ]
                    },
                    {name: "Modifiers"},
                    {name: "Structure"},
                ],
                args: {
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                    display: 'hover',
                    side: 'top',
                    position: 'c',
                    width: '200px',
                    height: '0px',
                },
                argsSide: {
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                    display: 'hover',
                    side: 'left',
                    position: 'c',
                    width: '0',
                    height: '100px',
                },
                shortArgs: {
                    t: 'Lorem ipsum dolor sit amet',
                    d: 'hover',
                    s: 'top',
                    p: 'c',
                    w: '200px',
                    h: '0px',
                },
                demo: {
                    a:`<span v-tooltip="smallText">Target text</span>`,
                    b:`<span v-tooltip="'Short helping text'">Target text</span>`,
                    c:`<span v-tooltip="args">Target text</span>`,
                    d:`<span v-tooltip="shortArgs">Target text</span>`,
                    e:`<span v-tooltip:[args]>Target text</span>`,
                    f:`<span v-tooltip.bottom="args">Target text</span>`,
                    g:`<span v-tooltip="{t: 'Hello!', display: 'click'}">Target text</span>`,
                    h:`<span v-tooltip="{t: 'Hello there!', display: 'clickout'}">Target text</span>`,
                    i:`<span v-tooltip.l="shortArgs">Top left</span>
<span v-tooltip.c="shortArgs">Top center</span>
<span v-tooltip.r="shortArgs">Top right</span>`,
                    j:`<span v-tooltip.l.bottom="shortArgs">Bottom left</span>
<span v-tooltip.c.bottom="shortArgs">Bottom center</span>
<span v-tooltip.r.bottom="shortArgs">Bottom right</span>`,
                    k:`<span v-tooltip.t.tal="argsSide">Left top</span>
<span v-tooltip.c.tal="argsSide">Left center</span>
<span v-tooltip.b.tal="argsSide">Left bottom</span>`,
                    l:`<span v-tooltip.t.right.tal="argsSide">Right top</span>
<span v-tooltip.c.right="argsSide">Right center</span>
<span v-tooltip.b.right.tar="argsSide">Right bottom</span>`,
                    m:`<span v-tooltip="{t: 'Hello', zone: 'bl'}">Target text</span>`,
                    n:`<span v-tooltip="{t: 'Hello', theme: 'light'}">Target text</span>`,
                    o:`<span class="red-bg" v-tooltip="{t: 'Hello'}">Target text</span>

.red-bg {
    --tooltip-bg: var(--color-primary);
}`
                },
                txt: 'Here!',
                smallText: 'Short helping text',
                bigText: 'Tooltip with a way bigger text explaining stuff that should be displayed on multiple lines'
            }
        }
    }
</script>