<style lang="scss">
    // Media queries thresholds from eg-elements
    @import "../../EgElementsProject/src/scss/thresholds";

    .demo-panel {
        vertical-align: top;
        width: 100%;
        margin-bottom: 1em;
        .eg-button {
            margin-right: 5px;
            margin-bottom: 5px;
        }
        .demo-panel-title {
            color: #AAA;
            font-size: 0.8rem;
            display: inline-block;
            text-decoration: none;
        }
        .demo-panel-title {
            margin-top: 1.5em;
        }
        .demo-panel-content {
            border: 1px solid #CCC;
        }
        .top-zone {
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0.5em;
                li {
                    display: inline-block;
                }
            }
        }
        .demo-panel-inner {
            display: flex;
            flex-wrap: wrap;
            @media all and (max-width: $Classic) {
                flex-direction: column;
            }
            .render-zone,
            .code-zone {
                flex: 1;
                padding: 1em 1.5em;
                margin: 0;
            }
            .render-zone {
                .eg-input {
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
            .code-zone {
                flex: 2;
                width: auto;
                code {
                    white-space: pre-wrap;
                    color: var(--primary-color);
                    font-family: monospace, monospace;
                    font-size: 1em;
                    opacity: 0.8;
                }
            }
        }
    }

    .eg-elements-demo-page.use-fluid .demo-panel,
    .demo-panel.fluid {
        display: inline-block;
        margin-right: 1em;
        margin-bottom: 1em;
        margin-top: 1em;
        width: auto;
        .demo-panel-title {
            margin-bottom: 10px;
        }
        .demo-panel-content {
            border: none;
            .demo-panel-inner .render-zone {
                padding: 0;
            }
        }
    }
</style>

<template>
    <div class="demo-panel" :id="url" :class="{fluid}" v-if="!hidden">
        <div class="top-zone">
            <a class="demo-panel-title" :href="`#${url}`" tabindex="-1">{{ title }}</a>
            <ul v-if="false">
                <li tabindex="0">Render</li>
                <li tabindex="0">Code</li>
            </ul>
        </div>
        <div class="demo-panel-content">
            <div class="demo-panel-inner">
                <div class="render-zone">
                    <slot></slot>
                </div>
                <pre v-if="!fluid" class="code-zone"><code>{{ code.trim() }}</code></pre>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            fluid: {type: Boolean, default: false},
            hidden: {type: Boolean, default: false},
            title: {type: String, default: ""},
            code: {type: String, default: ""},
        },
        computed: {
            url() { return this.title.toKebabCase() }
        }
    }
</script>