import VueRouter from 'vue-router'

import DemoHome from '../EgElementsDemo/DemoPages/DemoHome.vue'
import DemoButtons from '../EgElementsDemo/DemoPages/DemoButtons.vue'
import DemoTextInputs from '../EgElementsDemo/DemoPages/DemoTextInputs.vue'
import DemoSelects from '../EgElementsDemo/DemoPages/DemoSelects.vue'
import DemoNumberInputs from '../EgElementsDemo/DemoPages/DemoNumberInputs.vue'
import DemoCheckboxes from '../EgElementsDemo/DemoPages/DemoCheckboxes.vue'
import DemoModal from '../EgElementsDemo/DemoPages/DemoModal.vue'
import DemoIcons from '../EgElementsDemo/DemoPages/DemoIcons.vue'
import DemoSpecialIcons from '../EgElementsDemo/DemoPages/DemoSpecialIcons.vue'
import DemoTooltips from '../EgElementsDemo/DemoPages/DemoTooltips.vue'
import DemoSidemenu from '../EgElementsDemo/DemoPages/DemoSidemenu.vue'
import DemoTextarea from '../EgElementsDemo/DemoPages/DemoTextarea.vue'
import DemoTags from '../EgElementsDemo/DemoPages/DemoTags.vue'
import DemoVisible from '../EgElementsDemo/DemoPages/DemoVisible.vue'

const routes = [
    {
        path: '/buttons/:tab?',
        name: 'buttons',
        component: DemoButtons
    },
    {
        path: '/text-inputs/:tab?',
        name: 'text-inputs',
        component: DemoTextInputs
    },
    {
        path: '/inputs/:tab?',
        name: 'inputs',
        component: DemoTextInputs
    },
    {
        path: '/selects/:tab?',
        name: 'selects',
        component: DemoSelects
    },
    {
        path: '/number-inputs/:tab?',
        name: 'number-inputs',
        component: DemoNumberInputs
    },
    {
        path: '/checkboxes/:tab?',
        name: 'checkboxes',
        component: DemoCheckboxes
    },
    {
        path: '/modals/:tab?',
        name: 'modals',
        component: DemoModal
    },
    {
        path: '/icons/:tab?',
        name: 'icons',
        component: DemoIcons
    },
    {
        path: '/special-icons/:tab?',
        name: 'special-icons',
        component: DemoSpecialIcons
    },
    {
        path: '/tooltips/:tab?',
        name: 'tooltips',
        component: DemoTooltips
    },
    {
        path: '/sidemenu/:tab?',
        name: 'sidemenu',
        component: DemoSidemenu
    },
    {
        path: '/textarea/:tab?',
        name: 'textarea',
        component: DemoTextarea
    },
    {
        path: '/tags/:tab?',
        name: 'tags',
        component: DemoTags
    },
    {
        path: '/visible/:tab?',
        name: 'visible',
        component: DemoVisible
    },
    {
        path: '*',
        name: 'home',
        component: DemoHome
    }
];

const dashboard_router = new VueRouter({
    mode: 'history',
    //base: "/ege/",
    routes,
    scrollBehavior() {
        return {x: 0, y: 0, offset: {x: 0, y: 50}};
    }
});

export default dashboard_router