<template>
    <demo-page name="Modal" :menu="menu">
        <template slot="top">
            <p>Modals are UI elements used to display extra content on top of the whole page. Modal are generally closed by default and can be open (or closed) using the v-model value. When open, modals appear on top of a dark background (the modal shadow) covering the page. Clicking on the shadow or (on the corner cross) will hide the modal window to return to the previous app state.</p>
        </template>
        <template>
            <demo-panel title="Basic modal" :code="demo.a">
                <eg-button @click="show1=true">Show Modal</eg-button>
                <modal v-model="show1" title="Basic modal" validate="Do something!" back="Procrastinate">
                    <p>So you're telling me I can put anything in here?</p>
                </modal>
            </demo-panel>

            <div class="show-doc">
                <p>Modals can be displayed using by default <code>&lt;modal&gt;</code>.</p>
                <p>Their main characteristic is their size. By default, the modal will adjust its size to the content, but you can choose between two types of defined sizes (or apply css modification to the modal box element class <em>&quot;</em>.eg-modal-container&quot;):</p>
                <ul>
                    <li>Fixed: The modal size is set and there boolean attributes for all the thresholds: tiny, mini, smaller, small, classic, medium, long, big, bigger and large.</li>
                    <li>Flex: The size is proportional to the viewport width and will grow of shrink accordingly. You can choose between regular, basic, narrow and wide.</li>
                </ul>
                <p>The modal content can be anything passed using slots for any of the three zones of the modal:</p>
                <ul>
                    <li>The header (on the top) displays generally a title.</li>
                    <li>The body (on the middle, default slot) can display anything.</li>
                    <li>The footer (on the bottom) generally used to display <router-link :to="{name: 'buttons'}">buttons</router-link>. Traditionally, there is a button to confirm the action, and a button to go back and close the modal.</li>
                </ul>
            </div>

            <div id="attributes">
                <div class="show-doc">
                    <h2><a href="#attributes">Attributes</a></h2>
                </div>

                <div id="main">
                    <h3><a href="#main">Main</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>value</strong>: {default: null}: The value of the modal is given and updated using v-model. The modal can be displayed (if truthy) or hidden (if falsy), based on &quot;value&quot;<em>.</em></li>
                            <li><strong>animation</strong>: {type: String, default: 'smooth'}: The revealing animation. Will be given to the transition tag. The available transitions are based on the theme. The default transition is "smooth" with a zoom effect, but you can also use "fade" for a more simple fading effect.</li>
                        </ul>
                    </div>

                    <demo-panel title="Modal animation fade" :code="demo.d">
                        <eg-button @click="show3=true">Show Modal</eg-button>
                        <modal v-model="show3" small title="Modal" content="Lorem ipsum?" animation="fade"></modal>
                    </demo-panel>
                </div>

                <div id="sizing">
                <h3><a href="#sizing">Sizing</a></h3>

                    <div id="responsive-sizes">
                        <h4><a href="#responsive-sizes">Responsive sizes</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>regular</strong>: {type: Boolean, default: false}: Width of 25vw.</li>
                                <li><strong>basic</strong>: {type: Boolean, default: false}: Width of 33vw.</li>
                                <li><strong>narrow</strong>: {type: Boolean, default: false}: Width of 50vw.</li>
                                <li><strong>wide</strong>: {type: Boolean, default: false}: Width of 75vw.</li>
                            </ul>
                        </div>

                        <demo-panel title="Modal default size" :code="demo.e">
                            <eg-button @click="show4=true">Show Modal</eg-button>
                            <modal v-model="show4" title="Modal" :content="lorem"></modal>
                        </demo-panel>
                        <demo-panel title="Modal sizes" :code="demo.f">
                            <eg-button @click="show5=true">Tiny</eg-button>
                            <modal tiny v-model="show5" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show6=true">Mini</eg-button>
                            <modal mini v-model="show6" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show7=true">Smaller</eg-button>
                            <modal smaller v-model="show7" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show12=true">Small</eg-button>
                            <modal small v-model="show12" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show13=true">Classic</eg-button>
                            <modal classic v-model="show13" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show14=true">Medium</eg-button>
                            <modal medium v-model="show14" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show15=true">Long</eg-button>
                            <modal long v-model="show15" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show16=true">Big</eg-button>
                            <modal big v-model="show16" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show17=true">Bigger</eg-button>
                            <modal bigger v-model="show17" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show18=true">Large</eg-button>
                            <modal large v-model="show18" title="Modal" :content="lorem"></modal>
                        </demo-panel>
                    </div>

                    <div id="fixed-sizes">
                        <h4><a href="#fixed-sizes">Fixed sizes</a></h4>
                        <div class="show-doc">
                            <ul>
                                <li><strong>tiny</strong>: {type: Boolean, default: false}: Width of 300px.</li>
                                <li><strong>mini</strong>: {type: Boolean, default: false}: Width of 480px.</li>
                                <li><strong>smaller</strong>: {type: Boolean, default: false}: Width of 540px.</li>
                                <li><strong>small</strong>: {type: Boolean, default: false}: Width of 720px.</li>
                                <li><strong>classic</strong>: {type: Boolean, default: false}: Width of 900px,</li>
                                <li><strong>medium</strong>: {type: Boolean, default: false}: Width of 1090px.</li>
                                <li><strong>long</strong>: {type: Boolean, default: false}: Width of 1200px.</li>
                                <li><strong>big</strong>: {type: Boolean, default: false}: Width of 1440px.</li>
                                <li><strong>bigger</strong>: {type: Boolean, default: false}: Width of 1600px.</li>
                                <li><strong>large</strong>: {type: Boolean, default: false}: Width of 1920px.</li>
                            </ul>
                        </div>

                        <demo-panel title="Modal sizes" :code="demo.g">
                            <eg-button @click="show19=true">Narrow</eg-button>
                            <modal narrow v-model="show19" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show24=true">Basic</eg-button>
                            <modal basic v-model="show24" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show25=true">Regular</eg-button>
                            <modal regular v-model="show25" title="Modal" :content="lorem"></modal>
                            <eg-button @click="show20=true">Wide</eg-button>
                            <modal wide v-model="show20" title="Modal" :content="lorem"></modal>
                        </demo-panel>
                    </div>
                </div>

                <div id="content">
                    <h3><a href="#content">Content</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>title</strong>: {type: String, default: null}: The title of the modal, displayed in the header.</li>
                            <li><strong>content</strong>: {type: String, default: null}: A string to be display in a paragraph as modal content.</li>
                            <li><strong>back</strong>: {type: String, default: null}: If defined, a default button will be displayed in the footer. The label button will be the value of <em>back.</em></li>
                            <li><strong>b</strong>: {type: Boolean, default: false}: Shortcut to use the back button with the default label.</li>
                            <li><strong>validate</strong>: {type: String, default: null}: If defined, a primary button will be displayed in the right of the footer. The label button will be the value of &quot;validate&quot;<em>.</em></li>
                            <li><strong>v</strong>: {type: Boolean, default: false}: Shortcut to use the primary button with the default label.</li>
                        </ul>
                    </div>

                    <demo-panel title="Modal content shorthands" :code="demo.h">
                        <eg-button @click="show8=true">Show Modal</eg-button>
                        <modal basic v-model="show8"
                               title="Custom modal"
                               content="Any content here"
                               b v @validate="handleAction">
                        </modal>
                    </demo-panel>
                    <demo-panel title="Modal custom button labels" :code="demo.i">
                        <eg-button @click="show21=true">Show Modal</eg-button>
                        <modal basic v-model="show21"
                               title="Custom modal"
                               content="Any content here"
                               back="Go back?"
                               validate="Go forward?"
                               @validate="handleAction">
                        </modal>
                    </demo-panel>
                </div>

                <div id="helpers">
                    <h3><a href="#helpers">Helpers</a></h3>
                    <div class="show-doc">
                        <ul>
                            <li><strong>noCross</strong>: {type:Boolean, default: null}: If true, the closing cross will be hidden.</li>
                            <li><strong>noShadowClose</strong>: {type: Boolean, default: false}: If true, clicking on the modal shadow will not close the modal.</li>
                            <li><strong>noFocus</strong>: {type: Boolean, default: false}: If true, the modal will not have the focus when displayed (preventing an escape key closing).</li>
                        </ul>
                    </div>

                    <demo-panel title="Modal no cross" :code="demo.j">
                        <eg-button @click="show9=true">Show Modal</eg-button>
                        <modal basic v-model="show9" title="Modal" :content="lorem" no-cross></modal>
                    </demo-panel>
                    <demo-panel title="Modal no shadow click" :code="demo.k">
                        <eg-button @click="show10=true">Show Modal</eg-button>
                        <modal basic v-model="show10" title="Modal" :content="lorem" no-shadow-close></modal>
                    </demo-panel>
                    <demo-panel title="Modal no focus" :code="demo.l">
                        <eg-button @click="show11=true">Show Modal</eg-button>
                        <modal basic v-model="show11" title="Modal" :content="lorem" no-focus></modal>
                    </demo-panel>
                </div>
            </div>

            <div class="show-doc" id="events">
                <h2><a href="#events">Events</a></h2>
                <ul>
                    <li>v-model (<strong>@input</strong>): boolean flip to show (or hide if false) the modal window.</li>
                    <li><strong>@crossClick</strong>: when the modal cross is clicked. No payload.</li>
                    <li><strong>@close</strong>: when the modal closes. No payload.</li>
                    <li><strong>@validate</strong>: when the &quot;validate&quot; shorthand button is clicked. No payload.</li>
                </ul>
            </div>

            <div class="show-doc" id="slots">
                <h2><a href="#slots">Slots</a></h2>
                <ul>
                    <li><strong>header</strong>: Markup to insert in the modal window header.</li>
                    <li><strong>content</strong> (also default and body): Markup to insert in the modal window body.</li>
                    <li><strong>footer</strong>: Markup to insert in the modal window footer.</li>
                    <li><strong>leftFooter</strong>: Markup to insert on the left side of the modal window footer.</li>
                </ul>
                <p>The default slot sets its content in the body wrapper as well.</p>
            </div>

            <div class="show-doc" id="structure">
                <h2><a href="#structure">Structure</a></h2>
                <p>The Modal component has the following structure:</p>
                <pre><code class="jsx">&lt;div class=&quot;eg-modal-mask&quot;&gt;
  &lt;div class=&quot;eg-modal-wrapper&quot;&gt;
    &lt;!-- Modal window --&gt;
    &lt;div class=&quot;eg-modal-container&quot;&gt;
      &lt;div class=&quot;eg-modal-content&quot;&gt;
        &lt;div class=&quot;exit-cross&quot;&gt;&lt;eg-icon type=&quot;cross&quot;&gt;&lt;/eg-icon&gt;&lt;/div&gt;
        &lt;!-- Header --&gt;
        &lt;div class=&quot;eg-modal-header&quot; v-if=&quot;$slots.header&quot;&gt;
          &lt;slot name=&quot;header&quot;&gt;&lt;/slot&gt;
        &lt;/div&gt;
        &lt;!-- Body --&gt;
        &lt;div class=&quot;eg-modal-body&quot;&gt;
          &lt;slot name=&quot;body&quot;&gt;&lt;/slot&gt;
        &lt;/div&gt;
        &lt;!-- Footer --&gt;
        &lt;div class=&quot;eg-modal-footer-wrapper&quot;&gt;
          &lt;div class=&quot;eg-modal-side-footer&quot;&gt;
            &lt;slot name=&quot;leftFooter&quot;&gt;&lt;/slot&gt;
          &lt;/div&gt;
          &lt;div class=&quot;eg-modal-footer&quot; v-if=&quot;!noFooter&quot;&gt;
            &lt;slot name=&quot;footer&quot;&gt;&lt;/slot&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
</code></pre>
            </div>

        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                show1: false,
                show2: false,
                show3: false,
                show4: false,
                show5: false,
                show6: false,
                show7: false,
                show8: false,
                show9: false,
                show10: false,
                show11: false,
                show12: false,
                show13: false,
                show14: false,
                show15: false,
                show16: false,
                show17: false,
                show18: false,
                show19: false,
                show20: false,
                show21: false,
                show22: false,
                show24: false,
                show25: false,
                show26: false,
                show27: false,
                show28: false,
                show29: false,
                lorem: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus illum magni, minus nihil quae quod repudiandae? Amet beatae dicta possimus, quasi saepe vero vitae voluptatem. Animi iure nihil rem repudiandae.",
                menu: [
                    {
                        name: "Attributes",
                        children: [
                            {name: "Main"},
                            {name: "Sizing"},
                            {name: "Content"},
                            {name: "Helpers"},
                        ]
                    },
                    {name: "Events"},
                    {name: "Slots"},
                    {name: "Structure"},
                ],
                demo: {
                    a:`<eg-button @click="show1=true">Show Modal</eg-button>
<modal v-model="show1" title="Basic modal" validate="Do something!" back="Procrastinate">
    <p>So you're telling me I can put anything in here?</p>
</modal>`,
                    d:`<modal v-model="show3" small title="Modal" content="Lorem ipsum?" animation="fade"></modal>`,
                    e:`<modal v-model="show4" title="Modal" :content="lorem"></modal>`,
                    f:`<modal tiny v-model="show5" title="Modal" :content="lorem">
    <!-- Replace tiny by any of the thresholds name -->
</modal>`,
                    g:`<modal narrow v-model="show5" title="Modal" :content="lorem">
    <!-- Replace narrow by any of the thresholds name -->
</modal>`,
                    h:`<modal basic v-model="show8"
       title="Custom modal"
       content="Any content here"
       b v @validate="handleAction">
</modal>`,
                    i:`<modal basic v-model="show21"
       title="Custom modal"
       content="Any content here"
       back="Go back?"
       validate="Go forward?"
       @validate="handleAction">
</modal>`,
                    j:`<modal basic v-model="show9" title="Modal" :content="lorem" no-cross></modal>`,
                    k:`<modal basic v-model="show10" title="Modal" :content="lorem" no-shadow-close></modal>`,
                    l:`<modal basic v-model="show11" title="Modal" :content="lorem" no-focus></modal>`,
                }
            }
        },
        methods: {
            handleAction() {}
        }
    }
</script>