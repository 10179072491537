<style lang="scss">
    // Media queries thresholds from eg-elements
    @import "../../EgElementsProject/src/scss/thresholds";

    .side-menu-main-panel {
        background-color: rgba(var(--rgb-default),0.25);
        .eg-sidemenu-wrapper {
            width: 200px;
            .eg-sidemenu {
                @media all and (max-width: $Classic) {
                }
            }
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                &.side-menu-items {
                    margin: 1em 0;
                    li {
                        margin: 0;
                        &.separator-title {
                            font-size: 0.75em;
                            padding: 0.75em 2em;
                            margin-top: 1em;
                            color: var(--color-primary);
                            opacity: 0.5;
                        }
                        a {
                            box-sizing: border-box;
                            display: inline-block;
                            width: 100%;
                            color: #444;
                            padding: 0.75em 2em;
                            text-decoration: none;
                            &:hover {
                                background-color: rgba(0,0,0,0.05);
                                color: #999;
                            }
                            &.router-link-active {
                                background-color: var(--color-primary);
                                color: #f5f5f5;
                            }
                        }
                        ul {
                            a {
                                color: #999;
                                padding-left: 2.5em;
                            }
                        }
                    }
                }
            }
        }
    }
    .brand-title {
        height: 170px;
        width: 100%;
        cursor: pointer;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &, & > * {
            font-size: 1.5rem;
        }
        & > div {
            display: flex;
            align-items: center;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 30%;
            right: 30%;
            background-color: #CCCCCC;
            height: 1px;
            @media all and (max-width: $Classic) {
                left: 15px;
                right: 15px;
            }
        }
        svg {
            height: 70px;
            width: 100%;
            path:first-child {
                fill: var(--color-primary);
            }
            path:nth-child(2) {
                fill: var(--color-light-text);
            }
        }
        .logo-eg-text,
        .logo-elements-text {
            font-size: 0.8em;
            display: flex;
            user-select: none;
        }
        .logo-eg-text {
            margin-right: 5px;
            color: var(--color-primary);
        }
        .logo-elements-text {
            color: var(--color-light-text);
        }

    }
    .sidemenu {
        background-color: rgba(var(--rgb-default),0.25);
    }
    .small-navbar {
        width: 100%;
        height: 50px;
        display: flex;
        background-color: #F5F5F5;
        border-bottom: 1px solid #ccc;
        position: fixed;
        z-index: 1;
        @media all and (min-width: $Classic) {
            display: none;
        }
        .brand-title {
            height: 100%;
            padding: 0;
            width: 140px;
            flex: 1;
            font-size: 1.2rem;
            flex-direction: row;
            &::after {
                content: none;
            }
            svg {
                height: 80%;
                width: 60px;
                padding: 10px 0;
                margin: 0;
            }
        }
        .hamburger {
            transform: scale(0.6);
            position: absolute;
            top: -3px;
            left: 0;
            z-index: 1;
            .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                background-color: #AAA;
            }
            &:focus {
                outline: none;
                background-color: rgba(0,0,0,.1);
                .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                    background-color: #222;
                }
            }
        }
    }
    .top-slot {
        padding: 10px;
        max-height: 50px;
        transition: max-height .2s, padding .2s;
        margin-top: 5px;
        &.hidden {
            max-height: 0;
            padding: 0 5px;
            overflow: hidden;
        }
    }
    .side-menu-wrapper {
        position: sticky;
        top: 0;
        .shadow-zone {
            display: none;
        }
        @media all and (max-width: $Classic) {
            .shadow-zone {
                display: block;
            }
            position: relative;
        }
    }
    .sidemenu-navigation {
        margin-top: 10px;
        .demo-scroll-menu {
            aside {
                margin-right: 0;
                width: 100%;
                .scroll-menu-action {
                    background-color: #f5f5f5;
                    margin-bottom: 0;
                    text-align: center;
                    border-bottom: 1px solid #ccc;
                    border-top: 1px solid #ccc;
                    .scroll-menu-title {
                        opacity: 1;
                        left: 0;
                        padding: 0 1em;
                    }
                }
            }
            ul {
                padding-right: 0;
                li.visible > a::after {
                    width: 3px;
                    left: 2px;
                }
            }
        }
        display: none;
        .menu-top-title {
            padding: 1em;
            margin: 0;
        }
        @media all and (max-width: $Classic) {
            display: block;
        }
    }
    .side-menu-container {
        @media all and (max-width: $Classic) {
            height: 60px;
        }
    }
</style>

<template>
    <div class="side-menu-main-panel">
        <eg-sidemenu threshold="classic" v-model="showMenu" orientation="left" class="side-menu-wrapper">
            <div class="side-menu">
                <div class="side-menu-container">
                    <div class="brand-title" @click="hBrandTitleClick">
                        <svg width="500" height="500" version="1.1" viewBox="0 0 132.29 132.29" xmlns="http://www.w3.org/2000/svg">
                            <path d="m6.89 16.743v98.805h59.256v-18.052h-37v-61.119c2.1177-0.837 4.427-1.3383 6.9221-1.5131h4.053c2.0853 0.15344 4.0776 0.54452 5.9764 1.1751 2.5421 0.83342 4.7584 1.8299 6.6487 2.9895 1.6296 0.97837 3.3245 2.1742 5.0844 3.5874 1.7599 1.377 3.096 2.4461 4.0085 3.207h2.1022v-18.97c-0.0231-0.01241-0.0457-0.02429-0.0687-0.03669v-10.074zm61.898 41.442v15.273h22.894v-15.273z" fill="#45648f"/>
                            <path d="m68.42 16.743v18.121h34.727v62.632h-37v18.052h59.256v-98.805zm-27.809 41.443v15.273h22.894v-15.273z" fill="#b3b3b3"/>
                        </svg>
                        <div><span class="logo-eg-text">EG</span> <span class="logo-elements-text">Elements</span></div>
                    </div>
                    <slot name="top"></slot>
                    <div class="sidemenu-navigation">
                        <slot name="navigation"></slot>
                    </div>
                    <ul class="side-menu-items">
                        <li class="separator-title">Basics</li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'buttons'}">Buttons</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'icons'}">Icons</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'special-icons'}">Special Icons</router-link></li>
                        <li class="separator-title">Inputs</li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'text-inputs'}">Text Input</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'textarea'}">Textarea</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'number-inputs'}">Number Input</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'selects'}">Select</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'checkboxes'}">Checkbox</router-link></li>
                        <li class="separator-title">Structure</li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'modals'}">Modal</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'sidemenu'}">Sidemenu</router-link></li>
                        <li class="separator-title">Helpers</li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'tooltips'}">V-Tooltip</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'visible'}">V-Visible</router-link></li>
                        <li><router-link :to="{params: {tab: this.$route.params.tab}, name:'tags'}">Tags</router-link></li>
                    </ul>
                    <template v-if="false"><slot></slot></template>
                </div>
            </div>
        </eg-sidemenu>
        <div class="small-navbar">
            <hamburger-icon class="hamburger--collapse" :active="showMenu" @click="showMenu=!showMenu"></hamburger-icon>
            <span class="brand-title" @click="hBrandTitleClick">
                <svg width="500" height="500" version="1.1" viewBox="0 0 132.29 132.29" xmlns="http://www.w3.org/2000/svg">
                    <path d="m6.89 16.743v98.805h59.256v-18.052h-37v-61.119c2.1177-0.837 4.427-1.3383 6.9221-1.5131h4.053c2.0853 0.15344 4.0776 0.54452 5.9764 1.1751 2.5421 0.83342 4.7584 1.8299 6.6487 2.9895 1.6296 0.97837 3.3245 2.1742 5.0844 3.5874 1.7599 1.377 3.096 2.4461 4.0085 3.207h2.1022v-18.97c-0.0231-0.01241-0.0457-0.02429-0.0687-0.03669v-10.074zm61.898 41.442v15.273h22.894v-15.273z" fill="#45648f"/>
                    <path d="m68.42 16.743v18.121h34.727v62.632h-37v18.052h59.256v-98.805zm-27.809 41.443v15.273h22.894v-15.273z" fill="#b3b3b3"/>
                </svg>
                <div><span class="logo-eg-text">EG</span> <span class="logo-elements-text">Elements</span></div>
            </span>
        </div>
    </div>
</template>

<script>
    import { HamburgerIcon } from '../../EgElementsProject';

    export default {
        components: {
            HamburgerIcon
        },
        data() {
            return {
                showMenu: false
            }
        },
        methods: {
            hBrandTitleClick() {
                if(this.$route.name !== "home")
                    this.$router.push({name: 'home'});
                window.scrollTo(0,0);
            }
        },
        watch: {
            "$route.hash"() {
                this.showMenu = false;
            }
        }
    }
</script>