<template>
    <demo-page name="Number Input" :menu="menu">
        <template slot="top">
            <p>Number inputs are UI elements used to allow the user to enter numbers. It ensures that a number will be entered, an provide easy number manipulation UI.</p>
        </template>
        <div class="value-wrapper" slot="sidemenu">
            <div class="value-container">
                <span class="value-label">Simple value:</span>
                <pre>{{ val }}</pre>
            </div>
        </div>
        <template>

            <demo-panel title="Simple number input" :code="demo.a">
                <eg-input type="number" v-model="val"></eg-input>
            </demo-panel>

            <div class="show-doc">
                <p>Number inputs can be displayed using by default <code>&lt;eg-input&gt;</code> with the 'number' type.</p>
                <p>The native input number type is used. The value can be limited with a minimum and maximum, and incremented (by steps) using the keyboard up or bottom arrow keys. Small and big steps can be used holding respectively ctrl or shift while incrementing. Plus or minus stack buttons are also available to quickly increment the value using the mouse.</p>
                <p>This component is an <router-link :to="{name: 'text-inputs'}">Input</router-link>. It has a similar structure and shares all its arguments. Nevertheless, it has some specific attributes.</p>
            </div>

            <div id="attributes">
                <div class="show-doc">
                    <h2><a href="#attributes">Attributes</a></h2>
                    <ul>
                        <li><strong>decimals</strong>: {type: Number, default: null}: Number of decimal to round to.</li>
                        <li><strong>step</strong>: {type: Number, default: 1}: Base increment value.</li>
                        <li><strong>stepBig</strong>: {type: Number, default: 10}: Big increment value.</li>
                        <li><strong>min</strong>: {type: Number, default: null}: Minimal value accepted (any value below will be converted to the min value).</li>
                        <li><strong>max</strong>: {type: Number, default: null}: Maximal value accepted (any value above will be converted to the max value).</li>
                        <li><strong>plusMinus</strong>: {type: Boolean, default: true}: If false, the plus and minus button will be hidden.</li>
                        <li><strong>strongDecimals</strong>: {type: Boolean, default: false}: If true, the decimals will always be displayed.</li>
                    </ul>
                </div>

                <demo-panel title="Number input decimals" :code="demo.b">
                    <eg-input type="number" v-model="val" :decimals="1"></eg-input>
                </demo-panel>
                <demo-panel title="Number input custom steps" :code="demo.c">
                    <eg-input type="number" v-model="val" :step="5" :stepBig="50" :stepSmall="1"></eg-input>
                </demo-panel>
                <demo-panel title="Number input decimals" :code="demo.d">
                    <eg-input type="number" v-model="val" :min="0" :max="10" message="Between 0 and 10"></eg-input>
                </demo-panel>
                <demo-panel title="Number input decimals" :code="demo.e">
                    <eg-input type="number" v-model="val" :plusMinus="false"></eg-input>
                </demo-panel>
                <demo-panel title="Number input decimals" :code="demo.f">
                    <eg-input type="number" v-model="val" :decimals="2" strongDecimals></eg-input>
                </demo-panel>
            </div>
            <div class="show-doc" id="events">
                <h2><a href="#events">Events</a></h2>
                <ul>
                    <li>v-model (<strong>@input</strong>): any value can be passed to the field but will be converted to a number.</li>
                    <li>The Input events: <strong>@click</strong>, <strong>@iconClick</strong>, <strong>@change</strong>, <strong>@focus</strong>, <strong>@blur</strong>, <strong>@mousedown</strong>, <strong>@cross</strong>, <strong>@escape</strong>, <strong>@enter</strong>, <strong>@down</strong>, <strong>@up</strong>, <strong>@keydown</strong>, <strong>@keyup</strong>.</li>
                </ul>
            </div>

        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                val: 0,
                menu: [
                    {name: "Usage"},
                    {name: "Events"},
                    {
                        name: "Attributes",
                        children: [
                            {name: "Number"}
                        ]
                    },
                ],
                demo: {
                    a:`<eg-input type="number" v-model="val"></eg-input>`,
                    b:`<eg-input type="number" v-model="val" :decimals="1"></eg-input>`,
                    c:`<eg-input type="number" v-model="val" :step="5" :stepBig="50" :stepSmall="1"></eg-input>`,
                    d:`<eg-input type="number" v-model="val" :min="0" :max="10" message="Between 0 and 10"></eg-input>`,
                    e:`<eg-input type="number" v-model="val" :plusMinus="false"></eg-input>`,
                    f:`<eg-input type="number" v-model="val" :decimals="2" strongDecimals></eg-input>`
                }
            }
        }
    }
</script>