<template>
    <demo-page no-tab name="EG Components" :menu="menu">
        <template slot="top">
            <p>EG Elements is a vuejs library of modular and easily customizable interface components.</p>
        </template>
        <template>

            <div id="installation">
                <h2><a href="#installation">Installation</a></h2>
                <p>Using <strong>npm</strong>:</p>
                <pre><code>npm i eg-elements
</code></pre>
            </div>
            <div id="usage">
                <h2><a href="#usage">Usage</a></h2>
                <p>To use the library globally and register all the components in your main js file:</p>
                <pre><code>import eg from &quot;eg-elements&quot;
Vue.use(eg)
</code></pre>
                <p>You can alternatively use the components individually in one of your components:</p>
                <pre><code>import { EgInput, EgButton } from 'eg-elements'
</code></pre>
            </div>
            <div id="components">
                <h2><a href="#components">Components</a></h2>
                <p>Basics:</p>
                <ul>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Button.md">Buttons</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Icon.md">Icons</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Special%20Icons.md">Special Icons</a></li>
                </ul>
                <p>Inputs:</p>
                <ul>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Input.md">Text Input</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Textarea.md">Textarea</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Number%20Input.md">Number Input</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Select.md">Select</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/EgCheckbox.md">Checkbox</a></li>
                </ul>
                <p>Structure:</p>
                <ul>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Modal.md">Modal</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Sidemenu.md">Sidemenu</a></li>
                </ul>
                <p>Helpers:</p>
                <ul>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/V-Tooltip.md">V-Tooltip directive</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/V-Visible.md">V-Visible directive</a></li>
                    <li><a href="https://github.com/misurida/eg-elements/blob/master/doc/Tags.md">Tags</a></li>
                </ul>
            </div>
            <div id="global-parameters">
                <h2><a href="#global-parameters" >Global parameters</a></h2>
                <p>you can pass some parameters when using Vue.use() to set up several actions:</p>
                <ul>
                    <li>First of all, the native icon libraries selected as a string or an array with the attribute &quot;icons&quot;</li>
                    <li>Then the globally installed components can be selected and their names can be customized by passing to the &quot;components&quot; attribute an object that contains the component code as a key and the chosen name as a value.</li>
                    <li>Finally, the theme can be selected, either as a single theme by passing a string to the &quot;theme&quot; attribute, or by passing multiple themes with a table. If multiple themes are selected, they will be active only if one of the parent elements has the theme name as class.</li>
                </ul>
                <pre><code class="jsx">Vue.use(ege, {
    theme: &quot;basic&quot;,
    components: {
        icon: &quot;icon&quot;,
        input: &quot;text-input&quot;,
        numberInput: &quot;eg-number&quot;
    },
    icons: ['brandico', 'elusive']
});
</code></pre>
            </div>
            <div id="design-and-themes">
                <h2><a href="#design-and-themes" >Design and Themes</a></h2>
                <p>Each component has an internal CSS structure with a default design and some key classes that you can target and customize. If you select a theme using Vue.use(), you can also use CSS variables to quickly modify the key attributes of the components used in your own css files.</p>
                <p>If you import one or more components individually, you can also import the theme manually. The path for the Basic theme is &quot;~eg-elements/src/themes/theme_basic.scss&quot;.</p>
                <p>Here is the page of the <a href="https://github.com/misurida/eg-elements/blob/master/doc/Basic%20Theme.md">Basic theme</a> that contains the list of variables and that is currently displayed by default in the demo. The main purpose of using variables is to be able to stylize a component locally. You can overwrite the value of any of these variables in of the parents classes to make it fit the situation, of even your whole design if you overwrite most of them in the :root.</p>
                <p>The components styles are written using <code>lang=&quot;scss&quot;</code>, so you might need the <a href="https://vue-loader.vuejs.org/guide/pre-processors.html">Webpack SASS pre-processor</a> if you want to tweak the elements files:</p>
            </div>


            <div>
                <pre><code class="css">/*|   Colors    |*/

// main colors
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbDefault)" @input="hCC($event, 'rgbDefault')"></span>--rgb-default: {{ rgbDefault }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbDefaultText)" @input="hCC($event, 'rgbDefaultText')"></span>--rgb-default-text: {{ rgbDefaultText }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbPrimary)" @input="hCC($event, 'rgbPrimary')"></span>--rgb-primary: {{ rgbPrimary }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbPrimaryText)" @input="hCC($event, 'rgbPrimaryText')"></span>--rgb-primary-text: {{ rgbPrimaryText }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbSecondary)" @input="hCC($event, 'rgbSecondary')"></span>--rgb-secondary: {{ rgbSecondary }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbSecondaryText)" @input="hCC($event, 'rgbSecondaryText')"></span>--rgb-secondary-text: {{ rgbSecondaryText }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbTertiary)" @input="hCC($event, 'rgbTertiary')"></span>--rgb-tertiary: {{ rgbTertiary }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbTertiaryText)" @input="hCC($event, 'rgbTertiaryText')"></span>--rgb-tertiary-text: {{ rgbTertiaryText }};

// status colors
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbError)" @input="hCC($event, 'rgbError')"></span>--rgb-error: {{ rgbError }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbWarning)" @input="hCC($event, 'rgbWarning')"></span>--rgb-warning: {{ rgbWarning }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbSuccess)" @input="hCC($event, 'rgbSuccess')"></span>--rgb-success: {{ rgbSuccess }};

// special colors
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbDark)" @input="hCC($event, 'rgbDark')"></span>--rgb-dark: {{ rgbDark }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbDarkText)" @input="hCC($event, 'rgbDarkText')"></span>--rgb-dark-text: {{ rgbDarkText }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbLight)" @input="hCC($event, 'rgbLight')"></span>--rgb-light: {{ rgbLight }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbLightText)" @input="hCC($event, 'rgbLightText')"></span>--rgb-light-text: {{ rgbLightText }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbTransparentText)" @input="hCC($event, 'rgbTransparentText')"></span>--rgb-transparent-text: {{ rgbTransparentText }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbBlack)" @input="hCC($event, 'rgbBlack')"></span>--rgb-black: {{ rgbBlack }};
<span class="css-i"><input type="color" class="col-c" :value="bv(rgbWhite)" @input="hCC($event, 'rgbWhite')"></span>--rgb-white: {{ rgbWhite }};

// rgb renders
--color-default: rgb(var(--rgb-default));
--color-default-text: rgb(var(--rgb-default-text));
--color-primary: rgb(var(--rgb-primary));
--color-primary-text: rgb(var(--rgb-primary-text));
--color-secondary: rgb(var(--rgb-secondary));
--color-secondary-text: rgb(var(--rgb-secondary-text));
--color-tertiary: rgb(var(--rgb-tertiary));
--color-tertiary-text: rgb(var(--rgb-tertiary-text));
--color-error: rgb(var(--rgb-error));
--color-warning: rgb(var(--rgb-warning));
--color-success: rgb(var(--rgb-success));
--color-dark: rgb(var(--rgb-dark));
--color-dark-text: rgb(var(--rgb-dark-text));
--color-light: rgb(var(--rgb-light));
--color-light-text: rgb(var(--rgb-light-text));
--color-transparent-text: rgb(var(--rgb-transparent-text));
--color-black: rgb(var(--rgb-black));
--color-white: rgb(var(--rgb-white));

/*|   Buttons    |*/
--button-padding: var(--input-padding);
--button-padding-big: var(--input-padding-big);
--button-padding-small: var(--input-padding-small);

/*|   Inputs    |*/
--input-bg: #fff;
--input-border: thin solid #cdcdcd;
--input-border-focus: thin solid #bebebe;
--input-color: var(--color-black);
--input-color-placeholder: rgba(0,0,0,.5);
--input-color-placeholder-focus: rgba(0,0,0,.65);
--input-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
--input-icon-gap: 0.75em;
--input-padding: 12px 14px;
--input-padding-big: 1em 1.5em;
--input-padding-small: 0.4em 0.8em;
--select-padding: 9.5px 14px;

/*|   Tooltips    |*/
--tooltip-font-size: 1rem;
--tooltip-line-height: 1;
--tooltip-bg: #111; // #111 | #FFF
--tooltip-color: #EEE; // #CCC | #777
--tooltip-padding: 5px 10px;
--tooltip-shadow: 0 2px 2px 0 rgba(0,0,0,0.05); //  0 0 5px 5px rgba(0,0,0,.0)
--tooltip-border: none; // 1px solid #000 // 1px solid #eee
--tooltip-border-radius: 3px;
--tooltip-max-width: 200px;
--tooltip-max-height: 200px;

/*|   Modals    |*/
--modal-min-width: 300px;
--modal-shadow-color: rgba(0,0,0,.5);
--modal-bg: #fff;
--modal-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
                0 3px 1px -2px rgba(0,0,0,0.12),
                0 1px 5px 0 rgba(0,0,0,0.2);
--modal-border: none;
--modal-inner-border: none;
--modal-padding-header: 1.5rem 1.5rem 0 1.5rem;
--modal-padding-body: 1rem 1.5rem 1.5rem 1.5rem;
--modal-padding-footer: 0 1.5rem 1rem 1.5rem;

/*|   Sidemenu    |*/
--sidemenu-bg: #FFF;
--sidemenu-shadow-bg: #222;
--sidemenu-touch-bg: transparent;

/*|   Action messages    |*/
--action-message-bg: rgba(0,0,0,0.8);
--action-message-padding: 10px 15px;
--action-message-color: #f5f5f5;
--action-message-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
                0 3px 1px -2px rgba(0,0,0,0.12),
                0 1px 5px 0 rgba(0,0,0,0.2);
</code></pre>
            </div>
        </template>
    </demo-page>
</template>

<script>
    export default {
        data() {
            return {
                rgbDefault: '212, 212, 212',
                rgbDefaultText: '90, 90, 90',
                rgbPrimary: '69, 100, 143',
                rgbPrimaryText: '255, 255, 255',
                rgbSecondary: '219, 223, 225',
                rgbSecondaryText: '0, 0, 0',
                rgbTertiary: '60, 160, 160',
                rgbTertiaryText: '255, 255, 255',
                rgbError: '156, 39, 39',
                rgbWarning: '214, 109, 2',
                rgbSuccess: '82, 162, 44',
                rgbDark: '67, 67, 67',
                rgbDarkText: '217, 217, 217',
                rgbLight: '255, 255, 255',
                rgbLightText: '100, 100, 100',
                rgbTransparentText: '80, 80, 80',
                rgbBlack: '18, 18, 18',
                rgbWhite: '245, 245, 245',
                rgbGray: '42, 42, 42',
                rgbGray2: '67, 67, 67',
                rgbGray3: '144, 144, 144',
                rgbGray4: '169, 169, 169',
                rgbGray5: '195, 195, 195',
                rgbGray6: '235, 235, 235',
                rgbGray7: '242, 242, 242',
                menu: [
                    {name: "Installation"},
                    {name: "Usage"},
                    {name: "Components"},
                    {name: "Global Parameters"},
                    {name: "Design and Themes"},
                ]
            }
        },
        methods: {
            bv(v) {
                let t = v.split(', ');
                return this.rgba2hex(t[0],t[1],t[2]);
            },
            hCC(e, name) {
                let v = this.hex2rgb(e.target.value);
                this[name] = v;
                document.documentElement.style.setProperty(`--${this.camelToKebab(name)}`, v);
            },
            rgba2hex(r, g, b, alpha) {
                let hex = (r | 1 << 8).toString(16).slice(1) +
                    (g | 1 << 8).toString(16).slice(1) +
                    (b | 1 << 8).toString(16).slice(1);

                let a = alpha ? alpha : 0o01;
                // multiply before convert to HEX
                a = ((a * 255) | 1 << 8).toString(16).slice(1);
                if(alpha) hex = hex + a;

                return '#' + hex;
            },
            hex2rgb(hex) {
                let rgba = [];
                rgba[0] = parseInt(hex.slice(1,3), 16);
                rgba[1] = parseInt(hex.slice(3,5), 16);
                rgba[2] = parseInt(hex.slice(5,7), 16);
                return rgba.join(', ');
            },
            camelToKebab(s) { return s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase(); }
        }
    }
</script>